import React, { useState, useContext, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { FormComponents } from "./FormComponents/index.js";

/** ReactDatePicker CSS */
import "react-datepicker/dist/react-datepicker.css";
import "../../scss/biography-entry-form.scss";
import { personsAPI } from "../../api/personsAPI";
import { yupResolver } from "@hookform/resolvers/yup";
import { biographyEntryFormSchema } from "./validation/biographyEntryFormSchema";
import { BiographyStore } from "../../stateManager/stores/BiographyStore";

import "./index.scss";
import FormActionsNavbar from "./FormComponents/FormActionsNavbar.js";
import {
  hasAnyAPIErrors,
  setPermissionsAction,
  resetBiographyData,
  updateFormData,
  resetState,
  setPossibleTransitionsAction,
  updateInitialFormData,
} from "../../stateManager/actions/BiographyActions.js";
import { initialFormData } from "../BiographyEntryForm/FormComponents/initialFormData";
import ConfirmationModal from "./confirmationModal.js";
import { formatData, formatDataToDisplayInForm } from "./utils/Reformat.js";
import { toast } from "react-toastify";
import { Badge } from "react-bootstrap";
import { getBadgeClassName, getIdFromUrl, getStateName } from "./utils/utils.js";
import { displayToast } from "../DisplayToast.js";
import { useNavigate } from "react-router-dom";

export default function BiographyEntryForm() {
  const { state, dispatch } = useContext(BiographyStore);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [workflowModalBody, setWorkflowModalBody] = useState(null);
  const [workflowModalTitle, setWorkflowModalTitle] = useState(null);
  const [workflowModalConfirmButtonText, setWorkflowModalConfirmButtonText] = useState(null);
  const [workflowAction, setWorkflowAction] = useState(null);
  const navigate = useNavigate();
  const errorMessage = "Error! Something went wrong.";

  const methods = useForm({
    defaultValues: initialFormData,
    resolver: yupResolver(biographyEntryFormSchema),
  });

  const saveBiographyEntry = (data) => {
    const toastId = toast.loading("Please wait...", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
    updateFormData(data, dispatch);
    const saveData = formatData(data);
    personsAPI
      .create(saveData)
      .then((res) => {
        displayToast(toastId, "success", 1500, "Record has been saved successfully.");
        const createdEntryId = getIdFromUrl(res.data.url);
        !state.id && navigate(`/edit-bio-entry/${createdEntryId}`);
      })
      .catch((e) => {
        hasAnyAPIErrors({
          hasAnyErrors: true,
          APIErrorMessages: e.response?.data,
          APIErrorResponseStatus: e.response?.status,
          dispatch: dispatch,
        });
        window.scrollTo(0, 0);
        displayToast(toastId, "error", false, errorMessage, e);
      });
  };

  const editBiographyEntry = (data) => {
    const toastId = toast.loading("Please wait...");
    const formattedData = formatData(data);
    const updatedData = { ...formattedData, state: state.formData.state };
    updateFormData(updatedData, dispatch);
    methods.setValue("state", state.formData.state);
    personsAPI
      .update(state.id, updatedData)
      .then(() => {
        displayToast(toastId, "success", 1500, "Changes have been saved successfully.");
        updateFormData(methods.getValues(), dispatch);
        updateInitialFormData(methods.getValues(), dispatch);
        window.scrollTo(0, 0);
      })
      .catch((e) => {
        hasAnyAPIErrors({
          hasAnyErrors: true,
          APIErrorResponseStatus: e.response.status,
          APIErrorMessages: e.response.data,
          dispatch: dispatch,
        });
        window.scrollTo(0, 0);
        displayToast(toastId, "error", false, errorMessage, e);
      });
  };

  const onSubmit = (data) => {
    state.id ? editBiographyEntry(data) : saveBiographyEntry(data);
  };

  useEffect(() => {
    getAllPossibleTransitions();

    // Reset the form with initialFormData when there is no state.id, means creating the biography entry
    if (!state.id) {
      resetState(dispatch);
      methods.reset(initialFormData);
    }

    // Reset the form with state formData i.e. DB values when there is an state.id
    if (state.id) {
      methods.reset(state?.formData);
    }
  }, [state.id]);

  const onDeleteConfirmed = () => {
    const toastId = toast.loading("Please wait...");
    personsAPI
      .delete(state.id)
      .then(() => {
        resetBiographyData({
          formData: initialFormData,
          isSaved: false,
          dispatch: dispatch,
        });
        displayToast(toastId, "success", 1500, "Entry has been deleted successfully.");
        navigate("/my-biographies-entries");
      })
      .catch((e) => {
        window.scrollTo(0, 0);
        hasAnyAPIErrors({
          hasAnyErrors: true,
          APIErrorResponseStatus: e.response.status,
          APIErrorMessages: e.response.data,
          dispatch: dispatch,
        });
        displayToast(toastId, "error", false, errorMessage, e);
      });
  };

  const performWorkflowAction = () => {
    const toastId = toast.loading("Please wait...");
    personsAPI
      .performWorkflowAction(workflowAction, state.id, null)
      .then((response) => {
        displayToast(toastId, "success", 1500, "Record state has been updated successfully");
        setShowConfirmationModal(false);
        setWorkflowAction(null);
        getPersonOptions();
        getAllPossibleTransitions();
        const formattedFormData = formatDataToDisplayInForm(response.data);
        // Updating the formData with new state value
        const formData = { ...state.formData, state: formattedFormData.state };
        updateFormData(formData, dispatch);
      })
      .catch((e) => {
        const workflowErrorMessage = "Workflow update failed";
        displayToast(toastId, "error", false, workflowErrorMessage, e);
      });
  };

  const getAllPossibleTransitions = () => {
    state.id &&
      personsAPI
        .getPossibleTransitions(state.id)
        .then((response) => {
          setPossibleTransitionsAction(response?.data?.transitions, dispatch);
        })
        .catch((error) => {
          console.log(error);
        });
  };

  const getPersonOptions = () => {
    state.id &&
      personsAPI
        .getPersonOptions(state.id)
        .then((response) => {
          const hasEditPermission = (response?.data?.actions?.hasOwnProperty("PUT")) ?? false;
          const hasDeletePermission = (response?.data?.delete_action?.hasOwnProperty("DELETE")) ?? false;
          setPermissionsAction(hasEditPermission, hasDeletePermission, dispatch);
        })
        .catch((error) => {
          console.log(error);
        });
  };

  const formHeading = () => {
    if (state?.isReadOnly) return "View";
    if (!state?.isReadOnly && state.id) return "Edit";
    return "Create";
  };

  return (
    <>
      <FormProvider {...methods}>
        <ConfirmationModal
          show={showDeleteConfirmationModal}
          modalTitle={"Delete Entry"}
          modalBody={"Are you sure you want to delete this entry?"}
          cancelButtonText={"Cancel"}
          confirmButtonText={"Delete"}
          onModalClose={() => setShowDeleteConfirmationModal(false)}
          onConfirmed={onDeleteConfirmed}
        />
        <ConfirmationModal
          show={showConfirmationModal}
          modalTitle={workflowModalTitle}
          modalBody={workflowModalBody}
          cancelButtonText={"Cancel"}
          confirmButtonText={workflowModalConfirmButtonText}
          onModalClose={() => setShowConfirmationModal(false)}
          onConfirmed={() => performWorkflowAction()}
        />
        {state?.formData && (
          <div className="col-auto">
            <FormActionsNavbar
              methods={methods}
              onDelete={() => setShowDeleteConfirmationModal(true)}
              onSave={methods.handleSubmit(onSubmit)}
              onSubmit={() => {
                setWorkflowAction("SUBMIT_FOR_REVIEW");
                setWorkflowModalTitle("Submit Entry");
                setWorkflowModalConfirmButtonText("Submit");
                setWorkflowModalBody("Are you sure you want to submit this entry for review?");
                setShowConfirmationModal(true);
              }}
              onPublish={() => {
                setWorkflowAction("PUBLISH");
                setWorkflowModalTitle("Publish Entry");

                setWorkflowModalConfirmButtonText("Publish");
                setWorkflowModalBody("Are you sure you want to Publish this entry?");
                setShowConfirmationModal(true);
              }}
              onReject={() => {
                setWorkflowAction("REJECT");
                setWorkflowModalTitle("Reject Entry");
                setWorkflowModalConfirmButtonText("Reject");
                setWorkflowModalBody("Are you sure you want to Reject this entry?");
                setShowConfirmationModal(true);
              }}
              onArchive={() => {
                setWorkflowAction("ARCHIVE");
                setWorkflowModalTitle("Archive Entry");
                setWorkflowModalConfirmButtonText("Archive");
                setWorkflowModalBody("Are you sure you want to Archive this entry?");
                setShowConfirmationModal(true);
              }}
              onDraft={() => {
                setWorkflowAction("DRAFT");
                setWorkflowModalTitle("Draft Entry");
                setWorkflowModalConfirmButtonText("Draft");
                setWorkflowModalBody("Are you sure you want to move this entry to Draft?");
                setShowConfirmationModal(true);
              }}
              onMoveToOutOfScope={() => {
                setWorkflowAction("OUT_OF_SCOPE");
                setWorkflowModalTitle("Out of Scope Entry");
                setWorkflowModalConfirmButtonText("Move to Out of Scope");
                setWorkflowModalBody("Are you sure you want to move this entry to Out of Scope?");
                setShowConfirmationModal(true);
              }}
              onRemove={() => {
                setWorkflowAction("REMOVE");
                setWorkflowModalTitle("Out of Scope Entry");
                setWorkflowModalConfirmButtonText("Move to Out of Scope");
                setWorkflowModalBody("Are you sure you want to move this entry to Out of Scope?");
                setShowConfirmationModal(true);
              }}
            />
            <div className="container biography-form-container">
              <h3 id="form-heading">
                {formHeading()} Biography Entry{" "}
                <Badge id="bio-entry-status" bg={getBadgeClassName(state?.formData.state)}>
                  {getStateName(state?.formData.state)}
                </Badge>
              </h3>

              <form className="row g-3" id="bio-form">
                <FormComponents />
              </form>
            </div>
          </div>
        )}
      </FormProvider>
    </>
  );
}
