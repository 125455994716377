import { Button, Col, Container, Row } from "react-bootstrap";
import logo from "../assets/robert-henry-img.PNG";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

export const HarryGentleResourceCentreInfo = (content) => {

  return (
    <section className="pt-3 about">
      <Container>
        <Row className="justify-content-center">
          <Col xl={3} lg={3} md={5} sm={12}>
            {" "}
            <img src={logo} height={350} className="d-inline-block align-top" alt="Griffith University logo" />
          </Col>
          <Col xl={9} lg={9} md={7} sm={12} className="py-5">
            <div className="pt-3">
              <h3 className="home-page-headings">{content.harryGentleResourceCentreSectionContent.title}</h3>
            </div>
            <div>
              <ReactMarkdown
                className="reactMarkDownHomePage"
                remarkPlugins={[remarkGfm]}
                skipHtml={true}
                linkTarget="_blank"
              >
                {content.harryGentleResourceCentreSectionContent.content}
              </ReactMarkdown>
            </div>
            <Button
              variant="primary mt-3"
              className="shadow-lg mb-2 rounded py-2 px-4"
              target="_blank"
              href="https://harrygentle.griffith.edu.au"
            >
              {" "}
              Visit the Harry Gentle Resource Centre
            </Button>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
