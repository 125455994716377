import React, { useEffect, useContext, useState } from "react";
import { BiographyStore } from "../../../stateManager/stores/BiographyStore";
import "../../../scss/biography-entry-form.scss";
import ConfirmationModal from "../confirmationModal";
import CreatePlace from "../ArrayFieldComponents/CreatePlace";
import SearchAndAsyncSelect from "./SearchAndAsyncSelect";
import { getIdFromUrl } from "../utils/utils";
import { placeAPI } from "../../../api/placeAPI";
import { updateFormData } from "../../../stateManager/actions/BiographyActions";

function LabelAndLocationSearch({
  labelText,
  name,
  error,
  classNames,
  displayAddButton,
  control,
  getValues,
  setValue,
  placeholder,
  propertyOwnedLocation,
  weddingLocation,
  onFocus,
}) {
  const { state, dispatch } = useContext(BiographyStore);
  const [showAddModal, setShowAddModal] = useState(false);
  const [selectedPlace, setSelectedPlace] = useState({
    value: null,
    label: placeholder,
  });
  const [savePlaceResponse, setSavePlaceResponse] = useState(null);
  const fieldValue = getValues(name);
  const propertyOwnedValues = getValues("property_owned");

  const getPlace = (value) => {
    value &&
      value !== "" &&
      placeAPI.getById(value).then((response) => {
        const location = response?.data;
        setSelectedPlace({
          value: getIdFromUrl(location.url),
          label: location.full_title,
        });
      });
  };

  // Pre-fill the select value from the DB if saved any
  useEffect(() => {
    if (fieldValue && fieldValue !== fieldValue.url && propertyOwnedLocation && propertyOwnedValues.length > 0) {
      getPlace(fieldValue);
    } else if (typeof fieldValue === "string" && !propertyOwnedLocation) {
      weddingLocation && getPlace(getIdFromUrl(fieldValue));
    } else if (
      fieldValue &&
      fieldValue !== fieldValue.url &&
      !propertyOwnedLocation &&
      typeof fieldValue === "object" &&
      state.id
    ) {
      setSelectedPlace({
        value: fieldValue.url,
        label: fieldValue.full_title,
      });
      setValue(name, fieldValue.url);
      updateFormData(getValues(), dispatch);
    }
  }, [fieldValue]);

  // set the newly created place to selected place and set it as the field value
  // only when user clicks on Save & select, hence !showAddModal condition
  useEffect(() => {
    if (savePlaceResponse && !showAddModal) {
      if (propertyOwnedLocation) {
        setSelectedPlace({
          value: getIdFromUrl(savePlaceResponse.url),
          label: savePlaceResponse.full_title,
        });
        setValue(name, getIdFromUrl(savePlaceResponse.url));
        setSavePlaceResponse(null);
      } else {
        setSelectedPlace({
          value: savePlaceResponse.url,
          label: savePlaceResponse.full_title,
        });
        setValue(name, savePlaceResponse.url);
        setSavePlaceResponse(null);
      }
    }
  }, [savePlaceResponse]);

  return (
    <div className={classNames}>
      <ConfirmationModal
        show={showAddModal}
        scrollable={true}
        modalBody={
          <CreatePlace setResponse={(res) => setSavePlaceResponse(res)} onClose={() => setShowAddModal(false)} />
        }
        onModalClose={null}
        onConfirmed={null}
        modalClassName={"add-stub-entry-modal-dialog"}
      />
      {labelText && (
        <label htmlFor="labelText" className={"form-label"}>
          {labelText}:
        </label>
      )}
      <div className="row">
        <div className="col-lg-10 col-md-12">
          <SearchAndAsyncSelect
            onselectionchange={(option, onChange) => {
              if (propertyOwnedLocation) {
                const updatedOption = {
                  value: getIdFromUrl(option?.value),
                  label: option?.label,
                };
                setSelectedPlace(updatedOption);
                onChange(updatedOption?.value);
                updateFormData(getValues(), dispatch);
              } else {
                const updatedOption = {
                  value: option ? option.value : null,
                  label: option?.label,
                };

                setSelectedPlace(updatedOption);
                onChange(updatedOption?.value);
                updateFormData(getValues(), dispatch);
              }
            }}
            place={selectedPlace}
            name={name}
            placeholder={placeholder}
            control={control}
            fieldValue={getValues(name)}
            isReadOnly={state?.isReadOnly}
            onFocus={onFocus}
          />
        </div>
        {displayAddButton && (
          <div className="col-md-12 col-lg col-sm-12">
            <button
              id="add-place"
              type="button"
              className={"btn btn-primary btn-place"}
              disabled={state?.isReadOnly}
              onClick={() => setShowAddModal(true)}
            >
              {"+ Place"}
            </button>
          </div>
        )}
      </div>
      {error && (
        <span className="text-danger" data-testid="error-message" id={name + "-error-message"}>
          {error}
        </span>
      )}
    </div>
  );
}

export default LabelAndLocationSearch;
