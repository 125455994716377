import React, { useState } from "react";
import "./App.css";
import SearchPage from "./pages/SearchPage";
import HomePage from "./pages/HomePage";
import Footer from "./components/Footer";
import AppNavbar from "./components/AppNavbar";
import BiographyPage from "./pages/BiographyPage";
import CreateBiographyEntryPage from "./pages/CreateBiographyEntryPage";
import EditViewBiographyEntryPage from "./pages/EditViewBiographyEntryPage";
import { QueryParamProvider } from "use-query-params";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "bootstrap-icons/font/bootstrap-icons.css";
import { BiographyStoreProvider } from "./stateManager/stores/BiographyStore";
import Admin from "./components/Admin/Admin";
import AdminPendingApproval from "./components/Admin/AdminPendingApproval";
import AdminPublishedEntries from "./components/Admin/AdminPublishedEntries";
import DraftEntries from "./components/Admin/DraftEntries";
import AdminArchiveEntries from "./components/Admin/AdminArchiveEntries";
import ManageBiographyEntriesPage from "./pages/ManageBiographyEntriesPage";
import AuthEvents from "./AuthEvents";
import { useAuth } from "react-oidc-context";
import PrivateRoute from "./features/auth/PrivateRoute";
import Glossaries from "./pages/Glossaries";
import GlossaryEntries from "./pages/GlossaryEntries";
import NotFound from "./pages/NotFound";
import "react-toastify/dist/ReactToastify.css";
import AdminOutOfScopeEntries from "./components/Admin/AdminOutOfScopeEntries";
import Places from "./pages/Places";
import { whoAmIAPI } from "./api/whoAmIAPI";
import { SignInContext } from "./stateManager/stores/SignInContext";
import PublicHelpPage from "./pages/PublicHelpPage";
import AdministrativeHelpPage from "./pages/AdministrativeHelpPage";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";
import FAQPage from "./pages/FAQPage";
import ErrorHandler from "./ErrorHandler";
import InternalServerError from "./pages/InternalServerError";
import Unauthorized from "./pages/Unauthorized";
import { ToastContainer } from "react-toastify";

function App() {
  const auth = useAuth();
  const [isUserAccountApproved, setIsUserAccountApproved] = useState({
    isApprovedUser: null,
    displayPendingUserAccountMessage: undefined,
    setIsApprovedUser: () => null,
  });
  const [authInitialising, setAuthInitialising] = React.useState(true);

  // this might need some work but on a page reload, the react oidc context needs
  // a few moments to fully load. in that time the axios interceptor will not be configured
  // and axios requests will not have a token attached, even when the user is logged in.
  // rather than making EVERY useEffect hook depend on auth.isLoading, this should fix this
  React.useEffect(() => {
    if (authInitialising && !auth.isLoading) {
      setAuthInitialising(false);
    }
  }, [auth.isLoading, authInitialising, setAuthInitialising]);

  const setIsApprovedUser = React.useCallback(() => {
    setIsUserAccountApproved({
      isApprovedUser: null,
      displayPendingUserAccountMessage: false,
      setIsApprovedUser: setIsApprovedUser,
    });
  }, []);

  React.useEffect(() => {
    auth.isAuthenticated &&
      !auth.isLoading &&
      isUserAccountApproved.displayPendingUserAccountMessage === undefined &&
      whoAmIAPI
        .whoAmI()
        .then((res) => {
          setIsUserAccountApproved({
            isApprovedUser: res.data?.found === true,
            displayPendingUserAccountMessage: res.data?.found !== true,
            setIsApprovedUser: setIsApprovedUser,
          });
        })
        .catch((e) => {
          if (e.response?.status === 401) {
            setIsUserAccountApproved({
              isApprovedUser: false,
              displayPendingUserAccountMessage: true,
              setIsApprovedUser: setIsApprovedUser,
            });
          }
        });
  }, [auth, isUserAccountApproved.displayPendingUserAccountMessage, setIsApprovedUser]);

  return (
    <>
      <BrowserRouter>
      <ToastContainer theme="colored" position="bottom-right" />
        <SignInContext.Provider value={isUserAccountApproved}>
          <QueryParamProvider adapter={ReactRouter6Adapter}>
            <ErrorHandler />
            <AuthEvents />
            {authInitialising ? null : (
              <div className="AppWrapper">
                <Routes>
                  <Route path="/" element={<HomePage />} />
                  <Route
                    path="/help"
                    element={
                      <>
                        {" "}
                        <AppNavbar type={1} />
                        <PublicHelpPage />
                      </>
                    }
                  />
                  <Route
                    path="/notfound"
                    element={
                      <>
                        {" "}
                        <AppNavbar type={1} />
                        <NotFound />
                      </>
                    }
                  />
                  <Route
                    path="/*"
                    element={
                      <>
                        {" "}
                        <AppNavbar type={1} />
                        <NotFound />
                      </>
                    }
                  />
                  <Route
                    path="/internal-server-error"
                    element={
                      <>
                        {" "}
                        <AppNavbar type={1} />
                        <InternalServerError />
                      </>
                    }
                  />
                  <Route
                    path="/unauthorized"
                    element={
                      <>
                        {" "}
                        <AppNavbar type={1} />
                        <Unauthorized />
                      </>
                    }
                  />
                  <Route
                    path="/faq"
                    element={
                      <>
                        {" "}
                        <AppNavbar type={1} />
                        <FAQPage />
                      </>
                    }
                  />
                  <Route path="/search" element={<SearchPage />} />
                  <Route
                    path="/administrative-help"
                    element={
                      <PrivateRoute exact path="/administrative-help">
                        <AppNavbar type={1} />
                        <AdministrativeHelpPage />
                      </PrivateRoute>
                    }
                  />

                  <Route
                    path="/my-biographies-entries"
                    element={
                      <PrivateRoute exact path="/my-biographies-entries">
                        <AppNavbar type={1} />
                        <ManageBiographyEntriesPage />
                      </PrivateRoute>
                    }
                  />

                  <Route
                    path="/biography/:id"
                    element={
                      <>
                        <AppNavbar type={2} />
                        <BiographyPage />
                      </>
                    }
                  />

                  <Route
                    path="/admin"
                    element={
                      <PrivateRoute exact path="/admin">
                        <AppNavbar type={1} />
                        <Admin />
                      </PrivateRoute>
                    }
                  />

                  <Route
                    path="/admin-pending-reviews"
                    element={
                      <PrivateRoute exact path="/admin-pending-reviews">
                        <AdminPendingApproval />
                      </PrivateRoute>
                    }
                  />

                  <Route
                    path="/admin-published-entries"
                    element={
                      <PrivateRoute exact path="/admin-published-entries">
                        <AdminPublishedEntries />
                      </PrivateRoute>
                    }
                  />

                  <Route
                    path="/draft-entries"
                    element={
                      <PrivateRoute exact path="/draft-entries">
                        <DraftEntries />
                      </PrivateRoute>
                    }
                  />

                  <Route
                    path="/archived-entries"
                    element={
                      <PrivateRoute exact path="/archived-entries">
                        <AdminArchiveEntries />
                      </PrivateRoute>
                    }
                  />

                  <Route
                    path="/out-of-scope-entries"
                    element={
                      <PrivateRoute exact path="/out-of-scope-entries">
                        <AdminOutOfScopeEntries />
                      </PrivateRoute>
                    }
                  />

                  <Route
                    path="/create-bio-entry"
                    element={
                      <PrivateRoute exact path="/create-bio-entry">
                        <BiographyStoreProvider>
                          <AppNavbar type={1} />
                          <CreateBiographyEntryPage />
                        </BiographyStoreProvider>
                      </PrivateRoute>
                    }
                  />

                  <Route
                    path="/edit-bio-entry/:id"
                    element={
                      <PrivateRoute exact path="/edit-bio-entry/:id">
                        <BiographyStoreProvider>
                          <AppNavbar type={1} />
                          <EditViewBiographyEntryPage />
                        </BiographyStoreProvider>
                      </PrivateRoute>
                    }
                  />

                  <Route
                    path="/view-bio-entry/:id"
                    element={
                      <PrivateRoute exact path="/view-bio-entry/:id">
                        <BiographyStoreProvider>
                          <AppNavbar type={1} />
                          <EditViewBiographyEntryPage isReadOnly={true} />
                        </BiographyStoreProvider>
                      </PrivateRoute>
                    }
                  />

                  <Route
                    path="/glossaries/:slug"
                    element={
                      <PrivateRoute exact path="/glossaries/:slug">
                        <AppNavbar type={1} />
                        <GlossaryEntries />
                      </PrivateRoute>
                    }
                  />

                  <Route
                    path="/glossaries"
                    element={
                      <PrivateRoute exact path="/glossaries">
                        <AppNavbar type={1} />
                        <Glossaries />
                      </PrivateRoute>
                    }
                  />

                  <Route
                    path="/places"
                    element={
                      <PrivateRoute exact path="/places">
                        <AppNavbar type={1} />
                        <Places />
                      </PrivateRoute>
                    }
                  />
                </Routes>
                <Footer />
              </div>
            )}
          </QueryParamProvider>
        </SignInContext.Provider>
      </BrowserRouter>
    </>
  );
}

export default App;
