import React from "react";
import { Modal } from "react-bootstrap";

const ConfirmationModal = ({
  show,
  onModalClose,
  cancelButtonText,
  confirmButtonText,
  onConfirmed,
  modalTitle,
  modalBody,
  scrollable,
  modalClassName,
}) => {
  return (
    <>
      <Modal
        show={show}
        className={modalClassName}
        scrollable={scrollable}
        backdrop="static"
        onHide={null}
      >
        {modalTitle && (
          <Modal.Header>
            <Modal.Title>{modalTitle}</Modal.Title>
          </Modal.Header>
        )}
        <Modal.Body>{modalBody}</Modal.Body>
        {(cancelButtonText || confirmButtonText) && (
          <Modal.Footer>
            {cancelButtonText && (
              <button
                className="btn btn-secondary form-button"
                data-testid="close-button"
                onClick={onModalClose}
              >
                {cancelButtonText}
              </button>
            )}
            {confirmButtonText && (
              <button
                className="btn btn-primary form-button"
                data-testid="confirm-button"
                onClick={onConfirmed}
              >
                {confirmButtonText}
              </button>
            )}
          </Modal.Footer>
        )}
      </Modal>
    </>
  );
};

export default ConfirmationModal;
