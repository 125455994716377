import PageLayout from "../components/PageLayout";
import managedEntries from "../../src/assets/managedEntries.png";
import "../css/Help.css";
import { Col, Row, Table } from "react-bootstrap";
import clearAllButton from "../../src/assets/clearAllButton.png";
import createEntry from "../../src/assets/createEntry.png";
import mandatoryField from "../../src/assets/mandatoryField.png";
import formActions from "../../src/assets/formActions.png";
import formActionsUpdateEntry from "../../src/assets/formActionsUpdateEntry.png";
import workflowActions from "../../src/assets/workflowActions.png";
import birthAndDeathInfo from "../../src/assets/birthAndDeathInfo.png";
import addPlace from "../../src/assets/addPlace.png";
import addPlaceForm from "../../src/assets/addPlaceForm.png";
import selectedPlace from "../../src/assets/selectedPlace.png";
import dateFormats from "../../src/assets/dateFormats.png";
import infoIcon from "../../src/assets/infoIcon.png";
import relationships from "../../src/assets/relationships.png";
import addButton from "../../src/assets/addButton.png";
import parentRelationship from "../../src/assets/parentRelationship.png";
import searchAndSelect from "../../src/assets/searchAndSelect.png";
import searchEntriesModal from "../../src/assets/searchEntriesModal.png";
import addEntryButton from "../../src/assets/addEntryButton.png";
import addEntryModalForm from "../../src/assets/addEntryModalForm.png";
import arrivalInAustralia from "../../src/assets/arrivalInAustralia.png";
import occupationAccordion from "../../src/assets/occupationAccordion.png";
import occupationFormSection from "../../src/assets/occupationFormSection.png";
import occupationFormExample from "../../src/assets/occupationFormExample.png";
import notes from "../../src/assets/notes.png";
import notesToolbar from "../../src/assets/notesToolbar.png";
import notesExample from "../../src/assets/notesExample.png";
import dashboard from "../../src/assets/dashboard.png";
import userMenu from "../../src/assets/userMenu.png";
import userMenus from "../../src/assets/userMenus.png";
import lineChart from "../../src/assets/lineChart.png";
import lineChart2 from "../../src/assets/lineChart2.png";
import doughnutChart from "../../src/assets/doughnutChart.png";
import glossariesListPage from "../../src/assets/glossariesListPage.png";
import glossaryItems from "../../src/assets/glossaryItems.png";
import addGlossaryEntry from "../../src/assets/addGlossaryEntry.png";
import deleteGlossaryConfirmationDialog from "../../src/assets/deleteGlossaryConfirmationDialog.png";
import placeListPage from "../../src/assets/placeListPage.png";
import deletePlace from "../../src/assets/deletePlace.png";
import placeExample from "../../src/assets/placeExample.png";
import placeExample2 from "../../src/assets/placeExample2.png";
import glossariesMenu from "../../src/assets/glossariesMenu.png";
import saveAndClose from "../../src/assets/saveAndClose.png";
import saveAndAddAnother from "../../src/assets/saveAndAddAnother.png";
import addGlossaryEntryButton from "../../src/assets/addGlossaryEntryButton.png";
import bibliographyOutput from "../../src/assets/bibliographyOutput.png";
import bibliographyExample from "../../src/assets/bibliographyExample.png";

const AdministrativeHelpPage = () => {
  return (
    <PageLayout
      title={"Administrative Help"}
      showHeadingAndBreadcrumb={true}
      parents={[{ to: "/admin", title: "Admin" }]}
    >
      <Row className="mx-1">
        <UserMenuSectionOverview />
        <ManagedEntriesPageSection />
        <CreateEntryPageSection />
        <DashboardPageSection />
        <GlossariesPageSection />
        <PlacesPageSection />
      </Row>
    </PageLayout>
  );
};

export default AdministrativeHelpPage;

const UserMenuSectionOverview = () => {
  return (
    <Row className="p-2">
      <h5 className="help-page-headings">USER MENU OVERVIEW</h5>
      <p>To access the user menu, click on your username in the navigation bar.</p>
      <p>
        <img
          alt="HarryGentle Biography of Dictionary - User Menu"
          src={userMenu}
          style={{ width: "95%", height: "auto" }}
        />
      </p>
      <h5>Menu options</h5>
      <ol>
        <li>
          <span className="highlight-words">Manage Entries:</span> Existing entries which can be edited, submitted for
          review, published, archived, etc.
        </li>
        <li>
          <span className="highlight-words">Create Entry:</span> Create a new entry and, where appropriate, link the
          individual to relatives already in the Dictionary database.
        </li>
        <li>
          <span className="highlight-words">Dashboard:</span> Two charts (line and doughnut), analyse data in the
          database.
        </li>
        <li>
          <span className="highlight-words">Glossaries:</span> Lists occupations, religions, ships, etc., to ensure
          consistent spelling in the Dictionary.
        </li>
        <li>
          <span className="highlight-words">Places:</span> Lists place names to ensure consistent spelling and enable
          searches by country, region, town, etc.
        </li>
      </ol>
    </Row>
  );
};

const ManagedEntriesPageSection = () => {
  return (
    <Row className="p-2">
      <h5 className="help-page-headings">MANAGE ENTRIES PAGE</h5>
      <p className="text-center">
        <img
          alt="HarryGentle Biography of Dictionary Managed Entries Page"
          src={managedEntries}
          style={{ width: "90%", height: "auto" }}
        />
      </p>
      <Row className="mx-1 my-3">
        <h5 className="sub-headings p-1 px-2">NAVIGATION</h5>
        <p>
          Clicking on <span className="highlight-words">Manage Entries</span> takes you to a list of all existing
          entries which can be edited, submitted for review, published, archived, etc. The default listing is
          alphabetical by surname (A-Z). Place your mouse over the <span className="highlight-words">Surname</span>{" "}
          column heading to see the entries in reverse alphabetical order (Z-A), and the{" "}
          <span className="highlight-words">Toggle Sortby</span> tooltip will appear. Click on the{" "}
          <span className="highlight-words">Surname</span> column heading, and the list will be displayed in reverse
          alphabetical order (Z-A). To return to the original sort (A-Z), click the
          <img
            alt="HarryGentle Biography of Dictionary Search Page - Clear All Button"
            src={clearAllButton}
            style={{ width: "6%", height: "auto", marginLeft: 6, marginRight: 6 }}
          />
          button.
        </p>
        <p>
          From this screen, administrators can locate an entry, regardless of its state (draft, published, out-of-scope)
          and click on it to edit, publish, archive, delete or move it out of scope.
        </p>
        <h5 className="sub-headings p-1 px-2">STATE TYPES</h5>
        <Table>
          <thead>
            <tr>
              <th>STATE</th>
              <th>DESCRIPTION</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="highlight-words">Draft</td>
              <td>
                A draft entry is created when the mandatory fields (surname and gender) and other information, if
                available, have been entered and saved. Only the creator and the administration team can view drafts.
              </td>
            </tr>
            <tr>
              <td className="highlight-words">Waiting for Review</td>
              <td>
                The administration team will review drafts created by researchers and volunteers before publication.
                Only the creator and the administration team can view drafts in review.
              </td>
            </tr>
            <tr>
              <td className="highlight-words">Published</td>
              <td>
                A published entry is viewable by the public and can be searched on the public search page. Only the
                administration team can move entries to the published state.
              </td>
            </tr>
            <tr>
              <td className="highlight-words">Archived</td>
              <td>
                An archived entry is a draft or published entry that is temporarily or permanently archived, so it
                cannot be viewed on the public section of the site. Unlike a deleted entry, an archived entry can later
                be returned to the draft or published state.
              </td>
            </tr>
            <tr>
              <td className="highlight-words">Out of Scope</td>
              <td>
                Out-of-scope entries are created for family members who did not live in Queensland and exist solely to
                link relationships in the database. For example, out-of-scope entries are created for an individual’s
                parents who remained in England and/or their siblings who settled in Tasmania. The names of out-of-scope
                parents and siblings will appear in an individual’s entry. They are hyperlinked (underlined in red) as
                they do not have their entries in the Biographical Dictionary.
              </td>
            </tr>
          </tbody>
        </Table>
      </Row>
    </Row>
  );
};

const CreateEntryPageSection = () => {
  return (
    <Row className="p-2">
      <h5 className="help-page-headings">CREATE ENTRY PAGE</h5>
      <p className="text-center">
        <img
          alt="HarryGentle Biography of Dictionary Managed Entries Page"
          src={createEntry}
          style={{ width: "90%", height: "auto" }}
        />
      </p>
      <Row className="mx-1 my-3">
        <h5 className="sub-headings p-1 px-2">NAVIGATION</h5>
        <p>
          Click on <span className="highlight-words">Create Entry</span> in the user menu. Notice that each new entry
          starts in the <span className="highlight-words">draft</span> state. To complete the form, click on the down
          arrow next to each section to open that section and complete the fields within.
        </p>
        <p>
          Two mandatory fields (<span className="highlight-words">Surname</span> and{" "}
          <span className="highlight-words">Gender</span>) are required to save the entry; these are denoted by a red
          asterisk next to the field name. For example:
        </p>
        <img
          alt="HarryGentle Biography of Dictionary Search Page - Mandatory Field"
          src={mandatoryField}
          style={{ width: "30%", height: "auto", marginLeft: 6, marginRight: 6 }}
        />
        <Row>
          <Col xl={4} lg={4} md={12} sm={12}>
            <Row>
              <Col xl={12} lg={12} md={12} sm={12}>
                {" "}
                <p>
                  Once you have completed the form (or at least the mandatory fields), save the entry by clicking on the{" "}
                  <span className="highlight-words">Form Actions</span> drop-down menu at the top of the screen and
                  selecting <span className="highlight-words">Save</span>. You can also cancel your entry here or reset
                  it, which will clear out any data in the form.
                </p>
              </Col>
            </Row>
            <Row>
              <Col xl={12} lg={12} md={12} sm={12} className="text-center">
                <img
                  alt="HarryGentle Biography of Dictionary Search Page - Form Actions Dropdown"
                  src={formActions}
                  style={{ width: "60%", height: "auto" }}
                />
              </Col>
            </Row>
          </Col>

          <Col xl={4} lg={4} md={12} sm={12}>
            <Row>
              <Col xl={12} lg={12} md={12} sm={12}>
                {" "}
                <p>
                  Once you have saved the form for the first time, you can continue to edit and update it. The Form
                  Actions menu updates with additional options, including{" "}
                  <span className="highlight-words">Update, Review</span> and{" "}
                  <span className="highlight-words">Delete</span>.<span className="highlight-words"> Review</span> mode
                  allows you to check an entry without accidentally editing anything.
                </p>
              </Col>
            </Row>
            <Row>
              <Col xl={12} lg={12} md={12} sm={12} className="text-center">
                <img
                  alt="HarryGentle Biography of Dictionary Search Page - Form Actions Dropdown"
                  src={formActionsUpdateEntry}
                  style={{ width: "50%", height: "auto" }}
                />
              </Col>
            </Row>
          </Col>

          <Col xl={4} lg={4} md={12} sm={12}>
            <Row>
              <Col xl={12} lg={12} md={12} sm={12}>
                {" "}
                <p>
                  Once you have saved your form, you will also be presented with a new menu option drop-down box called{" "}
                  <span className="highlight-words">Workflow Actions</span>. Creators can then decide what to do once
                  they have completed their form entry: mark it as{" "}
                  <span className="highlight-words">Out of Scope, Submit for Review</span> or, if this option is listed,{" "}
                  <span className="highlight-words">Publish</span> the entry.
                </p>
              </Col>
            </Row>
            <Row>
              <Col xl={12} lg={12} md={12} sm={12} className="text-center">
                <img
                  alt="HarryGentle Biography of Dictionary Search Page - Workflow Actions Dropdown"
                  src={workflowActions}
                  style={{ width: "60%", height: "auto" }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <h5 className="sub-headings p-1 px-2">COMPLETING THE FORM</h5>
        <p>
          This help guide will not go through each field in each section of the form individually, but where there are
          more complex entry types, they will be explained below.
        </p>
        <h5 className="p-2 text-decoration-underline">BIRTH AND DEATH INFORMATION</h5>
        <img
          alt="HarryGentle Biography of Dictionary Search Page - Form Actions Dropdown"
          src={birthAndDeathInfo}
          style={{ width: "100%", height: "auto" }}
        />
        <p>
          There are two ways to add a place for a birth, death or burial entry. First, type in the place you are looking
          for (e.g., Ipswich) and select the correct place name (Ipswich, Queensland, Australia or Ipswich, Suffolk,
          England) from the list of returned results. If the place you are looking for is not already in the database,
          you will need to add a new place by clicking on the
          <img
            alt="HarryGentle Biography of Dictionary Search Page - Add Place Button"
            src={addPlace}
            style={{ width: "5%", height: "auto", marginLeft: 6, marginRight: 6 }}
          />
          button, which will give you the following pop-up:
        </p>
        <img
          alt="HarryGentle Biography of Dictionary Search Page - Add Place Form"
          src={addPlaceForm}
          style={{ width: "90%", height: "auto" }}
        />
        <p>
          When adding a new place, you must add not only the name of the place but also the place{" "}
          <span className="highlight-words">type</span> and, if possible, the{" "}
          <span className="highlight-words">area or parent region</span> where the place is located. To add the town of
          Cootehill, County Cavan, Ireland, for example:
        </p>
        <p>
          Ireland is already in the <span className="highlight-words">Places</span> glossary, so you need to add the
          county (Cavan) and town (Cootehill) in that order (the larger region followed by the city, town, suburb,
          etc.). So, first, add the county of Cavan and click on{" "}
          <span className="highlight-words">Save & Add Another:</span>
        </p>

        <img alt="HarryGentle Biography of Dictionary Search Page - Pre-filled Place Form" src={placeExample} />
        <p>
          Then add Cootehill as a town in the county of Cavan and click on{" "}
          <span className="highlight-words">Save & Close:</span>
        </p>

        <img alt="HarryGentle Biography of Dictionary Search Page - Pre-filled Place Form" src={placeExample2} />
        <p>This place will now display as Cootehill, Cavan, Ireland.</p>
        <img
          alt="HarryGentle Biography of Dictionary Search Page - Selected Place"
          src={selectedPlace}
          style={{ width: "50%", height: "auto", marginLeft: 6, marginRight: 6 }}
        />

        <h5 className="p-2 text-decoration-underline mt-4">DATES</h5>
        <p>
          All the date fields function in the same way across different fields. The database accommodates different date
          formats and uncertainties, including approximate dates. Click on the
          <img
            alt="HarryGentle Biography of Dictionary Search Page - Info Icon"
            src={infoIcon}
            style={{ width: "3%", height: "auto", marginLeft: 6, marginRight: 6 }}
          />
          icon next to any date field label to see an exhaustive list of valid date formats. This action will open the
          following dialogue box:
        </p>
        <img
          alt="HarryGentle Biography of Dictionary Search Page - Date Formats"
          src={dateFormats}
          style={{ width: "40%", height: "auto" }}
        />

        <h5 className="p-2 text-decoration-underline mt-4">RELATIONSHIPS</h5>
        <img
          alt="HarryGentle Biography of Dictionary Search Page - Relationships Form"
          src={relationships}
          style={{ width: "90%", height: "auto" }}
        />
        <p>
          The database allows you to connect an entry with other entries that share a family relationship, including
          parents, children and spouses. There is no need to connect siblings or cousins as they will be automatically
          connected via their parents’ entries.
        </p>

        <p>
          {" "}
          There are two ways of connecting family members. Click
          <img
            alt="HarryGentle Biography of Dictionary Search Page - Add Button"
            src={addButton}
            style={{ width: "5%", height: "auto", marginRight: 6, marginLeft: 6 }}
          />
          and the relationship box will expand further:{" "}
          <img
            alt="HarryGentle Biography of Dictionary Search Page - Parent Relationships Form"
            src={parentRelationship}
            style={{ width: "90%", height: "auto", marginTop: 10 }}
          />
        </p>

        <p>
          <ol>
            <li>
              {" "}
              If the family member you are looking for is already in the database, you can search for them by clicking{" "}
              <img
                alt="HarryGentle Biography of Dictionary Search Page - Search and Select Button"
                src={searchAndSelect}
                style={{ width: "15%", height: "auto", marginRight: 6, marginLeft: 6 }}
              />{" "}
              You will be taken to a page to search for existing entries:
              <img
                alt="HarryGentle Biography of Dictionary Search Page - Search Entires Modal"
                src={searchEntriesModal}
                style={{ width: "100%", height: "auto", marginTop: 10, marginBottom: 10 }}
              />
              Search this list of all entries in the database (draft, published and out of scope) for the family member
              you want to add. Once you find the correct person, click on their name, and you will be returned to the
              main entry screen. The relevant field (parent, child, spouse) will now be populated with the family
              member’s name. Importantly, this also means that when the entry is published in the Biographical
              Dictionary, there will be a hyperlink to the entry of the family member you added at this step.
            </li>
            <li>
              If the relative you want to add is <span className="highlight-words">not</span> in the database, click on{" "}
              <img
                alt="HarryGentle Biography of Dictionary Search Page - Add Entry Button"
                src={addEntryButton}
                style={{ width: "8%", height: "auto", marginRight: 6, marginLeft: 6 }}
              />
              , and the following pop-up box will be displayed:
            </li>
          </ol>
        </p>
        <img
          alt="HarryGentle Biography of Dictionary Search Page - Add Entry Modal Form"
          src={addEntryModalForm}
          style={{ width: "90%", height: "auto", marginTop: 10 }}
        />
        <p>
          When adding a new entry, you will be asked if this person’s entry is ‘out of scope’. Entries for people who
          lived in Queensland, even temporarily (e.g., convicts who spent several years at the Moreton Bay penal
          station), are ‘in scope’. Entries are ‘out of scope’ if you add a person who never lived in Queensland.
          Out-of-scope entries are added only to establish a family relationship (e.g. to someone’s mother or child who
          lived in Ireland).
        </p>

        <h5 className="p-2 text-decoration-underline mt-4">ARRIVAL IN AUSTRALIA</h5>
        <img
          alt="HarryGentle Biography of Dictionary Search Page - Arrival In Australia Form Section"
          src={arrivalInAustralia}
          style={{ width: "90%", height: "auto", marginTop: 10 }}
        />
        <p>
          To add a ship name, start typing in the corresponding field. If the ship name is already in the glossary, it
          will be returned for you to select. For example, if you type in ‘Roslin’, the database will return Roslin
          Castle from the glossary of ships that arrived in Australia. If the ship name is{" "}
          <span className="highlight-words">not</span> in the glossary, type in the entire ship name with correct
          spelling and capitalisation to ensure consistency throughout the database. You will see a notification that
          the ship name is currently not in the glossary.
        </p>

        <p>
          Alternatively, save your entry and navigate to the Glossaries page to save the ship name to ‘Ships Arrived in
          Australia’ or ‘Ships Arrived in Queensland’. Then return to the entry you are working on to add the ship name
          from the glossary and continue entering data.
        </p>
        <img
          alt="HarryGentle Biography of Dictionary Search Page - Glossaries Menu"
          src={glossariesMenu}
          style={{ width: "18%", height: "auto", marginTop: 10 }}
        />
        <p>
          The advantage of this feature is that should other entries require the same ship name, it will already be in
          the glossary.{" "}
        </p>

        <h5 className="p-2 text-decoration-underline mt-4">OCCUPATION</h5>
        <img
          alt="HarryGentle Biography of Dictionary Search Page - Occupation Form Section"
          src={occupationAccordion}
          style={{ width: "90%", height: "auto", marginTop: 10 }}
        />
        <p className="mt-1">
          Click
          <img
            alt="HarryGentle Biography of Dictionary Search Page - Occupation Form Section - add button"
            src={addButton}
            style={{ width: "5%", height: "auto", marginRight: 6, marginLeft: 6 }}
          />
          to expand this section:
        </p>
        <img
          alt="HarryGentle Biography of Dictionary Search Page - Occupation Form Section"
          src={occupationFormSection}
          style={{ width: "90%", height: "auto", marginTop: 10 }}
        />
        <p>
          To add an occupation, start typing in the Occupation 1 field. If the occupation exists in the glossary, you
          can select it from the list. If it is <span className="highlight-words">not</span> in the glossary, type in
          the full name of the occupation with correct spelling and capitalisation to ensure consistency throughout the
          database. You will receive a notification that the occupation is currently not in the glossary of occupations.
        </p>
        <p>
          Alternatively, save the entry you are working on and navigate to the Glossaries page (see ‘Arrival in
          Australia’ above for how to access Glossaries) and add the occupation to the glossary. Return to the entry you
          are working on to add the occupation from the glossary and continue entering data.
        </p>
        <p>
          Additional occupations can be added, along with start and end dates (if known) and a description of the
          occupation, for multiple occupations by clicking
          <img
            alt="HarryGentle Biography of Dictionary Search Page - Occupation Form Section - add button"
            src={addButton}
            style={{ width: "5%", height: "auto", marginRight: 6, marginLeft: 6 }}
          />
          as many times as needed to list all of a person’s occupations.
        </p>
        <p>
          You can change the order of two or more occupations by clicking on the arrows to move the entries up or down.
          You can also delete an occupation at this stage.{" "}
        </p>
        <img
          alt="HarryGentle Biography of Dictionary Search Page - Occupation Form Section - Example 1"
          src={occupationFormExample}
        />

        <h5 className="p-2 text-decoration-underline mt-4">NOTES</h5>
        <img
          alt="HarryGentle Biography of Dictionary Search Page - Notes Form Section"
          src={notes}
          style={{ width: "90%", height: "auto", marginTop: 10 }}
        />
        <p>
          Internal Notes are not shown on the public page. They are for researchers and volunteers to record uncertain
          information, sources yet to be consulted, descendants who have contributed information, etc. Public Notes are
          published on the public pages.{" "}
        </p>
        <p>
          The note fields are divided in two; the left side is where information is entered, and the right side is where
          notes can be previewed to see how they will look on the public page (e.g., to check that the name of ship or
          newspaper appears in italics in the preview on the right).{" "}
        </p>
        <p>
          The two notes fields use basic markdown syntax to create formatted text via a toolbar with options including
          bold, italics, strike-through, hyperlink, indented quote, insert picture, and created a bulleted list:
        </p>
        <img
          alt="HarryGentle Biography of Dictionary Search Page - Notes Toolbar"
          src={notesToolbar}
          style={{ width: "40%", height: "auto", margin: 6 }}
        />
        <p>
          {" "}
          To format a word or phrase as bold, italics etc., select the word and click on B, I, etc. in the toolbar. If
          you are familiar with basic markdown syntax, you can type *Book Name* to format a title in italics or **not**
          to format a word in bold. There is a link at the top right of the Notes field to a guide on the basic syntax
          for further information.
        </p>

        <img
          alt="HarryGentle Biography of Dictionary Search Page - Notes example"
          src={notesExample}
          style={{ width: "90%", height: "auto", marginTop: 10 }}
        />

        <h5 className="p-2 text-decoration-underline mt-4">BIBLIOGRAPHY</h5>
        <p className="highlight-words">Author Field</p>
        <p>
          The processor will interpret any <span className="highlight-words">and </span>
          or <span className="highlight-words">&</span> in the author field of a citation as separate authors. If an
          author (e.g., historical society or organisation) contains an <span className="highlight-words">and </span>
          or <span className="highlight-words">&</span> , the entry can be entered by enclosing it in curly brackets{" "}
          {`{}`}.{" "}
        </p>

        <p>
          For example, if you enter <span className="highlight-words">Maleny & District Centenary Committee</span> in
          the author field, once the entry is published, it will be displayed as{" "}
          <span className="highlight-words">Committee, M. & D. C.</span> —as if it was two names joined by an ampersand
          (Surname, Initials & Surname, Initials). If you enclose an organisation name in curly brackets, e.g.{" "}
          <span className="highlight-words">{`{Maleny & District Centenary Committee}`}</span>, it will display exactly
          as entered: <span className="highlight-words">Maleny & District Centenary</span>
        </p>

        <p className="highlight-words">Place of Publication Field</p>
        <p>
          To include the place of publication in the published dictionary entry, enter the place of publication in the
          <span className="highlight-words"> 'Publisher'</span> field, followed by{" "}
          <span className="highlight-words">a colon</span> and then the{" "}
          <span className="highlight-words">publisher name</span>, as you would normally do in a Bibliography or
          References list:
        </p>
        <p>
          {" "}
          <img alt="HarryGentle Biography of Dictionary Search Page - Bibliography Example" src={bibliographyExample} />
        </p>

        <p>And then, the book citation will display as:</p>

        <p>
          {" "}
          <img
            alt="HarryGentle Biography of Dictionary Search Page - Bibliography Output Sample"
            src={bibliographyOutput}
            style={{ width: "95%", height: "auto" }}
          />
        </p>

        <h5 className="p-2 text-decoration-underline mt-4">NEXT STEPS</h5>
        <p>
          Once you have completed your entry, click on the <span className="highlight-words">Form Actions</span> menu at
          the top of the page and select <span className="highlight-words">Update</span> to save your form. Then click
          on the <span className="highlight-words">Workflow Actions</span> menu (next to Form Actions) and select{" "}
          <span className="highlight-words">Submit for Review</span> to send your entry to the administration team, who
          will review and publish it. Administration team members have an additional menu option to publish an entry
          without sending it for review.
        </p>
        <p>
          The next section of this help guide relates to the{" "}
          <span className="highlight-words">Dashboard, Glossaries</span> and{" "}
          <span className="highlight-words">Places</span> pages. Access these pages from the drop-down menu at the top
          right of the screen under your username:
        </p>

        <img
          alt="HarryGentle Biography of Dictionary Search Page - Notes example"
          src={userMenus}
          style={{ width: "20%", height: "auto", marginTop: 10 }}
        />
      </Row>
    </Row>
  );
};

const DashboardPageSection = () => {
  return (
    <Row className="p-2">
      <h5 className="help-page-headings">DASHBOARD</h5>
      <img
        alt="HarryGentle Biography of Dictionary Search Page - Notes example"
        src={dashboard}
        style={{ width: "100%", height: "auto", marginTop: 10 }}
      />
      <Row className="mx-1 my-3">
        <h5 className="sub-headings p-1 px-2">NAVIGATION</h5>
        <p>
          The <span className="highlight-words">Dashboard</span> page displays a summary of the total number of entries
          in each state (pending published, archived, out of scope) across the top of the page. Clicking on an
          underlined state name will take you to a list of entries from the particular state (all published entries, all
          draft entries, all ‘out of scope’ entries, etc.).
        </p>
        <h5 className="sub-headings p-1 px-2">CHARTS</h5>
        <p>
          Two charts to analyse the data underneath the summary totals. The line chart displays the number of entries in
          each state for the previous 12 months. The doughnut chart shows the relational proportions between entries
          from different states.
        </p>
        <p>
          Both charts are interactive and can display the number of entries at different times and compare states in
          different combinations. To display the number of entries, hover over a dot point on the line chart or a
          section of the doughnut chart. Hovering over the dot point for March in the following chart shows 66 draft
          entries and ten published entries for that month.
        </p>
        <img
          alt="HarryGentle Biography of Dictionary Search Page - Line Chart"
          src={lineChart}
          style={{ width: "70%", height: "auto", marginTop: 10 }}
        />
        <p>
          To compare entries in different states, click the chart labels to hide/show particular datasets. For example,
          to compare only published and draft entries on the doughnut chart, click on the labels{" "}
          <span className="highlight-words">Pending Reviews, Archived</span> and{" "}
          <span className="highlight-words">Out of Scope</span>. These three labels will now appear in strike-through
          text, and the accompanying datasets will be excluded from the chart. In addition, by hovering over a section
          of the doughnut chart, the number of entries will be displayed.
        </p>
        <img
          alt="HarryGentle Biography of Dictionary Search Page - Doughnut Chart"
          src={doughnutChart}
          style={{ width: "30%", height: "auto", marginTop: 10 }}
        />
        <p>
          Similarly, clicking on all labels except <span className="highlight-words">Out of Scope</span> hides those
          states <span className="highlight-words">(Published, Draft, Pending Reviews </span>
          and <span className="highlight-words">Archived)</span> on the line chart and only displays ‘out-of-scope’
          entries. In addition, hovering over the maximum point shows that 6,451 out-of-scope entries were added to the
          database in August:
        </p>
        <img
          alt="HarryGentle Biography of Dictionary Search Page - Line Chart"
          src={lineChart2}
          style={{ width: "70%", height: "auto", marginTop: 10 }}
        />
      </Row>
    </Row>
  );
};

const GlossariesPageSection = () => {
  return (
    <Row className="p-2">
      <h5 className="help-page-headings">GLOSSARIES</h5>
      <img
        alt="HarryGentle Biography of Dictionary - Glossaries Page"
        src={glossariesListPage}
        style={{ width: "90%", height: "auto" }}
      />
      <Row className="mx-1 my-3">
        <h5 className="sub-headings p-1 px-2">NAVIGATION</h5>
        <p>
          The glossaries page lists glossary types and the number of entries in each. The main aim of the glossaries is
          to allow you to search and select a particular glossary entry when creating or editing the main biography
          entry. For example, when adding the Religion field value in the create or edit form, you can type to search
          the glossary entry and select the value. Glossaries help to ensure that data is entered consistently and that
          religions, occupations and other values are standardised and not entered with different spellings (e.g.,
          ‘Servant - House’ rather than ‘House Servant’).
        </p>
        <p>
          Contributors to the Biographical Dictionary (e.g., researchers and volunteers) can only search the glossary
          entries. The administration team can add, edit and delete glossary items. Contributors can contact the
          administration team to suggest new glossary items and to report any duplication of values or errors.
        </p>
        <img
          alt="HarryGentle Biography of Dictionary - Glossaries Page"
          src={glossaryItems}
          style={{ width: "90%", height: "auto" }}
        />

        <h5 className="sub-headings p-1 px-2">ADDING A NEW GLOSSARY ENTRY</h5>
        <p>
          Only administrators can <span className="highlight-words">add</span> glossary entries. Click
          <img
            alt="HarryGentle Biography of Dictionary - Glossaries Page"
            src={addGlossaryEntryButton}
            style={{ width: "15%", height: "auto", marginRight: 6, marginLeft: 6 }}
          />
          to add a new glossary entry. The glossary name field is required; the description field is optional and can be
          empty. Click
          <img
            alt="HarryGentle Biography of Dictionary - Glossaries Page"
            src={saveAndClose}
            style={{ width: "9%", height: "auto", marginRight: 6, marginLeft: 6 }}
          />
          after adding a single glossary value (e.g., an occupation) to save the entry and take you back to the glossary
          list page. Clicking
          <img
            alt="HarryGentle Biography of Dictionary - Glossaries Page"
            src={saveAndAddAnother}
            style={{ width: "12%", height: "auto", marginRight: 6, marginLeft: 6 }}
          />
          will let you save the current entry and add another entry (e.g., a second occupation).
        </p>
        <img
          alt="HarryGentle Biography of Dictionary - Glossaries Page"
          src={addGlossaryEntry}
          style={{ width: "90%", height: "auto" }}
        />

        <h5 className="sub-headings p-1 px-2">EDITING AN EXISTING GLOSSARY ENTRY</h5>
        <p>
          Only the administration team can <span className="highlight-words">edit</span> glossary entries. Click the
          edit button
          <i className="bi bi-pencil-square mx-1" />
          to open the Edit Glossary Entry form, edit the name and description, and then click on the{" "}
          <span className="highlight-words">Update</span> button to save the data.
        </p>

        <h5 className="sub-headings p-1 px-2">DELETING AN EXISTING GLOSSARY ENTRY</h5>
        <p>
          {" "}
          Only the administration team can delete glossary entries. Click the delete button
          <i className="bi bi-trash3 mx-1" />
          to delete the entry. You will be asked to confirm that you wish to delete the glossary entry. The entry will
          be permanently deleted if you confirm by clicking the delete button.
        </p>
        <img
          alt="HarryGentle Biography of Dictionary - Glossaries Page - Delete Confirmation Dialog"
          src={deleteGlossaryConfirmationDialog}
          style={{ width: "90%", height: "auto" }}
        />
      </Row>
    </Row>
  );
};

const PlacesPageSection = () => {
  return (
    <Row className="p-2">
      <h5 className="help-page-headings">PLACES</h5>
      <img alt="HarryGentle Biography of Dictionary - Glossaries Page" src={placeListPage} />
      <Row className="mx-1 my-3">
        <h5 className="sub-headings p-1 px-2">ADDING A NEW PLACE ENTRY</h5>
        <p>
          Click the <span className="highlight-words">+ Add Place</span> button to add a new entry.
        </p>
        <img
          alt="HarryGentle Biography of Dictionary - Glossaries Page"
          src={addPlaceForm}
          style={{ width: "90%", height: "auto" }}
        />
        <p>
          <span className="highlight-words">Title</span> and <span className="highlight-words">Type</span> are required
          fields. Start by searching for the country or parent region (e.g. India) in the
          <span className="highlight-words"> Located in</span> field; if found, select it. If not found, add the country
          or parent area first. Then add the sub-region (such as a state, province or county) and add the town, suburb,
          etc. For instance, add Belfast, Antrim, Ireland in this order:
        </p>
        <p>Country: Ireland (only if it is not already in the Places list) </p>
        <p>County: Antrim </p>
        <p>City: Belfast</p>

        <p>
          If you only add one place, fill out the form and click <span className="highlight-words">Save & Close</span>.
          If you are adding multiple places, click
          <span className="highlight-words"> Save & Add Another</span> until you get to the last entry and then click{" "}
          <span className="highlight-words">Save & Close</span>.
        </p>

        <p style={{ color: "red", fontWeight: "bold" }}>
          <i>
            {" "}
            WARNING: To eliminate duplication, search the place you want to add in the ‘Located in’ field to see if it
            already exists before adding a new place.
          </i>
        </p>

        <h5 className="sub-headings p-1 px-2">UPDATING AN EXISTING PLACE ENTRY</h5>
        <p>
          Click the edit button <i className="bi bi-pencil-square"></i> to open the{" "}
          <span className="highlight-words">Update Place</span> form, update the details you want to edit, and click on
          the <span className="highlight-words">Update</span> button to save the modified data.
        </p>
        <p style={{ color: "red", fontWeight: "bold" }}>
          <i>
            {" "}
            WARNING: Updating a place will also update the place name in any fields in existing entries (birth place,
            death place, burial place, and residence or property owned location).
          </i>
        </p>

        <h5 className="sub-headings p-1 px-2">DELETING AN EXISTING PLACE ENTRY</h5>
        <p>
          Only the administration team can delete place entries. Click the delete button{" "}
          <i className="bi bi-trash3"></i> to delete an entry. You will be asked to confirm that you wish to proceed
          with the deletion. If you click the delete button, the entry will be permanently deleted.
        </p>
        {deletePlaceWarningMessage}
        <img
          alt="HarryGentle Biography of Dictionary - Glossaries Page - Delete Confirmation Dialog"
          src={deletePlace}
          style={{ width: "80%", height: "auto" }}
        />
      </Row>
    </Row>
  );
};

export const deletePlaceWarningMessage = (
  <p style={{ color: "red", fontWeight: "bold" }}>
    <i>
      {" "}
      WARNING: Deleting a place name from the Places glossary will remove it from ALL location fields (e.g., birthplace,
      residence location) in ALL dictionary entries (i.e., in existing dictionary entries for other people). AMENDING a
      place entry is the preferred action as this will update the place in both in the glossary and all existing
      entries.
    </i>
  </p>
);
