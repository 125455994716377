import { Col, Row } from "react-bootstrap";
import { OverlayTrigger } from "../../OverlayTrigger";

export const ArrayFieldMoveUpDownButtons = ({
  onUpButtonClick,
  onDownButtonClick,
  index,
  fields,
  upButtonDataTestId,
  downButtonDataTestId,
}) => {
  return (
    <>
      <Col md="auto" >
        <Row className="flex-row p-0" >
          <Col md="auto" className="p-0 mx-1">
            <OverlayTrigger text={"Move Up"}>
              <button
                id={upButtonDataTestId}
                type="button"
                className="btn btn-secondary  btn-place"
                onClick={() => onUpButtonClick()}
                disabled={index === 0}
                data-testid={upButtonDataTestId}
              >
                <i className="bi bi-arrow-up-square-fill"></i>
              </button>
            </OverlayTrigger>
          </Col>
          <Col md="auto" className="p-0 mx-1">
            <OverlayTrigger text={"Move Down"}>
              <button
                type="button"
                id={downButtonDataTestId}
                className="btn btn-secondary  btn-place"
                onClick={() => onDownButtonClick()}
                data-testid={downButtonDataTestId}
                disabled={fields.length - 1 === index}
              >
                <i className="bi bi-arrow-down-square-fill"></i>
              </button>
            </OverlayTrigger>
          </Col>
        </Row>
      </Col>
    </>
  );
};
