import { useContext } from "react";
import { BiographyStore } from "../../../stateManager/stores/BiographyStore";
import { setSingleFieldsValues } from "../utils/setSingleFieldsValues";

export const ArrayFieldUndoButton = ({ getValues, append, dataTestId, reset }) => {
  const { state } = useContext(BiographyStore);
  return (
    <button
      id={dataTestId}
      data-testid={dataTestId}
      type="button"
      onClick={() => {
        const formData = setSingleFieldsValues(state, getValues());
        append();
        reset(formData);
      }}
      className="btn btn-primary ms-2"
    >
      + UNDO
    </button>
  );
};
