import "../../scss/admin.scss";
import AdminDashboard from "./AdminDashboard";
import { useAuth } from "react-oidc-context";


const Admin = () => {
    const auth = useAuth();


  return (
    <section className="min-height-container">
      {auth.user?.profile.given_name && (
            <AdminDashboard userName={auth.user?.profile.given_name} />
      )}
    </section>
  );
};

export default Admin;
