import React, { useContext, useEffect, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { updateFormData } from "../../../stateManager/actions/BiographyActions";
import { BiographyStore } from "../../../stateManager/stores/BiographyStore";
import { ArrayFieldAddButton } from "./ArrayFieldAddButton";
import { ArrayFieldDeleteButton } from "./ArrayFieldDeleteButton";
import { ArrayFieldMoveUpDownButtons } from "./ArrayFieldMoveUpDownButtons";
import { ArrayFieldUndoButton } from "./ArrayFieldUndoButton";
import NoDataProvidedMessage from "./NoDataProvidedMessage";
import { pubTypes, pubTypeLabels } from "./PubTypes";

const titleCase = ([firstLetter, ...restOfWord]) => firstLetter.toUpperCase() + restOfWord.join("");

function PublishedSourcesArray({ classNames }) {
  const {
    control,
    register,
    reset,
    getValues,
    watch,
    formState: { errors },
  } = useFormContext();

  const { fields, append, remove, swap } = useFieldArray({
    control,
    name: "published_sources",
  });

  const [displayUndoButton, setDisplayUndoButton] = useState(false);
  const { state, dispatch } = useContext(BiographyStore);
  const publishedSourcesValues = getValues("published_sources");

  useEffect(() => {
    if (JSON.stringify(publishedSourcesValues) === JSON.stringify(state.formData.published_sources)) {
      setDisplayUndoButton(false);
    } else {
      setDisplayUndoButton(true);
    }
  }, [publishedSourcesValues, state]);

  if (state?.formData.published_sources?.length === 0 && state?.isReadOnly) {
    return <NoDataProvidedMessage classNames={classNames} />;
  }

  return (
    <div className={"col-12 " + classNames}>
      {fields.map((item, index) => {
        const itemNum = index + 1;

        const publicationFields =
          watch(`published_sources[${index}].publication_type`) in pubTypes
            ? pubTypes[watch(`published_sources[${index}].publication_type`)]
            : pubTypes["article"];

        return (
          <div className="row g-2 field-array-row" key={item.id}>
            <div className="col-md-9 field-column">
              <div className="sub-field-array-style row g-2 mb-3" id={item.id}>
                <p className="subTitle">{`Published source ` + itemNum}</p>

                <div className="col-8" id={`published_sources[${index}].pubtype-div`}>
                  <label htmlFor={`pub_type_select_${index}`}>Publication Type:</label>
                  <select
                    className="form-select"
                    aria-label={`pub_type_select_${index}`}
                    {...register(`published_sources[${index}].publication_type`)}
                  >
                    {Object.keys(pubTypes).map((key) => (
                      <option key={key} value={key}>
                        {pubTypeLabels[key]}
                      </option>
                    ))}
                  </select>
                </div>
                {publicationFields.map((fieldName, i) => {
                  return (
                    <div
                      key={`${fieldName}-${i}`}
                      className="col-12"
                      id={`published_sources[${index}].${fieldName}-div`}
                    >
                      <label htmlFor={`published_sources[${index}]..${fieldName}`}>{titleCase(fieldName)}:</label>
                      <input
                        id={`published_sources[${index}].${fieldName}`}
                        name={`published_sources[${index}].${fieldName}`}
                        {...register(`published_sources[${index}].${fieldName}`, {
                          shouldUnregister: true,
                          valueAsNumber: fieldName === "year",
                          required: ["title", "year"].includes(fieldName),
                        })}
                        placeholder={titleCase(fieldName)}
                        className="form-control"
                        type={fieldName === "year" ? "number" : "text"}
                        readOnly={state.isReadOnly}
                        aria-label={`published_sources[${index}].${fieldName}`}
                      />
                      {errors?.published_sources?.[index]?.[fieldName] && (
                        <span className="text-danger" data-testid="error-message">
                          {errors?.published_sources[index][fieldName].message}
                        </span>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
            {!state?.isReadOnly && (
              <div className="col-md-3">
                <div className="row flex-row p-0">
                  <ArrayFieldDeleteButton
                    dataTestId={`published-sources-delete-button-${index}`}
                    onClick={() => {
                      updateFormData(getValues(), dispatch);
                      remove(index);
                    }}
                  />
                  <ArrayFieldMoveUpDownButtons
                    onDownButtonClick={() => swap(index, index + 1)}
                    onUpButtonClick={() => swap(index, index - 1)}
                    index={index}
                    fields={fields}
                    upButtonDataTestId={`published-sources-${index}-up`}
                    downButtonDataTestId={`published-sources-${index}-down`}
                  />
                </div>
              </div>
            )}
          </div>
        );
      })}
      {!state?.isReadOnly && (
        <ArrayFieldAddButton
          dataTestId={"published-sources-add-button"}
          onClick={() => {
            updateFormData(getValues(), dispatch);
            append();
            updateFormData(getValues(), dispatch);
          }}
        />
      )}
      {displayUndoButton && <ArrayFieldUndoButton getValues={getValues} append={append} reset={reset} />}
    </div>
  );
}

export default PublishedSourcesArray;
