import React, { useContext } from "react";
import { Container, Navbar, Nav } from "react-bootstrap";
import { BiographyStore } from "../../../stateManager/stores/BiographyStore";
import FormActionButtons from "./FormActionButtons";
import WorkflowActionButtons from "./WorkflowActionButtons";

function FormActionsNavbar({
  methods,
  onDelete,
  onSubmit,
  onSave,
  onPublish,
  onReject,
  onArchive,
  onDraft,
  onMoveToOutOfScope,
  onRemove
}) {
  const {state} = useContext(BiographyStore)
  return (
    <Navbar className="fixed-top nav-z-index navbar-custom-style" expand="md">
      <Container className="justify-content-end">
        <Nav>
          <FormActionButtons
            methods={methods}
            onDelete={onDelete}
            onSubmit={onSubmit}
            onSave={onSave}
          />
          {state.id && state.possibleTransitions && state.possibleTransitions.length > 0 && (
            <WorkflowActionButtons
              onPublish={onPublish}
              onReject={onReject}
              onArchive={onArchive}
              onDraft={onDraft}
              onSubmit={onSubmit}
              onMoveToOutOfScope={onMoveToOutOfScope}
              onRemove={onRemove}
            />
          )}
        </Nav>
      </Container>
    </Navbar>
  );
}

export default FormActionsNavbar;
