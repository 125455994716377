import React, { useContext, useEffect, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import UncertainDateController from "../FormComponents/UncertainDateController";
import { updateFormData } from "../../../stateManager/actions/BiographyActions";
import { BiographyStore } from "../../../stateManager/stores/BiographyStore";
import NoDataProvidedMessage from "./NoDataProvidedMessage";
import { ArrayFieldAddButton } from "./ArrayFieldAddButton";
import { ArrayFieldUndoButton } from "./ArrayFieldUndoButton";
import { ArrayFieldDeleteButton } from "./ArrayFieldDeleteButton";
import { ArrayFieldMoveUpDownButtons } from "./ArrayFieldMoveUpDownButtons";
import { PersonSearch } from "./PersonSearch";
import LabelAndLocationSearch from "../FormComponents/LabelAndLocationSearch";

function MarriagesArray({ classNames, onShowDatesFormatLinkClicked }) {
  const {
    control,
    setValue,
    register,
    getValues,
    reset,
    clearErrors,
    formState: { errors },
  } = useFormContext();

  const { fields, append, remove, swap } = useFieldArray({
    control,
    name: "marriages",
  });

  const [displayUndoButton, setDisplayUndoButton] = useState(false);
  const { state, dispatch } = useContext(BiographyStore);
  const marriagesValues = getValues("marriages");

  useEffect(() => {
    if (JSON.stringify(marriagesValues) === JSON.stringify(state.formData.marriages)) {
      setDisplayUndoButton(false);
    } else {
      setDisplayUndoButton(true);
    }
  }, [marriagesValues, state]);

  const MarriagesFieldArrayLabel = () => {
    return (
      <label htmlFor="marriages" className="form-label">
        Marriages:
      </label>
    );
  };

  if (state?.formData.marriages?.length === 0 && state?.isReadOnly) {
    return (
      <div className={"col-12 " + classNames}>
        <MarriagesFieldArrayLabel />
        <NoDataProvidedMessage />
      </div>
    );
  }

  return (
    <div className={"col-12 " + classNames}>
      <MarriagesFieldArrayLabel />

      <div>
        {fields.map((item, index) => {
          const itemNum = index + 1;

          return (
            <div className="row g-2 field-array-row" key={item.id}>
              <div className="col-md-9 field-column">
                <div className="sub-field-array-style row g-2 mb-3" id={item.id}>
                  <p className="subTitle">{`Marriage ` + itemNum}</p>
                  <PersonSearch
                    displaySearchButton={true}
                    registerText={`marriages[${index}].spouses[0]`}
                    placeholder={"Search & Select or Add a new Entry"}
                  />
                  <LabelAndLocationSearch
                    onFocus={() => {
                      clearErrors("marriages");
                    }}
                    displayLabel={false}
                    name={`marriages[${index}].place_of_marriage`}
                    classNames={"col-md-12 mt-2"}
                    placeholder={"Search and select or click + Place to add a new place"}
                    displayAddButton={true}
                    control={control}
                    getValues={getValues}
                    setValue={setValue}
                    propertyOwnedLocation={false}
                    weddingLocation={true}
                  />

                  <div className="col-6">
                    <UncertainDateController
                      name={`marriages[${index}].start_date`}
                      error={errors?.marriages?.[index]?.start_date?.message}
                      placeholder="Start date"
                      displayDatesInfoLink={true}
                      onShowDatesFormatLinkClicked={onShowDatesFormatLinkClicked}
                      onFocus={() => {
                        clearErrors("marriages");
                      }}
                    />
                  </div>
                  <div className="col-6">
                    <UncertainDateController
                      name={`marriages[${index}].end_date`}
                      error={errors?.marriages?.[index]?.end_date?.message}
                      placeholder="End date"
                      displayDatesInfoLink={true}
                      onShowDatesFormatLinkClicked={onShowDatesFormatLinkClicked}
                      onFocus={() => {
                        clearErrors("marriages");
                      }}
                    />
                  </div>
                  <div className="col-12">
                    <input
                      name={`marriages[${index}].notes`}
                      id={`marriages[${index}].notes`}
                      {...register(`marriages[${index}].notes`)}
                      placeholder="Notes"
                      className="form-control"
                      readOnly={state.isReadOnly}
                      onFocus={() => {
                        clearErrors("marriages");
                      }}
                    />
                  </div>
                  {errors?.marriages?.[index]?.spouses?.length && (
                    <span className="text-danger" data-testid="error-message" id={index + "-marriages-error-message"}>
                      {errors?.marriages[index]?.spouses[0]?.message}
                    </span>
                  )}
                </div>
              </div>
              {!state?.isReadOnly && (
                <div className="col-md-3">
                  <div className="row flex-row p-0">
                    <ArrayFieldDeleteButton
                      dataTestId={`marriages-delete-button-${index}`}
                      onClick={() => {
                        updateFormData(getValues(), dispatch);
                        remove(index);
                      }}
                    />
                    <ArrayFieldMoveUpDownButtons
                      onDownButtonClick={() => swap(index, index + 1)}
                      onUpButtonClick={() => swap(index, index - 1)}
                      index={index}
                      upButtonDataTestId={`marriages[${index}].up`}
                      downButtonDataTestId={`marriages[${index}].down`}
                      fields={fields}
                    />
                  </div>
                </div>
              )}
            </div>
          );
        })}
        {!state?.isReadOnly && (
          <ArrayFieldAddButton
            onClick={() => {
              append({ spouses: [], place_of_marriage: "", start_date: "", end_date: "", notes: "" });
              updateFormData(getValues(), dispatch);
            }}
            dataTestId={"marriages-add-button"}
          />
        )}
        {displayUndoButton && (
          <ArrayFieldUndoButton
            getValues={getValues}
            append={append}
            reset={reset}
            dataTestId={"marriages-undo-button"}
          />
        )}
      </div>
    </div>
  );
}

export default MarriagesArray;
