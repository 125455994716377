export const setSingleFieldsValues = (state, data) => {
  const firsName = data["first_name"];
  const middleName = data["middle_name"];
  const surname = data["surname"];
  const gender = data["gender"];
  const indigenous_status = data["indigenous_status"];
  const language_group = data["language_group"];
  const blanket_distribution_date = data["blanket_distribution_date"];
  const cultural_heritage = data["cultural_heritage"];
  const date_of_birth = data["date_of_birth"];
  const date_of_death = data["date_of_death"];
  const religion = data["religion"];
  const date_arrived_australia = data["date_arrived_australia"];
  const date_arrived_qld = data["date_arrived_qld"];
  const notes = data["notes"];
  const familynotes = data["familynotes"];
  const blanket_distribution_centre = data["blanket_distribution_centre"];
  const place_of_birth = data["place_of_birth"];
  const place_of_death = data["place_of_death"];
  const place_of_burial = data["place_of_burial"];
  const ship_arrived_in_australia = data["ship_arrived_in_australia"];
  const ship_arrived_in_qld = data["ship_arrived_in_qld"];

  return {
    ...state.formData,
    first_name: firsName,
    middle_name: middleName,
    surname: surname,
    gender: gender,
    indigenous_status: indigenous_status,
    language_group: language_group,
    blanket_distribution_date: blanket_distribution_date,
    cultural_heritage: cultural_heritage,
    date_of_birth: date_of_birth,
    date_of_death: date_of_death,
    religion: religion,
    date_arrived_australia: date_arrived_australia,
    date_arrived_qld: date_arrived_qld,
    notes: notes,
    familynotes: familynotes,
    blanket_distribution_centre: blanket_distribution_centre,
    place_of_birth: place_of_birth,
    place_of_death: place_of_death,
    place_of_burial: place_of_burial,
    ship_arrived_in_australia: ship_arrived_in_australia,
    ship_arrived_in_qld: ship_arrived_in_qld,
  };
};
