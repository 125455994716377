import React from "react";
import { OverlayTrigger } from "./OverlayTrigger";

const PaginationBar = (props) => {
  let items = [];
  let pages = Math.ceil(props.itemCount / 10.0);
  if (pages <= 10) {
    for (let number = 1; number <= pages; number++) {
      items.push(
        <li className={`page-item${number === props.currentPage ? " active" : ""}`} key={number}><button className="page-link" onClick={() => props.onChange(number)}>{number}</button></li>
      );
    }
  } else {
    for (let number = 1; number <= pages; number++) {
      if (number === 1 ||  number === props.currentPage || number === props.currentPage - 1 || number === props.currentPage + 1 || number === props.currentPage - 2 || number === props.currentPage + 2 || number === pages) {
        items.push(
          <li className={`page-item${number === props.currentPage ? " active" : ""}`} key={number}><button className="page-link" onClick={() => props.onChange(number)}>{number}</button></li>
        );
      } else if (number === props.currentPage - 3 || number === props.currentPage + 3 ) {
        items.push(
          <li className={`page-item disabled`} key={number}><button className="page-link">...</button></li>
        );
      }
    }
  }

  return (
    <>
      <nav aria-label="Page navigation example">
        <ul className="pagination">
          <li className={`page-item${props.currentPage === 1 ? " disabled" : ""}`}>
            <OverlayTrigger text={"Previous"} placement={'bottom'}>
              <button className="page-link" aria-label="Previous" onClick={() => props.onChange(props.currentPage - 1)}>
                <i className="bi bi-chevron-left" />
              </button>
            </OverlayTrigger>
          </li>
          {items}
          <li className={`page-item${props.currentPage === pages ? " disabled" : ""}`}>
            <OverlayTrigger text={"Next"} placement={'bottom'}>
              <button className="page-link" aria-label="Next" onClick={() => props.onChange(props.currentPage + 1)}>
                <i className="bi bi-chevron-right" />
              </button>
            </OverlayTrigger>
          </li>
        </ul>
      </nav>
    </>
  );
};

export default PaginationBar;
