import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import ReactSelect from "react-select";
import { getOptions } from "./formSelectOptions";
import "../../../scss/biography-entry-form.scss";

function LabelAndSelect({
  labelText,
  name,
  classNames,
  placeholder,
  error,
  control,
  isReadOnly,
  searchFieldStyle,
  onSelectionChange,
  getValues,
  labelClassName = "form-label",
}) {
  const options = getOptions(name);
  const [selectedOption, setSelectedOption] = useState(null);
  const fieldValue = getValues(name);

  // setting the selected option based on fieldValue
  useEffect(() => {
    !fieldValue && setSelectedOption(null);
    if (fieldValue) {
      setSelectedOption(options.find((o) => o?.value === fieldValue));
    }
  }, [fieldValue, options]);


  return (
    <div className={classNames}>
      {labelText && (
        <label htmlFor="labelText" className={labelClassName}>
          {labelText}:
        </label>
      )}
      <div className="reactSelectController">
        <Controller
          name={name}
          control={control}
          render={({ field: { onChange } }) => (
            <ReactSelect
              styles={searchFieldStyle}
              isClearable
              id={name}
              aria-label={labelText}
              isDisabled={isReadOnly}
              placeholder={placeholder}
              value={selectedOption}
              name={name}
              options={options}
              onChange={(option) => {
                onSelectionChange(option);
                setSelectedOption(option);
                onChange(option?.value);
              }}
            />
          )}
        />
        {error && (
          <span className="text-danger" data-testid="error-message" id={name+"-error-message"}>
            {error}
          </span>
        )}
      </div>
    </div>
  );
}

export default LabelAndSelect;
