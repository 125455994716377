import { Badge, Table } from "react-bootstrap";
import { useSortBy, useTable } from "react-table/dist/react-table.development";
import { FormatToDate } from "./BiographyEntryForm/utils/Reformat";
import { getBadgeClassName, getIdFromUrl, getStateName } from "./BiographyEntryForm/utils/utils";
import { Link, useNavigate } from "react-router-dom";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { getOptions } from "./BiographyEntryForm/FormComponents/formSelectOptions";
import { OverlayTrigger } from "./OverlayTrigger";

/* 
We are using react-table for most of all biography tables, for example, 
the component is used for a general search, relationship search on bio create/edit entry form,
Dashboard pages - Draft, Pending review, Published, out-of-scope and Archive entries pages.
The component has been a bit of conditional logic based on where it's being used.
*/

const formatOccupationData = (occupationData) => {
  return occupationData.map((value, index) => {
    if (index < occupationData.length - 1 && occupationData.length > 1){
      return <div key={index}>{value.occupation_name + ","}</div>;
    }
    return <div key={index}>{value.occupation_name}</div>;
  });
};

const formatOtherNamesData = (otherNames) => {
  return otherNames.map((name, index) => {
    if (index < otherNames.length - 1 && otherNames.length > 1){
      return <div key={index}>{name.name + ","}</div>;
    }
    return <div key={index}>{name.name}</div>;
  });
};

const formatResidenceOrPropertyOwnedPlaceData = (residenceOrPropertyOwnedData) => {
  if (residenceOrPropertyOwnedData?.length > 0)
    return residenceOrPropertyOwnedData.map((value, index) => {
      return (
        <div key={index}>
          {value.location_full_title && value.location_full_title !== "None" && "- " + value.location_full_title}
        </div>
      );
    });
  else {
    return "-";
  }
};

const formatResidenceOrPropertyOwnedNameData = (residenceOrPropertyOwnedData) => {
  if (residenceOrPropertyOwnedData?.length > 0)
    return residenceOrPropertyOwnedData.map((value, index) => {
      return <div key={index}>{value.name && value.name !== "None" && "- " + value.name}</div>;
    });
  else {
    return "-";
  }
};

const formatGenderData = (genderValue) => {
  const options = getOptions("gender");
  return options.find((gender) => gender.value === genderValue).label;
};

export const columnIds = {
  firstName: "first_name",
  middleName: "middle_name",
  surname: "surname",
  otherNames: "other_names",
  gender: "gender",
  placeOfBirth: "place_of_birth",
  placeOfDeath: "place_of_death",
  placeOfBurial: "place_of_burial",
  dateOfBirth: "date_of_birth",
  dateOfDeath: "date_of_death",
  indigenousStatus: "indigenous_status",
  culturalHeritage: "cultural_heritage",
  religion: "religion",
  occupation: "occupation",
  propertyOwnedName: "property_owned_name",
  propertyOwnedLocation: "property_owned_location",
  shipArrivedInAustralia: "ship_arrived_in_australia",
  shipArrivedInQld: "ship_arrived_in_qld",
  dateArrivedInAustralia: "date_arrived_australia",
  dateArrivedInQld: "date_arrived_qld",
};

export const hiddenColIds = [
  columnIds.middleName,
  columnIds.otherNames,
  columnIds.placeOfBurial,
  columnIds.indigenousStatus,
  columnIds.religion,
  columnIds.occupation,
  columnIds.propertyOwnedName,
  columnIds.propertyOwnedLocation,
  columnIds.shipArrivedInAustralia,
  columnIds.shipArrivedInQld,
  columnIds.dateArrivedInAustralia,
  columnIds.dateArrivedInQld,
];

export const columns = [
  {
    id: columnIds.surname, // id should match with the python drf person model field
    key: columnIds.surname,
    Header: "Surname",
    accessor: "surname",
  },
  {
    id: columnIds.firstName, // id should match with the python drf person model field
    key: columnIds.firstName,
    Header: "First Name",
    accessor: "first_name",
  },
  {
    id: columnIds.middleName, // id should match with the python drf person model field
    key: columnIds.middleName,
    Header: "Middle Name",
    accessor: "middle_name",
    disableSortBy: true,
  },
  {
    id: columnIds.otherNames, // id should match with the python drf person model field
    key: columnIds.otherNames,
    Header: "Other Names",
    accessor: "other_names",
    Cell: ({ value }) => formatOtherNamesData(value),
    disableSortBy: true,
  },

  {
    id: columnIds.gender, // id should match with the python drf person model field
    key: columnIds.gender,
    Header: "Gender",
    accessor: "gender",
    Cell: ({ value }) => formatGenderData(value),
    disableSortBy: true,
  },
  {
    id: columnIds.placeOfBirth, // id should match with the python drf person model field
    key: columnIds.placeOfBirth,
    Header: "Place of Birth",
    accessor: "place_of_birth.full_title",
    disableSortBy: true,
  },
  {
    id: columnIds.placeOfDeath, // id should match with the python drf person model field
    key: columnIds.placeOfDeath,
    Header: "Place of Death",
    accessor: "place_of_death.full_title",
    disableSortBy: true,
  },
  {
    id: columnIds.placeOfBurial, // id should match with the python drf person model field
    key: columnIds.placeOfBurial,
    Header: "Place of Burial",
    accessor: "place_of_burial.full_title",
    disableSortBy: true,
  },
  {
    id: columnIds.dateOfBirth, // id should match with the python drf person model field
    key: columnIds.dateOfBirth,
    Header: "Date of Birth",
    accessor: "date_of_birth",
    Cell: ({ value }) => FormatToDate(value),
    disableSortBy: true,
  },
  {
    id: columnIds.dateOfDeath, // id should match with the python drf person model field
    key: columnIds.dateOfDeath,
    Header: "Date of Death",
    accessor: "date_of_death",
    Cell: ({ value }) => FormatToDate(value),
    disableSortBy: true,
  },
  {
    id: columnIds.indigenousStatus, // id should match with the python drf person model field
    key: columnIds.indigenousStatus,
    Header: "Indigenous Status",
    accessor: "indigenous_status",
    disableSortBy: true,
  },
  {
    id: columnIds.culturalHeritage, // id should match with the python drf person model field
    key: columnIds.culturalHeritage,
    Header: "Cultural Heritage",
    accessor: "cultural_heritage",
    disableSortBy: true,
  },
  {
    id: columnIds.religion, // id should match with the python drf person model field
    key: columnIds.religion,
    Header: "Religion",
    accessor: "religion",
    disableSortBy: true,
  },
  {
    id: columnIds.occupation, // id should match with the python drf person model field
    key: columnIds.occupation,
    Header: "Occupation",
    accessor: "occupation",
    Cell: ({ value }) => formatOccupationData(value),
    disableSortBy: true,
  },
  {
    id: columnIds.propertyOwnedName,
    key: columnIds.propertyOwnedName,
    Header: "Residence or Property Owned Name",
    accessor: "property_owned",
    Cell: ({ value }) => formatResidenceOrPropertyOwnedNameData(value),
    disableSortBy: true,
  },
  {
    id: columnIds.propertyOwnedLocation,
    key: columnIds.propertyOwnedLocation,
    Header: "Residence or Property Owned Location",
    accessor: "property_owned",
    Cell: ({ value }) => formatResidenceOrPropertyOwnedPlaceData(value),
    disableSortBy: true,
  },
  {
    id: columnIds.shipArrivedInAustralia, // id should match with the python drf person model field
    key: columnIds.shipArrivedInAustralia,
    Header: "Ship name arrived in Australia",
    accessor: "ship_arrived_in_australia",
    disableSortBy: true,
  },
  {
    id: columnIds.shipArrivedInQld, // id should match with the python drf person model field
    key: columnIds.shipArrivedInQld,
    Header: "Ship name arrived in Queensland",
    accessor: "ship_arrived_in_qld",
    disableSortBy: true,
  },
  {
    id: columnIds.dateArrivedInAustralia, // id should match with the python drf person model field
    key: columnIds.dateArrivedInAustralia,
    Header: "Date arrived in Australia",
    accessor: "date_arrived_australia",
    Cell: ({ value }) => FormatToDate(value),
    disableSortBy: true,
  },
  {
    id: columnIds.dateArrivedInQld, // id should match with the python drf person model field
    key: columnIds.dateArrivedInQld,
    Header: "Date arrived in Queensland",
    accessor: "date_arrived_qld",
    Cell: ({ value }) => FormatToDate(value),
    collapse: true,
    disableSortBy: true,
  },
  {
    id: "state", // id should match with the python drf person model field
    key: "state",
    Header: "State",
    accessor: "state",
    Cell: ({ value }) => (
      <Badge pill bg={getBadgeClassName(value)}>
        {" "}
        {getStateName(value)}
      </Badge>
    ),
  },
  {
    id: "Action", // This is the custom column, so it's fine if it doesn't match with any model fields
    key: "Action",
    Header: "Action",
    accessor: "url",
    disableSortBy: true,
    Cell: ({ value }) => (
      <Link className="btn btn-primary" to={`/view-bio-entry/${getIdFromUrl(value)}`}>
        View
      </Link>
    ),
  },
  {
    id: "url", // id should match with the python drf person model field
    key: "url",
    Header: "Url",
    accessor: "url",
    disableSortBy: true,
  },
];

const BiographyTable = forwardRef(
  ({ data, isSearchModal, onPersonSelected, parameters, setParameters, hideStateColumn, hideActionColumn }, ref) => {
    const navigate = useNavigate();
    const [hiddenCols, setHiddenCols] = useState([...hiddenColIds]);

    // We do not want to display action column when searching for relationships search or general search page
    // We do display action column (with view button - linked to view person entry) for all dashboard searches.
    // Published, pending review, draft, out-of-scope and archive search tables.
    if (hideActionColumn && !hiddenCols.includes("Action")) {
      setHiddenCols([...hiddenCols, "Action"]);
    }

    // Only display state column for Manage entries page. Hide for rest of the search tables.
    if (hideStateColumn && !hiddenCols.includes("state")) {
      setHiddenCols([...hiddenCols, "state"]);
    }

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, setHiddenColumns, allColumns } = useTable(
      {
        columns,
        data,
      },
      useSortBy
    );

    useImperativeHandle(ref, () => ({
      hideShowColumn(column) {
        allColumns.find((col) => col.id === column.id).toggleHidden();
      },
    }));

    // Returns hidden columns based on whether we want to add a column or not.
    useEffect(() => {
      if (hiddenCols) setHiddenColumns(hiddenCols);
    }, [parameters, hiddenCols, setHiddenColumns]);

    // Only display icon if col is sorted otherwise do not - so returns icon or empty string
    const displayIconIfColSorted = (column) => {
      return column.canSort ? displayIcon(column) : <>{""}</>;
    };

    // return icon based on col sort value either up or down
    const displayIcon = (column) => {
      if (column.canSort && parameters.ordering === undefined) {
        return <></>;
      }
      if (column.canSort && parameters.ordering?.includes(`-${column.id}`)) {
        return <i className="bi bi-sort-alpha-up flex-shrink-0"></i>;
      } else if (column.canSort && parameters.ordering.includes(column.id)) {
        return <i className="bi bi-sort-alpha-down flex-shrink-0"></i>;
      }
      return <></>;
    };

    // Perform action based on from where the Cell is being clicked.
    const onCellClicked = (selectedRow) => {
      const row = selectedRow.values;

      // if it's search modal, then we want to pass a function onPersonSelected with row as props.
      // Else we want user to redirect to biography page
      if (isSearchModal) {
        onPersonSelected({ row });
      } else {
        navigate("/biography/" + getIdFromUrl(row?.url));
      }
    };

    const sortColumn = (column) => {
      let sortBy = parameters.ordering?.length ? parameters.ordering : [];
      if (column.canSort && !parameters.ordering?.includes(`-${column.id}`)) {
        sortBy = sortBy.filter((item) => item !== column.id);
        sortBy.push(`-${column.id}`);
      } else if (column.canSort && !parameters.ordering?.includes(column.id)) {
        sortBy = sortBy.filter((item) => item !== `-${column.id}`);
        sortBy.push(column.id);
      }
      setParameters({ ...parameters, ordering: [...sortBy] });
    };

    return (
      <div className="search-table">
        <Table {...getTableProps()} responsive="sm" className="table-hover mt-3">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(
                  (column) =>
                    column.Header !== "Url" && (
                      <th onClick={() => sortColumn(column)} key={column.id} style={{ minWidth: 115 }}>
                        {column.canSort && (
                          <OverlayTrigger text={column.canSort ? "Toggle Sortby" : ""}>
                            <span> {column.render("Header")} </span>
                          </OverlayTrigger>
                        )}
                        {!column.canSort && <span> {column.render("Header")} </span>}

                        <span>{displayIconIfColSorted(column)}</span>
                      </th>
                    )
                )}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr key={i} {...row.getRowProps()}>
                  {row.cells.map((cell, index) => {
                    if (cell.column.Header !== "Url") {
                      return (
                        <td key={index} onClick={() => (cell.column.Header !== "Action" ? onCellClicked(row) : null)}>
                          {cell.value ? cell.render("Cell") : "-"}
                        </td>
                      );
                    }
                    return null
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    );
  }
);

export default BiographyTable;
