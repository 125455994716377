// list of required fields for various publication types based on Bibtex.
// In the future some optional fields like url, issn etc should probably be added as well.


const titleCase = ([firstLetter, ...restOfWord]) =>
  firstLetter.toUpperCase() + restOfWord.join("");
  


const pubTypes = {
    article: [
        "author",
        "title",
        "journal",
        "year",
        "month",
        "volume",
        "number",
        "pages",
        "url",
        "doi",
        "issn",
        "note"
    ],
    book: [
        "author",
        "title",
        "publisher",
        "address",
        "year",
        "url",
        "doi",
        "isbn"
    ],
    book_edited: [ // Included to make a distinction between authored and edited book. Will be reconsiled with bibtex book type during rendering on the biography page.
        "editor",
        "title",
        "publisher",
        "address",
        "year",
        "url",
        "doi",
        "isbn"
    ],    
    booklet: [
        "title",
        "author",
        "howpublished",
        "month",
        "year",
        "url",
        "doi"
    ],
    inbook: [
        "author",
        "editor",        
        "title",
        "booktitle",
        "publisher",
        "year",
        "volume",
        "number",
        "series",
        "type",
        "address",
        "edition",
        "month",
        "pages",
        "url",
        "doi",
        "isbn"
    ],
    incollection: [
        "author",
        "title",
        "booktitle",
        "publisher",
        "year",
        "editor",
        "volume",
        "number",
        "series",
        "type",
        "chapter",
        "pages",
        "edition",
        "month",
        "address",
        "note",
        "url",
        "doi"
    ],
    inproceedings: [
        "author",
        "title",
        "booktitle",
        "series",
        "year",
        "month",
        "editor",
        "volume",
        "number",
        "pages",
        "publisher",
        "organization",
        "address",
        "url",
        "doi"
    ],
    manual: [
        "title",
        "author",
        "organization",
        "address",
        "year",
        "url",
        "doi"
    ],
    mastersthesis: [
        "author",
        "title",
        "school",
        "year",
        "address",
        "month",
        "url",
        "doi"
    ],
    misc: [
        "title",
        "author",
        "howpublished",
        "year",
        "note",
        "url",
        "doi"
    ],
    phdthesis: [
        "author",
        "title",
        "school",
        "address",
        "year",
        "month",
        "url",
        "doi"
    ],
    proceedings: [
        "editor",
        "title",
        "booktitle",
        "series",
        "volume",
        "publisher",
        "address",
        "year",
        "month",
        "organization",
        "url",
        "doi"
    ],
    techreport: [
        "title",
        "author",
        "institution",
        "address",
        "number",
        "year",
        "month",
        "url",
        "doi"
    ],
    unpublished: [
        "author",
        "title",
        "year",
        "url",
        "doi"        
    ]
}

const fieldNames = new Set();
for (let pubType of Object.keys(pubTypes)) {
    for (let field of pubTypes[pubType]) {
        fieldNames.add(field);
    }
}

// Allows to override display labels if required. 
const customLabels = {};

const defaultLabels = {}
for (let field of fieldNames.entries()) {
    defaultLabels[field] = titleCase(field);
}


const pubFieldLabels = {...defaultLabels, ...customLabels};

const defaultPubFieldLabels = {}
for (let field of Object.keys(pubTypes)) {
    defaultPubFieldLabels[field] = titleCase(field);
}

// Override some of the Bibtex types with more user friendly labels. 
const customPubTypeLabels = {
    incollection: "Article in Collection / Chapter with Authors in Edited Book",
    inbook: "Chapter in a Book",
    inproceedings: "Conference Paper in Proceesings",
    mastersthesis: "Masters Thesis",
    phdthesis: "PhD Thesis",
    proceedings: "Conference Proceeding",
    misc: "Miscellaneous Publication",
    techreport: "Technical Report",
    book: "Authored Book",
    book_edited: "Edited Book"     
}

const pubTypeLabels = {...defaultPubFieldLabels, ...customPubTypeLabels}


// currently unused but added in case we want to show longer explanations for pub types, e.g. from
// https://www.bibtex.com/e/entry-types/
const pubTypeNotes = {
    article: "",
    book: "",
    book_edited: "",
    booklet: "",
    inbook: "",
    incollection: "",
    inproceedings: "",
    manual: "",
    mastersthesis: "",
    misc: "",
    phdthesis: "",
    proceedings: "",
    techreport: "",
    unpublished: ""
}

// Object.keys(pubTypes)

export {pubTypes, fieldNames, pubFieldLabels, pubTypeNotes, pubTypeLabels};
