import axios from "axios";
const baseURL = `/api/v1/places`;

const headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

const getPlaceByTitle = ({ title }) => {
  return axios.get(`${baseURL}?title__icontains=${title}`);
};

const create = (data) => {
  return axios.post(baseURL, data, headers);
};

const getById = (id) => {
  return axios.get(`${baseURL}/${id}`);
};

// prefixed with underscored because delete is a reserved word in javascript
const _delete = (id) => {
  return axios.delete(`${baseURL}/${id}`, headers);
};

const update = (id, params) => {
  return axios.put(`${baseURL}/${id}`, params, headers);
};

const getPlaceOptions = (id) => {
  return axios.options(`${baseURL}/${id}`);
};

const getPlaceList = () => {
  return axios.get(baseURL)
}


export const placeAPI = {
  getPlaceByTitle,
  create,
  getById,
  update,
  getPlaceOptions,
  getPlaceList,
  delete: _delete,
};


