// Reusable data input structure
export const LabelAndInput = ({
  labelText,
  error,
  classNames = "",
  labelClassName = "form-label",
  placeholder = "",
  registerText,
  register,
  required,
  isReadOnly,
  onFocus,
}) => {
  return (
    <>
      <div className={classNames}>
        <label htmlFor={labelText} className={labelClassName}>
          {labelText}:
        </label>
        <input
          id={registerText}
          aria-label={labelText}
          className="form-control"
          placeholder={placeholder}
          {...register(registerText, { required: required })}
          type={"text"}
          readOnly={isReadOnly}
          onFocus={onFocus}
        />
        {error && (
          <span className="text-danger" data-testid="error-message">
            {error}
          </span>
        )}
      </div>
    </>
  );
};
