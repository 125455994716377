import { useContext } from "react";
import { Form } from "react-bootstrap";
import { Controller, useFormContext } from "react-hook-form";
import { BiographyStore } from "../../../stateManager/stores/BiographyStore";

// Reusable data input structure
export const IndigenousStatus = ({ labelText, registerText, indigenousStatus }) => {
  const { control } = useFormContext();
  const { state } = useContext(BiographyStore);

  return (
    <>
      <div className="col-md-12">
        <label htmlFor="indigenous_status" className="form-check-label" id={registerText}>
          {labelText}
        </label>
      </div>
      <div className="col-md-12" id="indigenous_status">
        <Controller
          name={registerText}
          control={control}
          id="yes-no"
          render={({ field: { value, onChange } }) => (
            <>
              {" "}
              <Form.Check
                inline
                type="radio"
                value={value}
                disabled={state?.isReadOnly}
                label={"Yes"}
                name={registerText}
                checked={indigenousStatus ? true : false}
                onChange={() => {
                  onChange(true);
                }}
                id={"yes"}
              />
              <Form.Check
                inline
                type="radio"
                value={value}
                checked={indigenousStatus ? false : true}
                disabled={state?.isReadOnly}
                label={"No"}
                name={registerText}
                onChange={() => {
                  onChange(false);
                }}
                id={"no"}
              />
            </>
          )}
        />
      </div>
    </>
  );
};
