import React, { useCallback, useContext, useEffect, useState } from "react";
import JumbotronPanel from "../components/JumbotronPanel";
import { HarryGentleResourceCentreInfo } from "../components/HarryGentleResourceCentreInfo";
import { Overview } from "../components/Overview";
import { Spinner } from "../components/Spinner";
import { SignInContext } from "../stateManager/stores/SignInContext";
import { useAuth } from "react-oidc-context";
import { Alert, Container } from "react-bootstrap";
import { HowToBecomeContributor } from "../components/HowToBecomeContributor";
import { HowToUseDictionary } from "../components/HowToUseDictionary";
import { contentAPI } from "../api/contentAPI";
import {
  defaultBecomeAContributorContent,
  defaultBecomeAContributorTitle,
  defaultHarryGentleResourceCentreContent,
  defaultHarryGentleResourceCentreTitle,
  defaultHowToUseDictionaryContent,
  defaultHowToUseDictionaryTitle,
  defaultOverviewContent,
  defaultOverviewTitle,
} from "../content/homePageContent";
import SearchButtonPanel from "../components/SearchButtonPanel";

function HomePage() {
  const { isApprovedUser, displayPendingUserAccountMessage, setIsApprovedUser } = useContext(SignInContext);
  const auth = useAuth();
  const [displayPendingMessage, setDisplayPendingMessage] = useState(undefined);
  const [content, setContent] = useState(null);

  const [overviewContent, setOverviewContent] = useState({
    content: defaultOverviewContent,
    title: defaultOverviewTitle,
  });
  const [howToUseDictionaryContent, setHowToUseDictionaryContent] = useState({
    content: defaultHowToUseDictionaryContent,
    title: defaultHowToUseDictionaryTitle,
  });
  const [howToBecomeContributorContent, setHowToBecomeContributorContent] = useState({
    content: defaultBecomeAContributorContent,
    title: defaultBecomeAContributorTitle,
  });
  const [harryGentleResourceCentreContent, setHarryGentleResourceCentreContent] = useState({
    content: defaultHarryGentleResourceCentreContent,
    title: defaultHarryGentleResourceCentreTitle,
  });

  useEffect(() => {
    displayPendingUserAccountMessage !== undefined && setDisplayPendingMessage(displayPendingUserAccountMessage);
    isApprovedUser && setDisplayPendingMessage(false);
  }, [displayPendingUserAccountMessage, isApprovedUser]);

  useEffect(() => {
    contentAPI
      .getContent()
      .then((response) => {
        response.data.results.length > 0 && setContent(response.data.results);
      })
      .catch(() => {
        setContent(null);
      });
  }, []);

  const findSectionContent = useCallback((contentId) => {
    return content?.find((contentItem) => contentItem.content_id === contentId);
  }, [content]);

  useEffect(() => {
    if (content) {
      const overviewSectionContent = findSectionContent("overview");
      overviewSectionContent &&
        setOverviewContent({
          content: overviewSectionContent?.content ? overviewSectionContent?.content : defaultOverviewContent,
          title: overviewSectionContent?.title ? overviewSectionContent?.title : defaultOverviewTitle,
        });

      const howToUseDictionarySectionContent = findSectionContent("how-to-use-dictionary");
      howToUseDictionarySectionContent &&
        setHowToUseDictionaryContent({
          content: howToUseDictionarySectionContent?.content
            ? howToUseDictionarySectionContent?.content
            : defaultHowToUseDictionaryContent,
          title: howToUseDictionarySectionContent?.title
            ? howToUseDictionarySectionContent?.title
            : defaultHowToUseDictionaryTitle,
        });

      const howToBecomeContributorSectionContent = findSectionContent("how-to-become-contributor");
      howToBecomeContributorSectionContent &&
        setHowToBecomeContributorContent({
          content: howToBecomeContributorSectionContent?.content
            ? howToBecomeContributorSectionContent?.content
            : defaultBecomeAContributorContent,
          title: howToBecomeContributorSectionContent?.title
            ? howToBecomeContributorSectionContent?.title
            : defaultBecomeAContributorTitle,
        });

      const harryGentleResourceCentreSectionContent = findSectionContent("harry-gentle-resource-centre-website-info");
      harryGentleResourceCentreSectionContent &&
        setHarryGentleResourceCentreContent({
          content: harryGentleResourceCentreSectionContent?.content
            ? harryGentleResourceCentreSectionContent?.content
            : defaultHarryGentleResourceCentreContent,
          title: harryGentleResourceCentreSectionContent?.title
            ? harryGentleResourceCentreSectionContent?.title
            : defaultHarryGentleResourceCentreTitle,
        });
    }
  }, [content, findSectionContent]);

  if (displayPendingMessage === undefined && auth.isAuthenticated) {
    return <Spinner />;
  }

  return (
    <>
      <JumbotronPanel />
      <SearchButtonPanel/>
      {window?.__RUNTIME_CONFIG__?.display_overview_section && <Overview overviewContent={overviewContent} />}
      {window?.__RUNTIME_CONFIG__?.display_how_to_use_dictionary_section && (
        <HowToUseDictionary howToUseDictionarySectionContent={howToUseDictionaryContent} />
      )}

      {window?.__RUNTIME_CONFIG__?.display_how_to_become_contributor_section && (
        <HowToBecomeContributor howToBecomeContributorSectionContent={howToBecomeContributorContent} />
      )}

      {window?.__RUNTIME_CONFIG__?.display_harry_gentle_resource_centre_info_section && (
        <HarryGentleResourceCentreInfo harryGentleResourceCentreSectionContent={harryGentleResourceCentreContent} />
      )}
      {displayPendingMessage && auth.isAuthenticated && (
        <nav id="sign-in-alert" className="fixed-top nav-z-index mt-5 pt-5">
          <Container>
            <Alert
              variant="secondary"
              onClose={() => {
                setIsApprovedUser();
                setDisplayPendingMessage(false);
              }}
              dismissible
            >
              {" "}
              <div>
                You’re logged in but don’t currently have permission to add a new entry. Contact
                <b>
                  <a href="mailto:harrygentle@griffith.edu.au" className="text-black"> harrygentle@griffith.edu.au</a>
                </b>{" "}
                to become a contributor.
              </div>
            </Alert>
          </Container>
        </nav>
      )}
    </>
  );
}

export default HomePage;
