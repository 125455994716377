import { Col } from "react-bootstrap";

export const ArrayFieldDeleteButton = ({ onClick, dataTestId }) => {
  return (
    <Col md="auto" className="p-0 mx-1">
      <button
        id={dataTestId}
        type="button"
        onClick={() => onClick()}
        className="btn btn-secondary"
        data-testid={dataTestId}
      >
        Delete
      </button>
    </Col>
  );
};
