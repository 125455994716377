import { Container, Spinner } from "react-bootstrap";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import "../css/markdown-styles.css";

export const HowToBecomeContributor = (content) => {
  return (
    <section className="p-5 how-to-become-contributor text-white">
      <Container style={{ minHeight: "5vh " }}>
        {!content.howToBecomeContributorSectionContent && (
          <Spinner animation="border" variant="light" className="align-center" />
        )}
        {content.howToBecomeContributorSectionContent && (
          <>
            {" "}
            <h5 className="display-5 home-page-headings text-center">
              {content.howToBecomeContributorSectionContent.title}
            </h5>
              {" "}
              <ReactMarkdown
                className="reactMarkDownHomePage"
                remarkPlugins={[remarkGfm]}
                skipHtml={true}
                linkTarget="_blank"
              >
                {content.howToBecomeContributorSectionContent.content}
              </ReactMarkdown>
          </>
        )}
      </Container>
    </section>
  );
};
