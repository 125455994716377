import { Row, Form, FormControl, Nav, ListGroup, Button, ButtonGroup, Col, Alert } from "react-bootstrap";
import PageLayout from "../components/PageLayout";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Spinner } from "../components/Spinner";
import ConfirmationModal from "../components/BiographyEntryForm/confirmationModal";
import { placeAPI } from "../api/placeAPI";
import CreatePlace from "../components/BiographyEntryForm/ArrayFieldComponents/CreatePlace";
import axios from "axios";
import { displayToast } from "../components/DisplayToast";
import { getIdFromUrl } from "../components/BiographyEntryForm/utils/utils";
import { deletePlaceWarningMessage } from "./AdministrativeHelpPage";

function Places() {
  const [pageNumber, setPageNumber] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [showAddModal, setShowAddModal] = useState(false);
  const [formData, setFormData] = useState(null);
  const [places, setPlaces] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [hasDeletePermission, setHasDeletePermission] = useState(true);

  useEffect(() => {
    placeAPI
      .getPlaceList()
      .then((response) => {
        setPlaces(response?.data);
      })
      .catch((error) => {
        toast.error(`${error}`);
      });
  }, []);

  useEffect(() => {
    const params = new URLSearchParams();
    let isMounted = true;
    if (searchTerm !== "" && isMounted) {
      params.append("title__icontains", searchTerm);
    }
    if (pageNumber > 1 && isMounted) {
      params.append("page", `${pageNumber}`);
    }
    if (isMounted) {
      axios
        .get(`/api/v1/places`, { params })
        .then((response) => {
          setPlaces(response?.data);
        })
        .catch((error) => {
          toast.error(`${error}`);
        });
    }
    return () => (isMounted = false);
  }, [pageNumber, searchTerm, showAddModal, showDeleteModal]);

  const onDeletePlace = () => {
    const toastId = toast.loading("Please wait...");
    placeAPI
      .delete(getIdFromUrl(formData?.url))
      .then(() => {
        setShowDeleteModal(false);
        setFormData(null);
        displayToast(toastId, "success", 1500, "Place Entry has been deleted successfully.");
      })
      .catch((e) => {
        setShowDeleteModal(false);
        setFormData(null);
        displayToast(toastId, "error", false, "Error! Something went wrong.", e);
      });
  };

  return (
    <>
      <PageLayout title={"Places"} parents={[{ to: "/admin", title: "Admin" }]} showHeadingAndBreadcrumb={true}>
        <>
          <ConfirmationModal
            show={showAddModal}
            scrollable={true}
            modalBody={
              <CreatePlace
                saveAndCloseButtonText={"Save & Close"}
                setResponse={() => setFormData(null)}
                onClose={() => {
                  setFormData(null);
                  setShowAddModal(false);
                }}
                formData={formData}
              />
            }
            onModalClose={null}
            onConfirmed={null}
            modalClassName={"add-stub-entry-modal-dialog"}
          />

          <ConfirmationModal
            cancelButtonText={"Cancel"}
            confirmButtonText={hasDeletePermission ? "Delete" : null}
            modalTitle={"Delete Place Entry"}
            show={showDeleteModal}
            scrollable={true}
            modalBody={
              <>
                {" "}
                {hasDeletePermission && (
                  <div>
                   <p> Are you sure you want to delete <b>{formData?.title}</b> Place Entry?</p>
                    {deletePlaceWarningMessage}
                  </div>
                )}
                {!hasDeletePermission && <div>You do not have permission to delete any place entries.</div>}
              </>
            }
            onModalClose={() => {
              setFormData(null);
              setShowDeleteModal(false);
            }}
            onConfirmed={() => onDeletePlace()}
            modalClassName={"add-stub-entry-modal-dialog"}
          />

          <Row>
            <Form className="d-flex justify-content-end align-self-center">
              <Row className="d-flex">
                <Col xl={4} lg={4} md={4} sm={12} className="mt-2 p-0">
                  <button
                    type="button"
                    className="btn btn-primary glossary-and-place-add-button"
                    onClick={() => {
                      setFormData(null);
                      setShowAddModal(true);
                    }}
                  >
                    + Add Place
                  </button>
                </Col>
                <Col className="mt-2">
                  {" "}
                  <FormControl
                    type="search"
                    placeholder="Search"
                    aria-label="Search"
                    value={searchTerm}
                    onChange={(e) => {
                      setSearchTerm(e.target.value);
                      setPageNumber(1);
                    }}
                  />
                </Col>
              </Row>
            </Form>
          </Row>
          <Row className="mt-2">
            <ListGroup as="ol">
              {places !== null ? (
                places?.results?.map((row, index) => {
                  return (
                    <ListGroup.Item key={index} as="li" className="d-flex justify-content-between align-items-start">
                      <div className="ms-2 me-auto">
                        <div className="fw-bold">
                          {row.title} - {row.place_type}
                        </div>
                        <div>{row.located_in_str}</div>
                        <div>
                          <small>{row.description}</small>
                        </div>
                      </div>
                      <>
                        <div className="glossary-and-place-action-buttons">
                          <button
                            type="button"
                            className="btn"
                            onClick={() => {
                              setFormData(row);
                              setShowAddModal(true);
                            }}
                          >
                            <i className="bi bi-pencil-square"></i>
                          </button>
                        </div>

                        <div className="glossary-and-place-action-buttons">
                          <button
                            type="button"
                            className="btn"
                            onClick={() => {
                              const id = getIdFromUrl(row.url);
                              setHasDeletePermission(false);
                              placeAPI.getPlaceOptions(id).then((response) => {
                                const permission =
                                (response?.data?.delete_action?.hasOwnProperty("DELETE")) ?? false;
                                setHasDeletePermission(permission === true ? true : false);
                                setFormData(row);
                                setShowDeleteModal(true);
                              });
                            }}
                          >
                            <i className="bi bi-trash3"></i>
                          </button>
                        </div>
                      </>
                    </ListGroup.Item>
                  );
                })
              ) : (
                <div className="d-flex justify-content-center">
                  <Spinner />
                </div>
              )}
            </ListGroup>
          </Row>
          {places?.results?.length > 0 && (
            <Row>
              <nav className="navbar navbar-light justify-content-between" style={{ boxShadow: "0 0 0" }}>
                <Nav.Item>
                  Showing Entry {(pageNumber - 1) * 10 + 1} to {(pageNumber - 1) * 10 + 10} of {places?.count}
                </Nav.Item>
                <Nav.Item>
                  <ButtonGroup aria-label="Basic example">
                    <Button
                      variant="primary"
                      disabled={places?.previous === null}
                      onClick={() => setPageNumber(pageNumber - 1)}
                    >
                      Previous
                    </Button>
                    <Button
                      variant="primary"
                      disabled={places?.next === null}
                      onClick={() => setPageNumber(pageNumber + 1)}
                    >
                      Next
                    </Button>
                  </ButtonGroup>
                </Nav.Item>
              </nav>
            </Row>
          )}
          {places?.results?.length === 0 && (
            <Row>
              <Col>
                {searchTerm && (
                  <Alert className="info-message">
                    There are no results for <b>{searchTerm}</b>.
                  </Alert>
                )}
              </Col>
            </Row>
          )}
        </>
      </PageLayout>
    </>
  );
}

export default Places;
