import { Container, Spinner } from "react-bootstrap";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import "../css/markdown-styles.css";

export const Overview = (overviewContent) => {
  return (
    <section className="p-5 dictionary-details text-black">
      <Container style={{ minHeight: "15vh" }}>
        {!overviewContent && <Spinner animation="border" variant="light" className="align-center" />}
        {overviewContent && (
          <>
            {" "}
            <h5 className="display-5 home-page-headings text-center">{overviewContent.overviewContent.title}</h5>
            <ReactMarkdown
              className="reactMarkDownHomePage"
              remarkPlugins={[remarkGfm]}
              skipHtml={true}
              linkTarget="_blank"
            >
              {overviewContent.overviewContent.content}
            </ReactMarkdown>
          </>
        )}
      </Container>
    </section>
  );
};
