import React, { StrictMode } from "react";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AuthProvider } from "react-oidc-context";
import { WebStorageStateStore } from "oidc-client-ts";
import { createRoot } from "react-dom/client";

const oidcConfig = {
  authority: window?.__RUNTIME_CONFIG__?.authority,
  client_id: window?.__RUNTIME_CONFIG__?.client_id,
  redirect_uri: window?.__RUNTIME_CONFIG__?.redirect_uri,
  loadUserInfo: true,
  stateStore: new WebStorageStateStore({ store: window.localStorage }),
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  automaticSilentRenew: false,
  accessTokenExpiringNotificationTimeInSeconds: 60,
};

const appTitles = {
  title: window.__RUNTIME_CONFIG__?.app_title,
  sub_title: window.__RUNTIME_CONFIG__?.app_sub_title,
};

const displaySections = {
  display_overview_section: window.__RUNTIME_CONFIG__?.display_overview_section,
};

console.log(appTitles);
console.log(oidcConfig);
console.log(displaySections);

const container = document.getElementById("root")
const root = createRoot(container);
root.render(
    <AuthProvider {...oidcConfig}>
      <StrictMode>
        <App />
      </StrictMode>
    </AuthProvider>
  
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
