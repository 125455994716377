import GetPlaces from "./getPlaces";

export const getIdFromUrl = (url) => {
  if (!url) return null;
  const parts = url.split("/");
  return parts[parts.length - 1];
};

export const getGenderName = (value) => {
  switch (value) {
    case "M":
      return "Male";
    case "F":
      return "Female";
    case "U":
      return "Unknown";
    default:
      return "";
  }
};

export const biographyState = {
  DRAFT: "draft", // Newly created until ready to be published
  OUT_OF_SCOPE: "out_of_scope", // Not included in the research
  PENDING_REVIEW: "pending_review", // Awaiting Admin Approval
  PUBLISHED: "published", // Visible on the website
  ARCHIVED: "archived", // Soft delete state
};

export const actions = {
  requestReview: "request-review",
  publish: "publish",
  reject: "reject",
  archive: "archive",
  outOfScope: "out-of-scope",
  draft: "draft",
  remove: "remove",
};

export const getStateName = (value) => {
  switch (value) {
    case "draft":
      return "Draft";
    case "out_of_scope":
      return "Out of Scope";
    case "pending_review":
      return "Pending Review";
    case "published":
      return "Published";
    case "archived":
      return "Archived";
    default:
      return "";
  }
};

export const getPlaceNameFromUrl = (url) => {
  return GetPlaces()?.data.results.find((result) => result?.url === url);
};

export const getBadgeClassName = (state) => {
  if (state === biographyState.DRAFT) return "dark";
  else if (state === biographyState.PENDING_REVIEW) return "info";
  else if (state === biographyState.PUBLISHED) return "success";
  else return "primary";
};

const validDates = [
  "~ 08.02.1981",
  "ca 08.02.1981",
  "approx. 08.02.1981",
  "ca. 08.02.1981",
  "08.02.1981~",

  "~08.02.1981",
  "08.02.1981",
  "~ 02.1981",
  "ca 02.1981",
  "02-1981",
  "~ 1981-02-08",
  "ca 1981-02",

  "1981-02-08~",
  "1981-02~",
  "1981-08-02",
  "~ 1981",
  "1981",
  "~1981",
  "1981?",
  "approx 1981 (uncertain)",

  "approximately 1981 (uncertain)",
  "~ January 1981",
  " dec 1981",
  "8 February 1981",
  "8th February 1981",

  "2nd February 1981",
  "circa 2nd February 1981",
  "~ January 1981?",
  " dec 1981 (uncertain)",

  "8 February 1981 (uncertain)",
  "8th February 1981 uncertain",
  "2nd February 1981?",
  "~ 1981-02-uu",

  "ca 1981-uu",
  "1981-02-uu~",
  "1981-02-XX~",
  "1981-uu~",
  "1981-XX-XX",
  "1981-uu~?",
  "1880s",
  "1880s?",
  "ca. 1880s",
];

// Returns a list of dates
export const getValidDates = () => {
  return validDates.map((date, index) => {
    return <li key={index}>{date}</li>;
  });
};

export const customSelectStyles = {
  control: (styles) => ({
    ...styles,
    borderRadius: 0,
    height: 40,
  }),
};

export const getLastYearMonths = (date) => {
  const lastMonths = [],
  monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  let month = date.getMonth();
  let count = 0;
  while (count < 12) {
    count++;
    month = month >= 11 ? 0 : month+1;
    lastMonths.push(monthNames[month]);
  }
  return lastMonths;
}