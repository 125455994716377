import React, { useContext, useEffect, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { BiographyStore } from "../../../stateManager/stores/BiographyStore";
import { updateFormData } from "../../../stateManager/actions/BiographyActions";
import NoDataProvidedMessage from "./NoDataProvidedMessage";
import { ArrayFieldAddButton } from "./ArrayFieldAddButton";
import { ArrayFieldUndoButton } from "./ArrayFieldUndoButton";
import { ArrayFieldDeleteButton } from "./ArrayFieldDeleteButton";
import { ArrayFieldMoveUpDownButtons } from "./ArrayFieldMoveUpDownButtons";
import UncertainDateController from "../FormComponents/UncertainDateController";

function NewspapersArray({ classNames, onShowDatesFormatLinkClicked }) {
  const {
    control,
    getValues,
    reset,
    register,
    formState: { errors },
  } = useFormContext();

  const { fields, append, remove, swap } = useFieldArray({
    control,
    name: "newspapers",
  });

  const [displayUndoButton, setDisplayUndoButton] = useState(false);
  const { state, dispatch } = useContext(BiographyStore);
  const newspapersValues = getValues("newspapers");

  useEffect(() => {
    if (JSON.stringify(newspapersValues) === JSON.stringify(state.formData.newspapers)) {
      setDisplayUndoButton(false);
    } else {
      setDisplayUndoButton(true);
    }
  }, [newspapersValues, state]);

  if (state?.formData.newspapers?.length === 0 && state?.isReadOnly) {
    return <NoDataProvidedMessage classNames={classNames} />;
  }

  return (
    <div className={"col-12 " + classNames}>
      {fields.map((item, index) => {
        const itemNum = index + 1;

        return (
          <div className="row g-2 field-array-row" key={item.id}>
            <div className="col-md-9 field-column">
              <div className="sub-field-array-style row g-2" id={item.id}>
                <p className="subTitle">{`Newspaper ` + itemNum}</p>
                <div className="col-6" id={`newspapers[${index}].newspaper_title-div`}>
                  <input
                    id={`newspapers[${index}].newspaper_title`}
                    name={`newspapers[${index}].newspaper_title`}
                    {...register(`newspapers[${index}].newspaper_title`)}
                    placeholder="Newspaper title*"
                    className="form-control"
                    readOnly={state.isReadOnly}
                  />
                  {errors?.newspapers?.[index]?.newspaper_title && (
                    <span className="text-danger" data-testid="error-message">
                      {errors?.newspapers[index].newspaper_title.message}
                    </span>
                  )}
                </div>
                <div className="col-6" id={`newspapers[${index}].article_title-div`}>
                  <input
                    id={`newspapers[${index}].article_title`}
                    name={`newspapers[${index}].article_title`}
                    {...register(`newspapers[${index}].article_title`)}
                    placeholder="Article title"
                    className="form-control"
                    readOnly={state.isReadOnly}
                  />
                </div>
                <div className="col-6">
                  <UncertainDateController
                    name={`newspapers[${index}].date`}
                    error={errors?.newspapers?.[index]?.date?.message}
                    placeholder="Date"
                    displayDatesInfoLink={true}
                    onShowDatesFormatLinkClicked={onShowDatesFormatLinkClicked}
                  />
                </div>
                <div className="col-6" id={`newspapers[${index}].page_number-div`}>
                  <input
                    type="number"
                    step="1"
                    id={`newspapers[${index}].page_number`}
                    name={`newspapers[${index}].page_number`}
                    {...register(`newspapers[${index}].page_number`)}
                    placeholder="Page number"
                    className="form-control"
                    readOnly={state.isReadOnly}
                  />
                </div>
                <div className="col-12" id={`newspapers[${index}].url-div`}>
                  <input
                    id={`newspapers[${index}].url`}
                    name={`newspapers[${index}].url`}
                    {...register(`newspapers[${index}].url`)}
                    placeholder="Url"
                    className="form-control"
                    readOnly={state.isReadOnly}
                  />
                </div>
              </div>
            </div>
            {!state?.isReadOnly && (
              <div className="col-md-3">
                <div className="row flex-row p-0">
                  <ArrayFieldDeleteButton
                    dataTestId={`newspapers-delete-button-${index}`}
                    onClick={() => {
                      updateFormData(getValues(), dispatch);
                      remove(index);
                    }}
                  />
                  <ArrayFieldMoveUpDownButtons
                    onDownButtonClick={() => swap(index, index + 1)}
                    onUpButtonClick={() => swap(index, index - 1)}
                    index={index}
                    fields={fields}
                    upButtonDataTestId={`newspapers-${index}-up`}
                    downButtonDataTestId={`newspapers-${index}-down`}
                  />
                </div>
              </div>
            )}
          </div>
        );
      })}

      {!state?.isReadOnly && (
        <ArrayFieldAddButton
          dataTestId={"newspapers-add-button"}
          onClick={() => {
            append({ newspaper_title: "", article_title: "", date: "", page_number: null, url: "" });
            updateFormData(getValues(), dispatch);
          }}
        />
      )}
      {displayUndoButton && (
        <ArrayFieldUndoButton
          dataTestId={"newspapers-undo-button"}
          getValues={getValues}
          append={append}
          reset={reset}
        />
      )}
    </div>
  );
}

export default NewspapersArray;
