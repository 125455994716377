import { Container } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useEffect } from "react";
import { glossaryAPI } from "../api/glossaryAPI";
import { toast } from "react-toastify";
import { LabelAndInput } from "../components/BiographyEntryForm/FormComponents/LabelAndInput";
import { useParams } from "react-router";
import { displayToast } from "../components/DisplayToast";

const AddGlossary = ({ onClose, formData }) => {
  const { register, handleSubmit, setValue, getValues, setError, reset } =
    useForm();
  let { slug } = useParams();

  // Setting the form values when glossary row has been selected and there is formData
  useEffect(() => {
    if (formData) {
      setValue("name", formData?.name);
      setValue("description", formData?.description);
    }
  }, [formData, setValue]);

  const createGlossaryItem = (data) => {
    const toastId = toast.loading("Please wait...");
    return glossaryAPI
      .createGlossaryEntry(slug, data)
      .then(() => {
        reset({ name: "", description: "" });
        displayToast(
          toastId,
          "success",
          1500,
          "Glossary Entry has been saved successfully."
        );
      })
      .catch(printAndDisplayErrorToast(toastId));
  };

  const updateGlossaryItem = (data) => {
    const toastId = toast.loading("Please wait...");
    glossaryAPI
      .updateGlossaryEntry(formData.id, slug, data)
      .then(() => {
        onClose();
        displayToast(
          toastId,
          "success",
          1500,
          "Glossary Entry has been updated successfully."
        );
      })
      .catch(printAndDisplayErrorToast(toastId));
  };

  const printAndDisplayErrorToast = (toastId) => {
    return (e) => {
      displayToast(
        toastId,
        "error",
        false,
        "Error! Something went wrong.",
        e
      );
    };
  }
  

  const onSubmit = (data) => {
    formData ? updateGlossaryItem(data) : createGlossaryItem(data);
  };

  const onSaveAndSelect = () => {
    const data = getValues();
    if (!data.name) {
      setError("name", { type: "focus" }, { shouldFocus: true });
    } else {
      createGlossaryItem(data).then(() => {
        onClose();
      });
    }
  };

  return (
    <Container>
      <div className="col-auto">
        <div className="row mb-3 mt-3">
          <div className="col-md-5">
            {!formData && <h3>Add {slug.charAt(0).toUpperCase() + slug.slice(1).toLowerCase()} Glossary Entry</h3>}
            {formData && <h3>Edit {slug.charAt(0).toUpperCase() + slug.slice(1).toLowerCase()} Glossary Entry</h3>}
          </div>
          <div className="col-md-7 float-end text-end">
            <button
              type="button"
              onClick={onClose}
              className="btn btn-secondary mx-2"
            >
              Cancel
            </button>
            {formData && (
              <button
                type="button"
                onClick={handleSubmit(onSubmit)}
                className="btn btn-primary mx-2"
              >
                {"Update"}
              </button>
            )}
            {!formData && (
              <>
                <button
                  type="button"
                  onClick={() => onSaveAndSelect()}
                  className="btn btn-primary mx-2"
                >
                  {"Save & Close"}
                </button>
                <button
                  type="button"
                  onClick={handleSubmit(onSubmit)}
                  className="btn btn-primary mx-2"
                >
                  {"Save & Add Another"}
                </button>
              </>
            )}
          </div>
        </div>
        <hr />

        <div className="col-md-12 field-array-style mb-4 mt-4">
          <div className="row">
            <LabelAndInput
              labelText="Name"
              classNames={"col-md-6"}
              placeholder={"Enter name"}
              labelClassName={"form-label required"}
              registerText="name"
              required={true}
              register={register}
            />
          </div>
          <div className="row mt-3">
            <div>
              <label htmlFor={""}>Description:</label>
              <textarea
                aria-label={""}
                className="form-control"
                placeholder={"Add description"}
                {...register("description")}
                type={"text"}
              />
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default AddGlossary;

