import axios from "axios";
import { getParamsConfig } from "./searchAPI";
const baseURL = `/api/v1/persons`;

const headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
};


// All public requests
const getAll = () => {
  return axios.get(baseURL);
};

// All private requests
const getPersonOptions = (id) => {
  return axios.options(`${baseURL}/${id}`);
};

const getById = (id) => {
  return axios.get(`${baseURL}/${id}`);
};

const create = (data) => {
  return axios.post(baseURL, data, headers);
};

const update = (id, params) => {
  return axios.put(`${baseURL}/${id}`, params, headers);
};

// prefixed with underscored because delete is a reserved word in javascript
const _delete = (id) => {
  return axios.delete(`${baseURL}/${id}`, headers);
};

const performWorkflowAction = (action, id, params) => {
  switch (action) {
    case "SUBMIT_FOR_REVIEW":
      return axios.post(`${baseURL}/${id}/request-review`, params, headers);
    case "PUBLISH":
      return axios.post(`${baseURL}/${id}/publish`, params, headers);
    case "REJECT":
      return axios.post(`${baseURL}/${id}/reject`, params, headers);
    case "ARCHIVE":
      return axios.post(`${baseURL}/${id}/archive`, params, headers);
    case "OUT_OF_SCOPE":
      return axios.post(`${baseURL}/${id}/out-of-scope`, params, headers);
    case "REMOVE":
      return axios.post(`${baseURL}/${id}/remove`, params, headers);
    case "DRAFT":
      return axios.post(`${baseURL}/${id}/draft`, params, headers);

    default:
      return null;
  }
};

const getPossibleTransitions = (id) => {
  return axios.get(`${baseURL}/${id}/possible-transitions`);
};

const exportResultsInCSV = (parameters, currentPage, bioState) => {
  const paramsConfig = getParamsConfig(parameters, currentPage, bioState).params;
  return axios.get(`${baseURL}/export_csv`, {
    params: paramsConfig,
    headers: headers,
  });
};

const getPersonCount = () => {
  return axios.get(`${baseURL}/count`)
}

export const personsAPI = {
  getAll,
  getById,
  create,
  update,
  delete: _delete,
  getPersonOptions,
  getPossibleTransitions,
  performWorkflowAction,
  getPersonCount,
  exportResultsInCSV,
};
