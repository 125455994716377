import { ListGroup } from "react-bootstrap";
import PageLayout from "../components/PageLayout";
import { glossaryAPI } from "../api/glossaryAPI";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Spinner } from "../components/Spinner";

const GlossaryRow = (props) => {
  const navigate = useNavigate();
  const [count, setCount] = useState(0);
  const row = props.row;

  useEffect(() => {
    axios
      .get(row.entries)
      .then((result) => {
        setCount(result.data.count);
      })
      .catch((error) => {
        toast.error(`${error}`);
      });
  }, [setCount, row]);

  return (
    <ListGroup.Item
      action
      variant="light"
      className="d-flex justify-content-between align-items-start"
      onClick={() => {
        navigate(`/glossaries/${row.slug}`);
      }}
    >
      <div className="ms-2 me-auto">
        <div className="fw-bold">{row.display_name}</div>
        {row.description}
      </div>
      <span className="badge bg-primary rounded-pill">{count}</span>
    </ListGroup.Item>
  );
};

function Glossaries() {
  const [glossaries, setGlossaries] = useState(null);

  useEffect(() => {
    console.log("fetching list of all Glossaries");
    glossaryAPI
      .getGlossaryList()
      .then((result) => {
        setGlossaries(result);
        console.log("Done fetching all Glossaries");
      })
      .catch((error) => {
        toast.error(`${error}`);
      });
  }, [setGlossaries]);

  return (
    <>
      <PageLayout
        title={"Glossaries"}
        parents={[{ to: "/admin", title: "Admin" }]}
        showHeadingAndBreadcrumb={true}
      >
        <div className="row">
          <ol className="list-group list-group-numbered p-0">
            {glossaries !== null ? (
              glossaries.map((row) => {
                return <GlossaryRow row={row} key={row.name} />;
              })
            ) : (
              <div className="d-flex justify-content-center">
                <Spinner />
              </div>
            )}
          </ol>
        </div>
      </PageLayout>
    </>
  );
}

export default Glossaries;
