import React, { useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "../css/SearchButtonPanel.css";
import { LinkContainer } from "react-router-bootstrap"; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons'; 

function SearchButtonPanel() {
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [surname, setSurname] = useState("");

  const navigate = useNavigate();

  const handleSearch = () => {
    navigate(
      `/search?first_name=${encodeURIComponent(firstName)}&middle_name=${encodeURIComponent(
        middleName
      )}&surname=${encodeURIComponent(surname)}`
    );
  };

  return (
    <section className="p-2 search-button-panel text-white">
      <Container>
       
              <Row className="flex-row justify-content-center py-3">
            
                <Col className="text-center">
                  <LinkContainer style={{color: "#32241c", borderRadius: "5px", border:"0px"}} to="/search">
                    <Button
                      size="lg"
                      variant="beige"
                      style={{margin: "0px auto"}}
                      className="shadow-lg px-3 py-2 text-uppercase border-0"
                      target="_blank"
                      href="https://harrygentle.griffith.edu.au"
                    >
                      <FontAwesomeIcon icon={faSearch} className="me-2" />
                    Search the Dictionary
                    </Button>
                  </LinkContainer>
                </Col>
          
              </Row>
        
      </Container>
    </section>
  );
}

export default SearchButtonPanel;
