import React, { createContext, useReducer } from "react";
import { initialFormData } from "../../components/BiographyEntryForm/FormComponents/initialFormData";
import { BiographyReducer } from "../reducers";

export const initialState = {
  isLoading: false,
  isReadOnly: false,
  hasAnyAPIErrors: false,
  APIErrorMessages: null,
  APIErrorResponseStatus: null,
  id: null,
  formData: initialFormData,
  initialFormData: initialFormData,
  hasEditPermission: false,
  hasDeletePermission: false,
  possibleTransitions: [],
};

export const BiographyStore = createContext(initialState);

export const BiographyStoreProvider = ({ children }) => {
  const [state, dispatch] = useReducer(BiographyReducer, initialState);

  return (
    <BiographyStore.Provider value={{ state, dispatch }}>
      {children}
    </BiographyStore.Provider>
  );
};
