export const defaultTestOptions = [
  { value: "", label: "Select Option" },
  { value: "1", label: "option 1" },
  { value: "2", label: "option 2" },
  { value: "3", label: "option 3" },
];

export const getOnlineResourceTypeOptions = [
  { value: "AJCP record", label: "AJCP record" },
  { value: "BDM record", label: "BDM record" },
  { value: "Biography", label: "Biography" },
  { value: "Convict record", label: "Convict record" },
  { value: "Court record", label: "Court record" },
  { value: "Genealogy website", label: "Genealogy website" },
  { value: "Government Gazette", label: "Government Gazette" },
  { value: "Grave/Cemetery record", label: "Grave/Cemetery record" },
  { value: "Image/Artwork", label: "Image/Artwork" },
  { value: "Land record", label: "Land record" },
  { value: "Map", label: "Map" },
  { value: "Memorial", label: "Memorial" },
  { value: "Newspaper article", label: "Newspaper article" },
  { value: "Obituary", label: "Obituary" },
  {
    value: "Parliament/Government records",
    label: "Parliament/Government records",
  },
  { value: "Police Gazette", label: "Police Gazette" },
  { value: "Property description", label: "Property description" },
  { value: "Property history", label: "Property history" },
  { value: "Prosecution Project/Trial", label: "Prosecution Project/Trial" },
  { value: "Publication", label: "Publication" },
  { value: "SLQ record", label: "SLQ record" },
];

export const genderOptions = [
  { value: "", label: "Select Gender" },
  { value: "M", label: "Male" },
  { value: "F", label: "Female" },
  { value: "U", label: "Unknown" },
];

const stateOptions = [
  { value: "publish", label: "Publish" },
  { value: "out_of_scope", label: "Out of Scope" },
  { value: "draft", label: "Draft" },
  { value: "pending_review", label: "Pending review" },
  { value: "archived", label: "Archived" },
];

export const getIndigenousStatusOptions = [
  { value: "", label: "Select Option" },
  { value: "true", label: "Yes" },
  { value: "false", label: "No" },
];

export const getPlaceTypes = [
  { value: "at_sea", label: "At Sea" },
  { value: "borough", label: "Borough" },
  { value: "city", label: "City" },
  { value: "community", label: "Community" },
  { value: "country", label: "Country" },
  { value: "county", label: "County" },
  { value: "county_town", label: "County Town" },
  { value: "district", label: "District" },
  { value: "division", label: "Division" },
  { value: "hamlet", label: "Hamlet" },
  { value: "island", label: "Island" },
  { value: "kingdom", label: "Kingdom" },
  { value: "other", label: "Other" },
  { value: "parish", label: "Parish" },
  { value: "penal_station", label: "Penal Station" },
  { value: "principal_area", label: "Principal Area" },
  { value: "property", label: "Property" },
  { value: "protectorate", label: "Protectorate" },
  { value: "province", label: "Province" },
  { value: "region", label: "Region" },
  { value: "state", label: "State" },
  { value: "station", label: "Station" },
  { value: "settlement", label: "Settlement" },
  { value: "shire", label: "Shire" },
  { value: "suburb", label: "Suburb" },
  { value: "territory", label: "Territory" },
  { value: "town", label: "Town" },
];

const rangeOfYears = (start, end) =>
  Array(end - start + 1)
    .fill(start)
    .map((year, index) => year + index);

const getYears = () => {
  const years = rangeOfYears(1800, 2000);
  return years.map((year) => {
    return mapOptions(year);
  });
};

const mapOptions = (year) => {
  const value = year;
  const label = year;
  return { value, label };
};

export const getOptions = (name) => {
  switch (name) {
    case "from_birth_year":
      return getYears();
    case "to_birth_year":
      return getYears();
    case "from_death_year":
      return getYears();
    case "to_death_year":
      return getYears();
    case "from_year_arrived_australia":
      return getYears();
    case "to_year_arrived_australia":
      return getYears();
    case "from_year_arrived_qld":
      return getYears();
    case "to_year_arrived_qld":
      return getYears();
    case "gender":
      return genderOptions;
    case "indigenous_status":
      return getIndigenousStatusOptions;
    case "onlineResourceTypes":
      return getOnlineResourceTypeOptions;
    case "biographyStates":
      return stateOptions;
    case "places":
      return getPlaceTypes;

    default:
      return defaultTestOptions;
  }
};
