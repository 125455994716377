import React, { useCallback, useRef, useEffect, useState } from "react";
import PaginationBar from "../components/PaginationBar";
import { useQueryParam, NumberParam, StringParam, useQueryParams, ArrayParam } from "use-query-params";
import { searchAPI } from "../api/searchAPI";
import { biographyState, customSelectStyles, getIdFromUrl } from "../components/BiographyEntryForm/utils/utils";
import PageLayout from "../components/PageLayout";
import { Spinner } from "../components/Spinner";
import BiographyTable, { columnIds, columns, hiddenColIds } from "../components/BiographyTable";
import { Container, Row, Col, Form, Dropdown, Button, Collapse, Alert, Spinner as ReactSpinner } from "react-bootstrap";
import { getOptions } from "../components/BiographyEntryForm/FormComponents/formSelectOptions";
import Select from "react-select";
import SearchAndAsyncSelect from "../components/BiographyEntryForm/FormComponents/SearchAndAsyncSelect";
import { LabelAndGlossarySearch } from "../components/BiographyEntryForm/FormComponents/LabelAndGlossarySearch";
import LabelAndSelect from "../components/BiographyEntryForm/FormComponents/LabelAndSelect";
import { useForm } from "react-hook-form";
import AppNavbar from "../components/AppNavbar";
import { personsAPI } from "../api/personsAPI";
import { displayToast } from "../components/DisplayToast";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

// This search component is mostly used for all search pages.
// For example, public search page, admin pages, etc..

export const defaultSearchFieldIds = {
  gender: "gender",
  placeOfBirth: "birth_place",
  placeOfDeath: "death_place",
  placeOfBurial: "burial_place",
  residencePlaceOrPropertyOwnedPlace: "place_of_residence_or_property_owned",
  residencePlaceOrPropertyOwnedName: "residence_or_property_owned_name",
  birthYear: "from_birth_year",
  deathYear: "from_death_year",
  occupation: "occupation_name",
  religion: "religion",
  culturalHeritage: "cultural_heritage",
  indigenousStatus: "indigenous_status",
  yearArrivedAustralia: "from_year_arrived_australia",
  yearArrivedQld: "from_year_arrived_qld",
  shipArrivedInAustralia: "ship_arrived_in_australia",
  shipArrivedInQueensland: "ship_arrived_in_qld",
  otherNames: "other_names",
};

const defaultSearchFields = [
  {
    fieldName: "Cultural Heritage",
    fieldId: defaultSearchFieldIds.culturalHeritage,
    isVisible: false,
  },
  {
    fieldName: "Gender",
    fieldId: defaultSearchFieldIds.gender,
    isVisible: false,
  },
  {
    fieldName: "Indigenous Status",
    fieldId: defaultSearchFieldIds.indigenousStatus,
    isVisible: false,
  },
  {
    fieldName: "Occupation",
    fieldId: defaultSearchFieldIds.occupation,
    isVisible: false,
  },
  {
    fieldName: "Other Names",
    fieldId: defaultSearchFieldIds.otherNames,
    isVisible: false,
  },
  {
    fieldName: "Place of Birth",
    fieldId: defaultSearchFieldIds.placeOfBirth,
    isVisible: false,
  },
  {
    fieldName: "Place of Burial",
    fieldId: defaultSearchFieldIds.placeOfBurial,
    isVisible: false,
  },
  {
    fieldName: "Place of Death",
    fieldId: defaultSearchFieldIds.placeOfDeath,
    isVisible: false,
  },
  {
    fieldName: "Religion",
    fieldId: defaultSearchFieldIds.religion,
    isVisible: false,
  },
  {
    fieldName: "Residence or Property owned location",
    fieldId: defaultSearchFieldIds.residencePlaceOrPropertyOwnedPlace,
    isVisible: false,
  },
  {
    fieldName: "Residence or Property owned name",
    fieldId: defaultSearchFieldIds.residencePlaceOrPropertyOwnedName,
    isVisible: false,
  },
  {
    fieldName: "Ship name arrived in Australia",
    fieldId: defaultSearchFieldIds.shipArrivedInAustralia,
    isVisible: false,
  },
  {
    fieldName: "Ship name arrived in Queensland",
    fieldId: defaultSearchFieldIds.shipArrivedInQueensland,
    isVisible: false,
  },
  {
    fieldName: "Year of Birth",
    fieldId: defaultSearchFieldIds.birthYear,
    isVisible: false,
  },
  {
    fieldName: "Year of Death",
    fieldId: defaultSearchFieldIds.deathYear,
    isVisible: false,
  },
  {
    fieldName: "Year arrived in Australia",
    fieldId: defaultSearchFieldIds.yearArrivedAustralia,
    isVisible: false,
  },
  {
    fieldName: "Year arrived in Queensland",
    fieldId: defaultSearchFieldIds.yearArrivedQld,
    isVisible: false,
  },
];

const defaultParameters = {
  first_name: "",
  middle_name: "",
  surname: "",
  religion: "",
  other_names: "",
  cultural_heritage: "",
  from_birth_year: "",
  to_birth_year: "",
  from_death_year: "",
  to_death_year: "",
  gender: "",
  occupation_name: "",
  birth_place: "",
  birth_place_id: "",
  death_place: "",
  death_place_id: "",
  burial_place: "",
  burial_place_id: "",
  place_of_residence_or_property_owned: "",
  place_of_residence_or_property_owned_id: "",
  residence_or_property_owned_name: "",
  indigenous_status: "",
  from_year_arrived_australia: "",
  to_year_arrived_australia: "",
  from_year_arrived_qld: "",
  to_year_arrived_qld: "",
  ship_arrived_in_australia: "",
  ship_arrived_in_qld: "",
  ordering: [],
};

const SearchPage = ({
  displayStubEntryFilterCheckbox,
  isSearchModal,
  onPersonSelected,
  showHeadingAndBreadcrumb,
  displayAdminBreadcrumb,
  hideActionColumn,
  hideStateColumn,
  pageTitle,
  displayEntryForState,
  alertMessage,
}) => {
  const {
    control,
    getValues,
    setValue,
    reset,
    register,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm(defaultParameters);
  const [selectedBirthPlace, setSelectedBirthPlace] = useState(null);
  const [selectedDeathPlace, setSelectedDeathPlace] = useState(null);
  const [selectedBurialPlace, setSelectedBurialPlace] = useState(null);
  const [selectedResidenceOrPropertyOwnedPlace, setSelectedResidenceOrPropertyOwnedPlace] = useState(null);
  const [advancedSearchFilters, setAdvancedSearchFilters] = useState(defaultSearchFields);
  const [open, setOpen] = useState(true);
  const selectInputRef = useRef();
  const options = getOptions("biographyStates");
  const isAnySearchFieldsAdded = advancedSearchFilters.filter((item) => item.isVisible === false)?.length === 0;
  const numberOfAddedFields = advancedSearchFilters.filter((item) => item.isVisible === true)?.length;

  const [nextUrl, setNextUrl] = useState();
  const [previousUrl, setPreviousUrl] = useState();
  const [itemCount, setItemCount] = useState(0);
  const [responseData, setResponseData] = useState(null);
  const [displayInfoMessage, setDisplayInfoMessage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [bioState, setBioState] = useState(displayEntryForState ?? biographyState.PUBLISHED);
  const [selectedState, setSelectedState] = useState("");
  const biographyTableRef = useRef();
  const [isCSVDataLoading, setIsCSVDataLoading] = useState(false);

  const [parameters, setParameters] = useQueryParams({
    first_name: StringParam,
    middle_name: StringParam,
    surname: StringParam,
    religion: StringParam,
    cultural_heritage: StringParam,
    from_birth_year: StringParam,
    to_birth_year: StringParam,
    from_death_year: StringParam,
    to_death_year: StringParam,
    gender: StringParam,
    other_names: StringParam,
    occupation_name: StringParam,
    birth_place: StringParam,
    birth_place_id: StringParam,
    death_place: StringParam,
    death_place_id: StringParam,
    burial_place: StringParam,
    burial_place_id: StringParam,
    place_of_residence_or_property_owned: StringParam,
    place_of_residence_or_property_owned_id: StringParam,
    residence_or_property_owned_name: StringParam,
    indigenous_status: StringParam,
    from_year_arrived_australia: StringParam,
    to_year_arrived_australia: StringParam,
    from_year_arrived_qld: StringParam,
    to_year_arrived_qld: StringParam,
    ship_arrived_in_australia: StringParam,
    ship_arrived_in_qld: StringParam,
    ordering: ArrayParam,
  });

  const [currentPage, setCurrentPage] = useQueryParam("page", NumberParam);

  const fetchData = useCallback(() => {
  setIsLoading(true);
  
  // Reset displayInfoMessage when a new search is triggered
  setDisplayInfoMessage(false);
  
  searchAPI
    .search({ currentPage, parameters, bioState })
    .then((response) => {
      setResponseData(response.data.results);
      
      // Display the "There are no matching results" message if no results are found
      setDisplayInfoMessage(response.data.results.length === 0)

      setNextUrl(response.data.next);
      setPreviousUrl(response.data.previous);
      setItemCount(response.data.count);
      setIsLoading(false);
    })
    .catch((error) => {
      console.log("Error:", error);
      setIsLoading(false);
    });
}, [currentPage, parameters, bioState]);


  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // Update search field visibility when selected from dropdown or removed from page
  const onFieldSelected = (field) => {
    const selectedField = advancedSearchFilters.find((item) => item.fieldName === field.fieldName);
    selectedField.isVisible = !selectedField.isVisible;
    setAdvancedSearchFilters([...advancedSearchFilters]);
  };

  // Returns field for given fieldId
  const getField = (fieldId) => {
    return advancedSearchFilters.find((field) => field.fieldId === fieldId);
  };

  // Remove field icon component
  const RemoveFieldIcon = ({ field, onRemove }) => {
    return (
      <Col className="cross-button" xl={1} lg={1} md={1} sm={12}>
        <i
          className="bi bi-x-circle"
          onClick={() => {
            onFieldSelected(field);
            onRemove();
          }}
        />
      </Col>
    );
  };

  // Display Validation Error Component
  const DisplayErrors = ({ error }) => {
    if (!error) return null;
    return <small className="text-danger">{error.message}</small>;
  };

  // pre-fill the search form from query parameters when page reloads or someone just copy and paste the link
  // Most of the fields would just work doing reset(parameters),
  // but place fields would needs to be pre-filled manually as we are using state variable to hold the selected value.
  useEffect(() => {
    setAdvancedFieldsVisibilityToFalse();
    reset(parameters);

    // Set visible true for the field if query parameter has value,
    // Key should be any of first_name, middle_name and surname as they are already visible and are not a part of advancedSearchFilters
    Object.keys(parameters).forEach((key) => {
      if (
        parameters[key] &&
        parameters[key] !== "" &&
        key !== "first_name" &&
        key !== "middle_name" &&
        key !== "surname"
      ) {
        const selectedField = advancedSearchFilters.find((item) => item.fieldId === key);
        if (selectedField) selectedField.isVisible = true;
        setAdvancedSearchFilters([...advancedSearchFilters]);
      }
    });

    // Now set places
    parameters.birth_place && setSelectedBirthPlace({ value: parameters.birth_place, label: parameters.birth_place });
    parameters.death_place && setSelectedDeathPlace({ value: parameters.death_place, label: parameters.death_place });
    parameters.burial_place &&
      setSelectedBurialPlace({ value: parameters.burial_place, label: parameters.burial_place });
    parameters.place_of_residence_or_property_owned &&
      setSelectedResidenceOrPropertyOwnedPlace({
        value: parameters.place_of_residence_or_property_owned,
        label: parameters.place_of_residence_or_property_owned,
      });
  }, []);

  // setting isVisible to false for advanced fields
  const setAdvancedFieldsVisibilityToFalse = () => {
    advancedSearchFilters.forEach((field) => {
      field.isVisible = false;
    });
    setAdvancedSearchFilters([...advancedSearchFilters]);
  };

  // Set visibility of all advanced search fields to false. And clear values of all fields.
  const onClearAllClicked = () => {
    // reset state field
    isSearchModal && setBioState("");
    setSelectedState("");

    setAdvancedFieldsVisibilityToFalse();
    // now clear all fields values to empty string
    setParameters(defaultParameters);

    // now reset search form too
    reset(defaultParameters);
    setSelectedBirthPlace(null);
    setSelectedDeathPlace(null);
    setSelectedBurialPlace(null);
    setSelectedResidenceOrPropertyOwnedPlace(null);

    // reset the collapse value if it's set to false
    !open && setOpen(true);
  };

  const CheckDropdownItem = React.forwardRef(({ children, id, checked, onChange }, ref) => {
    return (
      <Form.Group ref={ref} className="dropdown-item mb-0" controlId={id}>
        <Form.Check
          type="checkbox"
          label={children}
          defaultChecked={checked}
          onChange={onChange && onChange.bind(onChange, id)}
        />
      </Form.Group>
    );
  });

  const validate = ({ name, value }) => {
    const re = /^\d{4}$/;
    if (value && !value.match(re)) setError(name, { message: "Please enter 4 digit number!" });
    else clearErrors(name);
  };

  const exportResultsInCSV = async () => {
    setIsCSVDataLoading(true);
    const toastId = toast.loading("Please wait...");
    await personsAPI
      .exportResultsInCSV(parameters, currentPage, bioState)
      .then((response) => {
        const fileName = response.headers["content-disposition"].split("filename=")[1] ?? "dictionary-of-biography";
        const temp = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = temp;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        displayToast(toastId, "success", 1500, "Results have been downloaded successfully.");
        setIsCSVDataLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
        displayToast(toastId, "error", false, "Error! Something went wrong.", e);
        setIsCSVDataLoading(false);
      });
  };

  return (
    <>
      {!isSearchModal && <AppNavbar type={1} />}
      <PageLayout
        title={pageTitle ?? "Search"}
        parents={displayAdminBreadcrumb ? [{ to: "/admin", title: "Admin" }] : []}
        showHeadingAndBreadcrumb={isSearchModal ? showHeadingAndBreadcrumb : true}
      >
        <Row>
          <Container>
            <Form>
              <Row className="search-panel mx-1">
                <Row className="flex-row mx-1">
                  <Col className="p-2" xl={7} lg={7} md={12} sm={12}>
                    <Row className="flex-row">
                      <Col className="p-2" xl={4} lg={4} md={4} sm={12}>
                        <input
                          type="text"
                          className="form-control search-slt"
                          placeholder={"First Name"}
                          value={parameters.first_name || ""}
                          onChange={(e) => {
                            const value = e.target.value;
                            setParameters({
                              ...parameters,
                              first_name: value,
                            });
                            setCurrentPage(1);
                          }}
                        />
                      </Col>
                      <Col className="p-2" xl={4} lg={4} md={4} sm={12}>
                        <input
                          type="text"
                          className="form-control search-slt"
                          placeholder={"Middle Name"}
                          value={parameters.middle_name}
                          onChange={(e) => {
                            const value = e.target.value;
                            setParameters({
                              ...parameters,
                              middle_name: value,
                            });
                            setCurrentPage(1);
                          }}
                        />
                      </Col>
                      <Col className="p-2" xl={4} lg={4} md={4} sm={12}>
                        <input
                          type="text"
                          className="form-control search-slt"
                          placeholder={"Surname"}
                          value={parameters.surname}
                          onChange={(e) => {
                            const value = e.target.value;
                            setParameters({ ...parameters, surname: value });
                            setCurrentPage(1);
                          }}
                        />
                      </Col>
                    </Row>
                  </Col>

                  <Col className="p-1 mt-1" xl={5} lg={5} md={12} sm={12}>
                    <Row className="flex-row px-2">
                      <Col className="p-2" md="auto">
                        <Dropdown>
                          <Dropdown.Toggle className="filter-button" disabled={isAnySearchFieldsAdded || !open}>
                            Add Filters
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            {advancedSearchFilters.map((field, index) => {
                              return (
                                !field.isVisible && (
                                  <Dropdown.Item key={index} onClick={() => onFieldSelected(field)}>
                                    {field.fieldName}
                                  </Dropdown.Item>
                                )
                              );
                            })}
                          </Dropdown.Menu>
                        </Dropdown>
                      </Col>
                      <Col className="p-2" md="auto">
                        <Button
                          onClick={() => onClearAllClicked()}
                          aria-controls="advanced-filter-fields"
                          className="filter-button"
                          aria-expanded={open}
                        >
                          Clear All
                        </Button>
                      </Col>
                      <Col className="p-2" md="auto">
                        <Dropdown autoClose="outside">
                          <Dropdown.Toggle className="filter-button">Hide/Show Columns</Dropdown.Toggle>

                          <Dropdown.Menu className="hide-show-col-dropdown-menu">
                            {columns.map((column, index) => {
                              if (Object.values(columnIds).includes(column.id)) {
                                return (
                                  <Dropdown.Item
                                    key={index}
                                    as={CheckDropdownItem}
                                    checked={!Object.values(hiddenColIds).includes(column.id)}
                                    onChange={() => {
                                      biographyTableRef.current.hideShowColumn(column);
                                    }}
                                  >
                                    {column.Header}
                                  </Dropdown.Item>
                                );
                              } else return null;
                            })}
                          </Dropdown.Menu>
                        </Dropdown>
                      </Col>
                      <Col className="p-2" md="auto">
                        <Button
                          onClick={() => setOpen(!open)}
                          aria-controls="advanced-filter-fields"
                          aria-expanded={open}
                          className="chevron-button"
                          disabled={advancedSearchFilters.filter((item) => item.isVisible === true)?.length === 0}
                        >
                          {open && <i className="bi bi-chevron-double-up chevron-icon"></i>}
                          {!open && <i className="bi bi-chevron-double-down chevron-icon"></i>}
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Collapse in={open}>
                  <Row className="flex-row justify-content-start p-2 mx-2" id="advanced-filter-fields">
                    <>
                      {/* Only display it for Parents, Children and Spouses relationships */}
                      {displayStubEntryFilterCheckbox && (
                        <Col className="mt-1" xl={4} lg={4} md={12} sm={12}>
                          <Row>
                            <Col className="p-0" xl={10} lg={10} md={10} sm={12}>
                              <Select
                                inputRef={selectInputRef}
                                styles={customSelectStyles}
                                classNamePrefix="select"
                                aria-label={"State"}
                                placeholder={<small>Select State</small>}
                                value={selectedState}
                                name={"biography_state"}
                                options={options}
                                onChange={(selectedOption) => {
                                  setBioState(selectedOption.value);
                                  setSelectedState(selectedOption);
                                }}
                              />
                            </Col>
                          </Row>
                        </Col>
                      )}
                      {getField(defaultSearchFieldIds.otherNames).isVisible && (
                        <>
                          <Col className="mt-1" xl={4} lg={4} md={12} sm={12}>
                            <Row>
                              <Col className="p-0" xl={10} lg={10} md={10} sm={12}>
                                <input
                                  type="text"
                                  className="form-control search-slt"
                                  placeholder={"Other Names"}
                                  value={parameters.other_names}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    setParameters({ ...parameters, other_names: value });
                                    setCurrentPage(1);
                                  }}
                                />
                              </Col>
                              <RemoveFieldIcon
                                field={getField(defaultSearchFieldIds.otherNames)}
                                onRemove={() => {
                                  setParameters({
                                    ...parameters,
                                    other_names: "",
                                  });
                                }}
                              />
                            </Row>
                          </Col>
                        </>
                      )}

                      {getField(defaultSearchFieldIds.placeOfBirth).isVisible && (
                        <>
                          <Col className="mt-1" xl={4} lg={4} md={12} sm={12}>
                            <Row>
                              <Col className="p-0" xl={10} lg={10} md={10} sm={12}>
                                <SearchAndAsyncSelect
                                  styles={customSelectStyles}
                                  place={selectedBirthPlace}
                                  placeholder={<small>Birth place</small>}
                                  fieldValue={selectedBirthPlace}
                                  control={control}
                                  name={"birth_place"}
                                  onselectionchange={(option, onChange) => {
                                    const value = option?.label;
                                    setCurrentPage(1);
                                    setParameters({
                                      ...parameters,
                                      birth_place: value,
                                      birth_place_id: getIdFromUrl(option?.value),
                                    });
                                    onChange(option?.value);
                                    setSelectedBirthPlace(option);
                                  }}
                                />
                              </Col>
                              <RemoveFieldIcon
                                field={getField(defaultSearchFieldIds.placeOfBirth)}
                                onRemove={() => {
                                  setParameters({
                                    ...parameters,
                                    birth_place: "",
                                    birth_place_id: "",
                                  });
                                  setSelectedBirthPlace(null);
                                }}
                              />
                            </Row>
                          </Col>
                        </>
                      )}
                      {getField(defaultSearchFieldIds.placeOfDeath).isVisible && (
                        <>
                          <Col className="mt-1" xl={4} lg={4} md={12} sm={12}>
                            <Row>
                              <Col className="p-0" xl={10} lg={10} md={10} sm={12}>
                                <SearchAndAsyncSelect
                                  styles={customSelectStyles}
                                  place={selectedDeathPlace}
                                  fieldValue={selectedDeathPlace}
                                  placeholder={<small>Death place</small>}
                                  control={control}
                                  name={"death_place"}
                                  onselectionchange={(option, onChange) => {
                                    const value = option?.label;
                                    setCurrentPage(1);
                                    setParameters({
                                      ...parameters,
                                      death_place: value,
                                      death_place_id: getIdFromUrl(option?.value),
                                    });
                                    onChange(option?.value);
                                    setSelectedDeathPlace(option);
                                  }}
                                />
                              </Col>
                              <RemoveFieldIcon
                                field={getField(defaultSearchFieldIds.placeOfDeath)}
                                onRemove={() => {
                                  setParameters({
                                    ...parameters,
                                    death_place: "",
                                    death_place_id: "",
                                  });
                                  setSelectedDeathPlace(null);
                                }}
                              />
                            </Row>
                          </Col>
                        </>
                      )}

                      {getField(defaultSearchFieldIds.placeOfBurial).isVisible && (
                        <>
                          <Col className="mt-1" xl={4} lg={4} md={12} sm={12}>
                            <Row>
                              <Col className="p-0" xl={10} lg={10} md={10} sm={12}>
                                <SearchAndAsyncSelect
                                  styles={customSelectStyles}
                                  place={selectedBurialPlace}
                                  fieldValue={selectedBurialPlace}
                                  placeholder={<small>Burial place</small>}
                                  control={control}
                                  name={"burial_place"}
                                  onselectionchange={(option, onChange) => {
                                    const value = option?.label;
                                    setCurrentPage(1);
                                    setParameters({
                                      ...parameters,
                                      burial_place: value,
                                      burial_place_id: getIdFromUrl(option?.value),
                                    });
                                    onChange(option?.value);
                                    setSelectedBurialPlace(option);
                                  }}
                                />
                              </Col>
                              <RemoveFieldIcon
                                field={getField(defaultSearchFieldIds.placeOfBurial)}
                                onRemove={() => {
                                  setParameters({
                                    ...parameters,
                                    burial_place: "",
                                    burial_place_id: "",
                                  });
                                  setSelectedBurialPlace(null);
                                }}
                              />
                            </Row>
                          </Col>
                        </>
                      )}

                      {getField(defaultSearchFieldIds.residencePlaceOrPropertyOwnedPlace).isVisible && (
                        <>
                          <Col className="mt-1" xl={4} lg={4} md={12} sm={12}>
                            <Row>
                              <Col className="p-0" xl={10} lg={10} md={10} sm={12}>
                                <SearchAndAsyncSelect
                                  styles={customSelectStyles}
                                  place={selectedResidenceOrPropertyOwnedPlace}
                                  fieldValue={selectedResidenceOrPropertyOwnedPlace}
                                  placeholder={<small>Residence or property owned place</small>}
                                  control={control}
                                  name={"residence_place"}
                                  onselectionchange={(option, onChange) => {
                                    const value = option?.label;
                                    setCurrentPage(1);
                                    setParameters({
                                      ...parameters,
                                      place_of_residence_or_property_owned: value,
                                      place_of_residence_or_property_owned_id: getIdFromUrl(option?.value),
                                    });
                                    onChange(option?.value);
                                    setSelectedResidenceOrPropertyOwnedPlace(option);
                                  }}
                                />
                              </Col>
                              <RemoveFieldIcon
                                field={getField(defaultSearchFieldIds.residencePlaceOrPropertyOwnedPlace)}
                                onRemove={() => {
                                  setParameters({
                                    ...parameters,
                                    place_of_residence_or_property_owned: "",
                                    place_of_residence_or_property_owned_id: "",
                                  });
                                  setSelectedResidenceOrPropertyOwnedPlace(null);
                                }}
                              />
                            </Row>
                          </Col>
                        </>
                      )}

                      {getField(defaultSearchFieldIds.residencePlaceOrPropertyOwnedName).isVisible && (
                        <>
                          <Col className="mt-1" xl={4} lg={4} md={12} sm={12}>
                            <Row>
                              <Col className="p-0" xl={10} lg={10} md={10} sm={12}>
                                <input
                                  type="text"
                                  className="form-control search-slt"
                                  placeholder={"Residence or Property owned name"}
                                  value={parameters.residence_or_property_owned_name}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    setParameters({
                                      ...parameters,
                                      residence_or_property_owned_name: value,
                                    });
                                    setCurrentPage(1);
                                  }}
                                />
                              </Col>
                              <RemoveFieldIcon
                                field={getField(defaultSearchFieldIds.residencePlaceOrPropertyOwnedName)}
                                onRemove={() => {
                                  setParameters({
                                    ...parameters,
                                    residence_or_property_owned_name: "",
                                  });
                                }}
                              />
                            </Row>
                          </Col>
                        </>
                      )}

                      {getField(defaultSearchFieldIds.gender).isVisible && (
                        <>
                          {" "}
                          <Col className="mt-1" xl={4} lg={4} md={12} sm={12}>
                            <Row>
                              <Col className="p-0" xl={10} lg={10} md={10} sm={12}>
                                <LabelAndSelect
                                  searchFieldStyle={customSelectStyles}
                                  control={control}
                                  placeholder={<small>Select Gender</small>}
                                  name="gender"
                                  classNames={"col-md-12"}
                                  labelClassName={"form-label required"}
                                  getValues={getValues}
                                  onSelectionChange={(option) => {
                                    const value = option?.value;
                                    setCurrentPage(1);
                                    setParameters({
                                      ...parameters,
                                      gender: value,
                                    });
                                  }}
                                />
                              </Col>
                              <RemoveFieldIcon
                                field={getField(defaultSearchFieldIds.gender)}
                                onRemove={() => {
                                  setValue("gender", "");
                                  setParameters({
                                    ...parameters,
                                    gender: "",
                                  });
                                }}
                              />
                            </Row>
                          </Col>
                        </>
                      )}

                      {getField(defaultSearchFieldIds.culturalHeritage).isVisible && (
                        <>
                          <Col className="mt-1" xl={4} lg={4} md={12} sm={12}>
                            <Row>
                              <Col className="p-0" xl={10} lg={10} md={10} sm={12}>
                                <input
                                  type="text"
                                  className="form-control search-slt"
                                  placeholder={"Cultural Heritage"}
                                  value={parameters.cultural_heritage}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    setParameters({
                                      ...parameters,
                                      cultural_heritage: value,
                                    });
                                    setCurrentPage(1);
                                  }}
                                />
                              </Col>
                              <RemoveFieldIcon
                                field={getField(defaultSearchFieldIds.culturalHeritage)}
                                onRemove={() => {
                                  setValue("cultural_heritage", "");
                                  setParameters({
                                    ...parameters,
                                    cultural_heritage: "",
                                  });
                                }}
                              />
                            </Row>
                          </Col>
                        </>
                      )}

                      {getField(defaultSearchFieldIds.religion).isVisible && (
                        <>
                          {" "}
                          <Col className="mt-1" xl={4} lg={4} md={12} sm={12}>
                            <Row>
                              <Col className="p-0" xl={10} lg={10} md={10} sm={12}>
                                <LabelAndGlossarySearch
                                  displayGlossaryStatusMessage={false}
                                  placeholder={<small>Type to search religion or enter text</small>}
                                  onGlossarySelectionChange={(option) => {
                                    setParameters({
                                      ...parameters,
                                      religion: option?.value,
                                    });
                                  }}
                                  glossaryType={"Religion"}
                                  getValues={getValues}
                                  setValue={setValue}
                                  control={control}
                                  registerText={"religion"}
                                  styles={customSelectStyles}
                                />
                              </Col>
                              <RemoveFieldIcon
                                field={getField(defaultSearchFieldIds.religion)}
                                onRemove={() => {
                                  setValue("religion", "");
                                  setParameters({
                                    ...parameters,
                                    religion: "",
                                  });
                                }}
                              />
                            </Row>
                          </Col>
                        </>
                      )}

                      {getField(defaultSearchFieldIds.occupation).isVisible && (
                        <>
                          <Col className="mt-1" xl={4} lg={4} md={12} sm={12}>
                            <Row>
                              <Col className="p-0" xl={10} lg={10} md={10} sm={12}>
                                <LabelAndGlossarySearch
                                  displayGlossaryStatusMessage={false}
                                  placeholder={<small>Occupation</small>}
                                  onGlossarySelectionChange={(option) => {
                                    const value = option?.value;
                                    setParameters({
                                      ...parameters,
                                      occupation_name: value,
                                    });
                                    setCurrentPage(1);
                                  }}
                                  getValues={getValues}
                                  setValue={setValue}
                                  glossaryType={"Occupation"}
                                  registerText={"occupation_name"}
                                  control={control}
                                  styles={customSelectStyles}
                                />
                              </Col>
                              <RemoveFieldIcon
                                field={getField(defaultSearchFieldIds.occupation)}
                                onRemove={() => {
                                  setValue("occupation_name", "");
                                  setParameters({
                                    ...parameters,
                                    occupation_name: "",
                                  });
                                }}
                              />
                            </Row>
                          </Col>
                        </>
                      )}
                      {getField(defaultSearchFieldIds.shipArrivedInAustralia).isVisible && (
                        <>
                          {" "}
                          <Col className="mt-1" xl={4} lg={4} md={12} sm={12}>
                            <Row>
                              <Col className="p-0" xl={10} lg={10} md={10} sm={12}>
                                <LabelAndGlossarySearch
                                  displayGlossaryStatusMessage={false}
                                  placeholder={<small>Ship arrived in Australia</small>}
                                  onGlossarySelectionChange={(option) => {
                                    const value = option?.value;
                                    setParameters({
                                      ...parameters,
                                      ship_arrived_in_australia: value,
                                    });
                                    setCurrentPage(1);
                                  }}
                                  getValues={getValues}
                                  setValue={setValue}
                                  glossaryType={"ShipsArrivedInAustralia"}
                                  registerText="ship_arrived_in_australia"
                                  control={control}
                                  styles={customSelectStyles}
                                />
                              </Col>
                              <RemoveFieldIcon
                                field={getField(defaultSearchFieldIds.shipArrivedInAustralia)}
                                onRemove={() => {
                                  setValue("ship_arrived_in_australia", "");
                                  setParameters({
                                    ...parameters,
                                    ship_arrived_in_australia: "",
                                  });
                                }}
                              />
                            </Row>
                          </Col>
                        </>
                      )}
                      {getField(defaultSearchFieldIds.shipArrivedInQueensland).isVisible && (
                        <>
                          {" "}
                          <Col className="mt-1" xl={4} lg={4} md={12} sm={12}>
                            <Row>
                              <Col className="p-0" xl={10} lg={10} md={10} sm={12}>
                                <LabelAndGlossarySearch
                                  displayGlossaryStatusMessage={false}
                                  placeholder={<small>Ship arrived in Queensland</small>}
                                  onGlossarySelectionChange={(option) => {
                                    const value = option?.value;
                                    setParameters({
                                      ...parameters,
                                      ship_arrived_in_qld: value,
                                    });
                                    setCurrentPage(1);
                                  }}
                                  getValues={getValues}
                                  setValue={setValue}
                                  glossaryType={"ShipsArrivedInQueensland"}
                                  registerText="ship_arrived_in_qld"
                                  control={control}
                                  styles={customSelectStyles}
                                />
                              </Col>
                              <RemoveFieldIcon
                                field={getField(defaultSearchFieldIds.shipArrivedInQueensland)}
                                onRemove={() => {
                                  setValue("ship_arrived_in_qld", "");
                                  setParameters({
                                    ...parameters,
                                    ship_arrived_in_qld: "",
                                  });
                                }}
                              />
                            </Row>
                          </Col>
                        </>
                      )}
                      {getField(defaultSearchFieldIds.indigenousStatus).isVisible && (
                        <>
                          {" "}
                          <Col className="mt-1" xl={4} lg={4} md={12} sm={12}>
                            <Row>
                              <Col className="p-0" xl={10} lg={10} md={10} sm={12}>
                                <LabelAndSelect
                                  searchFieldStyle={customSelectStyles}
                                  control={control}
                                  placeholder={<small>Select Indigenous Status</small>}
                                  name="indigenous_status"
                                  classNames={"col-md-12"}
                                  labelClassName={"form-label required"}
                                  getValues={getValues}
                                  onSelectionChange={(option) => {
                                    const value = option?.value;
                                    setCurrentPage(1);
                                    setParameters({
                                      ...parameters,
                                      indigenous_status: value,
                                    });
                                  }}
                                />
                              </Col>
                              <RemoveFieldIcon
                                field={getField(defaultSearchFieldIds.indigenousStatus)}
                                onRemove={() => {
                                  setValue("indigenous_status", "");
                                  setParameters({
                                    ...parameters,
                                    indigenous_status: "",
                                  });
                                }}
                              />
                            </Row>
                          </Col>
                        </>
                      )}

                      {(getField(defaultSearchFieldIds.birthYear).isVisible ||
                        getField(defaultSearchFieldIds.deathYear).isVisible ||
                        getField(defaultSearchFieldIds.yearArrivedAustralia).isVisible ||
                        getField(defaultSearchFieldIds.yearArrivedQld).isVisible) && (
                        <Row className="mt-2">
                          {getField(defaultSearchFieldIds.birthYear).isVisible && (
                            <>
                              {" "}
                              <Col className="mt-1" xl={6} lg={6} md={12} sm={12}>
                                <Row>
                                  {" "}
                                  <Col className="p-0" xl={5} lg={5} md={5} sm={12}>
                                    <input
                                      type="number"
                                      min={0}
                                      className="form-control search-slt"
                                      name={"from_birth_year"}
                                      {...register("from_birth_year", { minLength: 4 })}
                                      placeholder={"From or exact birth year"}
                                      value={parameters.from_birth_year}
                                      onChange={(e) => {
                                        const value = e.target.value;
                                        validate({ name: "from_birth_year", value: value });
                                        setParameters({ ...parameters, from_birth_year: value });
                                        setCurrentPage(1);
                                      }}
                                    />
                                    <DisplayErrors error={errors.from_birth_year}></DisplayErrors>
                                  </Col>
                                  <Col className="p-0 mx-1" xl={5} lg={5} md={5} sm={12}>
                                    <input
                                      type="number"
                                      min={0}
                                      className="form-control search-slt"
                                      name={"to_birth_year"}
                                      {...register("to_birth_year")}
                                      placeholder={"To birth year"}
                                      value={parameters.to_birth_year}
                                      onChange={(e) => {
                                        const value = e.target.value;
                                        validate({ name: "to_birth_year", value: value });
                                        setParameters({ ...parameters, to_birth_year: value });
                                        setCurrentPage(1);
                                      }}
                                    />
                                    <DisplayErrors error={errors.to_birth_year}></DisplayErrors>
                                  </Col>
                                  <RemoveFieldIcon
                                    field={getField(defaultSearchFieldIds.birthYear)}
                                    onRemove={() => {
                                      setValue("from_birth_year", null);
                                      setValue("to_birth_year", null);
                                      setParameters({
                                        ...parameters,
                                        from_birth_year: null,
                                        to_birth_year: null,
                                      });
                                    }}
                                  />
                                </Row>
                              </Col>
                            </>
                          )}
                          {getField(defaultSearchFieldIds.deathYear).isVisible && (
                            <>
                              <Col className="mt-1" xl={6} lg={6} md={12} sm={12}>
                                <Row>
                                  {" "}
                                  <Col className="p-0" xl={5} lg={5} md={5} sm={12}>
                                    <input
                                      type="number"
                                      min={0}
                                      className="form-control search-slt"
                                      placeholder={"From or exact death year"}
                                      name={"from_death_year"}
                                      {...register("from_death_year")}
                                      value={parameters.from_death_year}
                                      onChange={(e) => {
                                        const value = e.target.value;
                                        validate({ name: "from_death_year", value: value });
                                        setParameters({ ...parameters, from_death_year: value });
                                        setCurrentPage(1);
                                      }}
                                    />
                                    <DisplayErrors error={errors.from_death_year}></DisplayErrors>
                                  </Col>
                                  <Col className="p-0 mx-1" xl={5} lg={5} md={5} sm={12}>
                                    <input
                                      type="number"
                                      min={0}
                                      className="form-control search-slt"
                                      placeholder={"To death year"}
                                      name={"to_death_year"}
                                      {...register("to_death_year")}
                                      value={parameters.to_death_year}
                                      onChange={(e) => {
                                        const value = e.target.value;
                                        validate({ name: "to_death_year", value: value });
                                        setParameters({ ...parameters, to_death_year: value });
                                        setCurrentPage(1);
                                      }}
                                    />
                                    <DisplayErrors error={errors.to_death_year}></DisplayErrors>
                                  </Col>
                                  <RemoveFieldIcon
                                    field={getField(defaultSearchFieldIds.deathYear)}
                                    onRemove={() => {
                                      setValue("from_death_year", null);
                                      setValue("to_death_year", null);
                                      setParameters({
                                        ...parameters,
                                        from_death_year: null,
                                        to_death_year: null,
                                      });
                                    }}
                                  />
                                </Row>
                              </Col>
                            </>
                          )}
                          {getField(defaultSearchFieldIds.yearArrivedAustralia).isVisible && (
                            <>
                              <Col className="mt-1" xl={6} lg={6} md={12} sm={12}>
                                <Row>
                                  {" "}
                                  <Col className="p-0" xl={5} lg={5} md={5} sm={12}>
                                    <input
                                      type="number"
                                      min={0}
                                      className="form-control search-slt"
                                      placeholder={"From/exact year arrived in Australia"}
                                      name={"from_year_arrived_australia"}
                                      {...register("from_year_arrived_australia")}
                                      value={parameters.from_year_arrived_australia}
                                      onChange={(e) => {
                                        const value = e.target.value;
                                        validate({ name: "from_year_arrived_australia", value: value });
                                        setParameters({ ...parameters, from_year_arrived_australia: value });
                                        setCurrentPage(1);
                                      }}
                                    />
                                    <DisplayErrors error={errors.from_year_arrived_australia}></DisplayErrors>
                                  </Col>
                                  <Col className="p-0 mx-1" xl={5} lg={5} md={5} sm={12}>
                                    <input
                                      type="number"
                                      min={0}
                                      className="form-control search-slt"
                                      placeholder={"To year arrived in Australia"}
                                      name={"to_year_arrived_australia"}
                                      {...register("to_year_arrived_australia")}
                                      value={parameters.to_year_arrived_australia}
                                      onChange={(e) => {
                                        const value = e.target.value;
                                        validate({ name: "to_year_arrived_australia", value: value });
                                        setParameters({ ...parameters, to_year_arrived_australia: value });
                                        setCurrentPage(1);
                                      }}
                                    />
                                    <DisplayErrors error={errors.to_year_arrived_australia}></DisplayErrors>
                                  </Col>
                                  <RemoveFieldIcon
                                    field={getField(defaultSearchFieldIds.yearArrivedAustralia)}
                                    onRemove={() => {
                                      setValue("from_year_arrived_australia", null);
                                      setValue("to_year_arrived_australia", null);
                                      setParameters({
                                        ...parameters,
                                        from_year_arrived_australia: null,
                                        to_year_arrived_australia: null,
                                      });
                                    }}
                                  />
                                </Row>
                              </Col>
                            </>
                          )}
                          {getField(defaultSearchFieldIds.yearArrivedQld).isVisible && (
                            <>
                              <Col className="mt-1" xl={6} lg={6} md={12} sm={12}>
                                <Row>
                                  {" "}
                                  <Col className="p-0" xl={5} lg={5} md={5} sm={12}>
                                    <input
                                      type="number"
                                      min={0}
                                      className="form-control search-slt"
                                      placeholder={"From/exact year arrived in Queensland"}
                                      name={"from_year_arrived_qld"}
                                      {...register("from_year_arrived_qld")}
                                      value={parameters.from_year_arrived_qld}
                                      onChange={(e) => {
                                        const value = e.target.value;
                                        validate({ name: "from_year_arrived_qld", value: value });
                                        setParameters({ ...parameters, from_year_arrived_qld: value });
                                        setCurrentPage(1);
                                      }}
                                    />
                                    <DisplayErrors error={errors.from_year_arrived_qld}></DisplayErrors>
                                  </Col>
                                  <Col className="p-0 mx-1" xl={5} lg={5} md={5} sm={12}>
                                    <input
                                      type="number"
                                      min={0}
                                      className="form-control search-slt"
                                      name={"to_year_arrived_qld"}
                                      {...register("to_year_arrived_qld")}
                                      placeholder={"To year arrived in Queensland"}
                                      value={parameters.to_year_arrived_qld}
                                      onChange={(e) => {
                                        const value = e.target.value;
                                        validate({ name: "to_year_arrived_qld", value: value });
                                        setParameters({ ...parameters, to_year_arrived_qld: value });
                                        setCurrentPage(1);
                                      }}
                                    />
                                    <DisplayErrors error={errors.to_year_arrived_qld}></DisplayErrors>
                                  </Col>
                                  <RemoveFieldIcon
                                    field={getField(defaultSearchFieldIds.yearArrivedQld)}
                                    onRemove={() => {
                                      setValue("from_year_arrived_qld", null);
                                      setValue("to_year_arrived_qld", null);
                                      setParameters({
                                        ...parameters,
                                        from_year_arrived_qld: null,
                                        to_year_arrived_qld: null,
                                      });
                                    }}
                                  />
                                </Row>
                              </Col>
                            </>
                          )}
                        </Row>
                      )}
                    </>
                  </Row>
                </Collapse>
                {!open && (
                  <p className="text-end mb-1">
                    {" "}
                    <small>
                      <b>{numberOfAddedFields}</b> filters have been added. Either clear all to start over or open
                      search panel to continue.
                    </small>
                  </p>
                )}
                {open && numberOfAddedFields === 0 && (
                  <p className="text-end mb-1">
                    {" "}
                    <small>
                      Search names or add filters from <b>Add Filters</b>.
                    </small>
                  </p>
                )}
              </Row>
            </Form>
          </Container>
        </Row>
        {/* display spinner when loading data */}
        {isLoading && <Spinner />}
        {/* Display Search and Published Entries Table only if there are any published entries to display. */}
        {responseData && !displayInfoMessage && (
          <>
            {/* Display Search result table only if there are any results entries to display. */}
            {responseData.length > 0 && (
              <>
                <BiographyTable
                  ref={biographyTableRef}
                  data={responseData}
                  isSearchModal={isSearchModal}
                  onPersonSelected={onPersonSelected}
                  parameters={parameters}
                  setParameters={setParameters}
                  hideStateColumn={hideStateColumn === undefined ? true : hideStateColumn}
                  hideActionColumn={hideActionColumn === undefined ? true : hideActionColumn}
                />
                <Row>
                  <Col className="mt-1">
                    <PaginationBar
                      onChange={setCurrentPage}
                      nextUrl={nextUrl}
                      previousUrl={previousUrl}
                      currentPage={currentPage || 1}
                      itemCount={itemCount}
                    />
                  </Col>
                  {!isSearchModal && (
                    <Col className="mt-1 p-0">
                      <Button
                        aria-controls="advanced-filter-fields"
                        onClick={() => exportResultsInCSV()}
                        className="display-block float-end"
                        disabled={isCSVDataLoading}
                        aria-expanded={open}
                      >
                        {isCSVDataLoading ? (
                          <>
                            <ReactSpinner
                              as="span"
                              animation="grow"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                              className="mx-2"
                            />
                            Loading...
                          </>
                        ) : (
                          <>Export Search Results</>
                        )}
                      </Button>
                    </Col>
                  )}
                </Row>
              </>
            )}
          </>
        )}

        {/* Display message when the subsequent search results return blank array */}
        {!isLoading && displayInfoMessage && responseData && responseData.length === 0 && (
          <div className="row">
            <Alert className="info-message mt-5">
              There are no matching results.
              <li>Make sure that all words are spelled correctly.</li>
              <li>Try different filters.</li>
              <li>
                Refer
                <Link to="/help#search-section"> Help Page Search Section</Link> for more information.
              </li>
            </Alert>
          </div>
        )}

        {/* Display message if there are no published entries to display. 
      Will only be displayed based on the first search result i.e on first page load.  */}
        {displayInfoMessage && !responseData && (
          <div style={{ marginLeft: 3, paddingRight: 43, marginTop: 10 }}>
            <Alert className="info-message">
              {alertMessage === undefined ? "There are no Published Entries." : alertMessage}
            </Alert>
          </div>
        )}
      </PageLayout>
    </>
  );
};

export default SearchPage;
