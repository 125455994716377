import React, { useContext, useEffect, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { updateFormData } from "../../../stateManager/actions/BiographyActions";
import { BiographyStore } from "../../../stateManager/stores/BiographyStore";
import { LabelAndGlossarySearch } from "../FormComponents/LabelAndGlossarySearch";
import UncertainDateController from "../FormComponents/UncertainDateController";
import { ArrayFieldAddButton } from "./ArrayFieldAddButton";
import { ArrayFieldDeleteButton } from "./ArrayFieldDeleteButton";
import { ArrayFieldMoveUpDownButtons } from "./ArrayFieldMoveUpDownButtons";
import { ArrayFieldUndoButton } from "./ArrayFieldUndoButton";
import NoDataProvidedMessage from "./NoDataProvidedMessage";

function OccupationArray({ classNames, onShowDatesFormatLinkClicked }) {
  const {
    control,
    register,
    reset,
    getValues,
    setValue,
    formState: { errors },
  } = useFormContext();

  const { fields, append, remove, swap } = useFieldArray({
    control,
    name: "occupation",
  });

  const [displayUndoButton, setDisplayUndoButton] = useState(false);
  const { state, dispatch } = useContext(BiographyStore);
  const occupationValues = getValues("occupation");

  useEffect(() => {
    if (JSON.stringify(occupationValues) === JSON.stringify(state.formData.occupation)) {
      setDisplayUndoButton(false);
    } else {
      setDisplayUndoButton(true);
    }
  }, [occupationValues, state]);

  if (state?.formData.occupation.length === 0 && state?.isReadOnly) {
    return <NoDataProvidedMessage classNames={classNames} />;
  }

  return (
    <div className={"col-12 " + classNames}>
      {fields.map((item, index) => {
        const itemNum = index + 1;

        return (
          <div className="row g-2 field-array-row" key={item.id}>
            <div className="col-md-9 field-column">
              <div className="sub-field-array-style row g-2 mb-3" id={item.id}>
                <p className="subTitle">{`Occupation ` + itemNum}</p>
                <div className="col-6">
                  <LabelAndGlossarySearch
                    placeholder={"Search Glossary or enter text*"}
                    displayGlossaryStatusMessage={true}
                    onGlossarySelectionChange={() => null}
                    error={errors?.religion?.message}
                    registerText={`occupation[${index}].occupation_name`}
                    glossaryType={"Occupation"}
                    control={control}
                    getValues={getValues}
                    setValue={setValue}
                    isReadOnly={state?.isReadOnly}
                  />
                  {errors?.occupation?.[index]?.occupation_name && (
                    <span className="text-danger" data-testid="error-message">
                      {errors?.occupation[index].occupation_name.message}
                    </span>
                  )}
                </div>
                <div className="col-6">
                  <input
                    id={`occupation[${index}].employer_name`}
                    name={`occupation[${index}].employer_name`}
                    {...register(`occupation[${index}].employer_name`)}
                    placeholder="Employer name"
                    className="form-control"
                    readOnly={state.isReadOnly}
                  />
                </div>
                <div className="col-6">
                  <UncertainDateController
                    name={`occupation[${index}].start_date`}
                    error={errors?.occupation?.[index]?.start_date?.message}
                    placeholder="Start date"
                    displayDatesInfoLink={true}
                    onShowDatesFormatLinkClicked={onShowDatesFormatLinkClicked}
                  />
                </div>
                <div className="col-6">
                  <UncertainDateController
                    name={`occupation[${index}].end_date`}
                    error={errors?.occupation?.[index]?.end_date?.message}
                    placeholder="End date"
                    displayDatesInfoLink={true}
                    onShowDatesFormatLinkClicked={onShowDatesFormatLinkClicked}
                  />
                </div>
                <div className="col-12">
                  <input
                    id={`occupation[${index}].description`}
                    name={`occupation[${index}].description`}
                    {...register(`occupation[${index}].description`)}
                    placeholder="Description"
                    className="form-control"
                    readOnly={state.isReadOnly}
                  />
                </div>
              </div>
            </div>
            {!state?.isReadOnly && (
              <div className="col-md-3">
                <div className="row flex-row p-0">
                  <ArrayFieldDeleteButton
                    dataTestId={`occupation-delete-button-${index}`}
                    onClick={() => {
                      updateFormData(getValues(), dispatch);
                      remove(index);
                    }}
                  />
                  <ArrayFieldMoveUpDownButtons
                    onDownButtonClick={() => swap(index, index + 1)}
                    onUpButtonClick={() => swap(index, index - 1)}
                    index={index}
                    fields={fields}
                    upButtonDataTestId={`occupation-${index}-up`}
                    downButtonDataTestId={`occupation-${index}-down`}
                  />
                </div>
              </div>
            )}
          </div>
        );
      })}

      {!state?.isReadOnly && (
        <ArrayFieldAddButton
          dataTestId={"occupation-add-button"}
          onClick={() => {
            append({ occupation_name: null, employer_name: "", start_date: "", end_date: "", description: "" });
            updateFormData(getValues(), dispatch);
          }}
        />
      )}
      {displayUndoButton && (
        <ArrayFieldUndoButton
          dataTestId={"occupation-undo-button"}
          getValues={getValues}
          append={append}
          reset={reset}
        />
      )}
    </div>
  );
}

export default OccupationArray;
