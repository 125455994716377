import { useCallback, useEffect, useRef, useState } from "react";
import { Controller } from "react-hook-form";
import { glossaryAPI } from "../../../api/glossaryAPI";
import AsyncSelect from "react-select/async";

// Reusable data input structure
export const LabelAndGlossarySearch = ({
  labelText,
  error,
  classNames = "",
  labelClassName = "form-label",
  registerText,
  glossaryType,
  control,
  isReadOnly,
  getValues,
  styles,
  placeholder,
  onGlossarySelectionChange,
  displayGlossaryStatusMessage,
  setValue,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [responseData, setResponseData] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [isValueInGlossary, setIsValueInGlossary] = useState(false);
  const [checkWhetherValueIsInGlossary, setCheckWhetherValueIsInGlossary] = useState(false);
  const selectRef = useRef();
  const fieldValue = getValues(registerText);

  useEffect(() => {
    if (fieldValue) {
      // Pre-fill the select value from the DB if saved any
      setSelectedOption({ value: fieldValue, label: fieldValue });
    } else {
      setSelectedOption(null);
    }
  }, [fieldValue]);

  useEffect(() => {
    // We are making a request to check whether the value is in the glossary or not
    !fieldValue && !selectedOption?.value && setValue(registerText, null);
    selectedOption && selectedOption.value && setCheckWhetherValueIsInGlossary(true);
  }, [selectedOption, fieldValue, registerText, setValue]);

   // API call to search glossaries based on given name
   const searchGlossaries = useCallback((name) => {
    glossaryAPI
      .getGlossaryEntriesByGlossaryType({ glossaryType, name })
      .then((response) => {
        const data = response?.data?.results;
        setResponseData(data);

        // Here we are setting a flag isValueInGlossary based on the length of the data received,
        // we only want to do when the fieldValue changes not on each keystroke,
        // hence using the checkWhetherValueIsInGlossary which we are setting in above useEffect
        if (checkWhetherValueIsInGlossary) {
          if (data.length === 0) setIsValueInGlossary(false);
          else setIsValueInGlossary(true);
          setCheckWhetherValueIsInGlossary(false);
        }
      })
      .catch((e) => {
        console.log("error", e);
      })
  }, [checkWhetherValueIsInGlossary, glossaryType]);

  useEffect(() => {
    checkWhetherValueIsInGlossary && searchGlossaries(fieldValue);
  }, [checkWhetherValueIsInGlossary, fieldValue, searchGlossaries]);

  // Maps response data into options list and returns that list
  const mapOptions = (data) => {
    return data?.map((element) => {
      const value = element.name;
      const label = element.name;
      return { value, label };
    });
  };

  // Search glossaries based on input value. Set and return new input value
  const handleInputChange = (newValue) => {
    const newInputValue = newValue.trim();
    setInputValue(newInputValue);
    searchGlossaries(inputValue);
    selectRef.current = newInputValue;
    return inputValue;
  };

  // AsyncSelect loadOptions prop requires to pass a function that returns a promise,
  // which is the set of options to be used once the promise resolves.
  const loadOptions = async () => {
    // return null if user hasn't entered anything or inputValue is undefined or null
    if (!inputValue) {
      return null;
    }

    const options = mapOptions(responseData);

    // Add the current input value to option, only if no matched data found
    if (!responseData.some((data) => data?.name.toLowerCase() === selectRef.current.toLowerCase())) {
      options.unshift({ value: selectRef.current, label: selectRef.current });
    }

    // Returned mapped options if found match glossary entries
    return options;
  };

  return (
    <>
      <div className={classNames} id={registerText}>
        {labelText && (
          <label htmlFor={labelText} className={labelClassName} id={registerText + "-label"}>
            {labelText}:
          </label>
        )}
        <Controller
          name={registerText}
          id={registerText}
          control={control}
          render={({ field: { onChange, name } }) => (
            <AsyncSelect
              styles={styles}
              id={registerText}
              onChange={(option) => {
                setSelectedOption(option);
                onChange(option?.value);
                onGlossarySelectionChange(option);
              }}
              inputRef={selectRef}
              value={selectedOption}
              isDisabled={isReadOnly}
              name={name}
              placeholder={placeholder ? placeholder : "Search Glossary or enter text"}
              loadOptions={loadOptions}
              onInputChange={handleInputChange}
              isClearable
              cacheOptions={false}
              getOptionValue={(e) => e.value}
            />
          )}
        />
        <div>
          {displayGlossaryStatusMessage && selectedOption && !isValueInGlossary && (
            <small className="text-info" id={registerText + "-not-in-list-message"}>
              <i className="bi bi-check-circle-fill"></i> The selected value is currently not in the Glossary list.
            </small>
          )}
          {displayGlossaryStatusMessage && selectedOption && selectedOption.value && isValueInGlossary && (
            <small className="text-success" id={registerText + "-in-list-message"}>
              <i className="bi bi-check-circle-fill"></i> The selected value is in the Glossary list.
            </small>
          )}
        </div>
        {error && (
          <span className="text-danger" data-testid="error-message">
            {error}
          </span>
        )}
      </div>
    </>
  );
};
