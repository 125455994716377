import { useEffect, useRef } from "react";
import { User } from "oidc-client-ts";
import { useAuth } from "react-oidc-context";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import useSharedState from "./useSharedState";

function AuthEvents() {
  const auth = useAuth();
  const [isLoggedIn, setLoggedIn] = useSharedState("isLoggedIn", auth.isAuthenticated);
  const location = useLocation();
  const navigate = useNavigate();
  const interceptorId = useRef(null);

  //  maintain global axios interceptor that adds the Authorization header
  // if the user is logged in
  useEffect(() => {
    return auth.events.addUserLoaded((user) => {
      if (interceptorId.current != null) {
        axios.interceptors.request.eject(interceptorId.current);
        interceptorId.current = null;
      }
      if (user?.access_token && !user.expired) {
        interceptorId.current = axios.interceptors.request.use((config) => {
          config.headers.Authorization = `Bearer ${user?.access_token}`;
          return config;
        });
      }
    });
  }, [auth.events]);

  useEffect(() => {
    return auth.events.addUserUnloaded(() => {
      if (interceptorId.current != null) {
        axios.interceptors.request.eject(interceptorId.current);
        interceptorId.current = null;
      }
    });
  }, [auth.events]);

  const access_token = auth.user?.access_token;
  // the auth event leaves some query params that seem to do weird things when the user
  // reloads the page. since we only need them until the user is authenticated, we can
  // just remove them as an event handler for the UserLoaded event.

  const loadUserFromStorage = () => {
    for (let i = 0; i < window.localStorage.length; i++) {
      if (localStorage.key(i).startsWith("oidc.user")) {
        const userString = localStorage.getItem(localStorage.key(i));
        return User.fromStorageString(userString);
      }
    }
    return null;
  };

  // the oidc redirect leaves some query parameters behind that cause issues when the user reloads the page
  // a while after logging in (when the callback token has expired). Since they're no longer needed after the login,
  // we'll just remove them.
  useEffect(() => {
    return auth.events.addUserLoaded(() => {
      const queryParams = new URLSearchParams(location.search);
      if (queryParams.has("state")) {
        queryParams.delete("state");
      }
      if (queryParams.has("session_state")) {
        queryParams.delete("session_state");
      }
      if (queryParams.has("code")) {
        queryParams.delete("code");
      }
      navigate({
        search: queryParams.toString(),
      });
    });
  }, [auth, navigate, location]);

  // This effect will refresh the token before it expires. We'll use this instead of the build-in one
  // to add cross tab/window sync.
  useEffect(() => {
    return auth.events.addAccessTokenExpiring(async () => {
      try {
        const result = await navigator.locks.request("token_refresh_lock", async (lock) => {
          console.log("Acquired lock " + lock);
          const user = loadUserFromStorage();
          if (auth.user?.access_token === user?.access_token) {
            console.log(Date(Date.now()).toString() + " refreshing access token");
            await auth.signinSilent();
            return "ok";
          } else {
            console.log("token already refreshed. updating user.");
            auth.events.load(user);
          }
          // The lock will be released now.
        });
        console.log(result);
      } catch (ex) {
        console.log(ex);
      }
    });
  }, [auth.events, auth.signinSilent, auth.user, loadUserFromStorage]);

  // handle user unloaded events (logout or remove user)
  // handle user unloaded events (logour or auth.removeUser). addUserSignOut doesn't seem to ever fire so we
  // can't use that (I havent' dug through all the code to work out why)
  useEffect(() => {
    return auth.events.addUserUnloaded(() => {
      if (isLoggedIn === true) {
        console.log("sending user signed out message");
        setLoggedIn(false);
      }
    });
  }, [auth.events, isLoggedIn, setLoggedIn]);

  // handle user loaded events (login or events.load). addUserSignIn doesn't seem to ever fire so we
  // can't use that (I havent' dug through all the code to work out why)
  useEffect(() => {
    return auth.events.addUserLoaded(() => {
      if (isLoggedIn === false) {
        console.log("sending user signed in message");
        setLoggedIn(true);
      }
    });
  }, [auth.events, isLoggedIn, setLoggedIn]);

  // handle changes to isLoggedIn, i.e. if login or logout happened in other tab/window
  useEffect(() => {
    if (isLoggedIn === true) {
      console.log("signed in event");
      const user = loadUserFromStorage();
      if (user?.access_token && access_token !== user?.access_token) {
        console.log("Loading updated user");
        auth.events.load(user);
      }
    }

    // In React 18 useEffects runs twice on strict mode.
    // When you are logged in one tab and open new tab, there will be one point when the isLoggedIn is false and user is still authenticated.
    // Hence it was triggering the removeUser which in turn will trigger signed out in all the tabs.
    // So added extra condition to prevent that which is also look for 'oidc.user' storage value
    // If storage still has this value that means we do not want to trigger removeUser()
    if (isLoggedIn === false && auth.isAuthenticated && loadUserFromStorage() === null) {
      console.log("signed out event");
      auth.removeUser();
    }
  }, [isLoggedIn, access_token, auth.isAuthenticated, loadUserFromStorage]);

  // this doesn't render anything, I just needed to hook it in somewhere inside the authcontext and router.
  return null;
}

export default AuthEvents;
