import PageLayout from "../components/PageLayout";
import { Row } from "react-bootstrap";


const Unauthorized = () => {
    return (
        <PageLayout title={"401: Unauthorized"} showHeadingAndBreadcrumb={true} parents="Home">
            <Row className="mx-1">
                You do not have permission to access this page. Contact harrygentle@griffith.edu.au to become a contributor.
            </Row>
        </PageLayout>
    );
};

export default Unauthorized;
