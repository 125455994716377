import {
  Row,
  Form,
  FormControl,
  Nav,
  ListGroup,
  Button,
  ButtonGroup,
  Col,
  Alert,
} from "react-bootstrap";
import PageLayout from "../components/PageLayout";
import { glossaryAPI } from "../api/glossaryAPI";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Spinner } from "../components/Spinner";
import ConfirmationModal from "../components/BiographyEntryForm/confirmationModal";
import AddGlossary from "./AddGlossary";
import { displayToast } from "../components/DisplayToast";

function GlossaryEntries() {
  const [glossary, setGlossary] = useState(null);
  const [page, setPage] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [entriesUrl, setEntriesUrl] = useState(null);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [formData, setFormData] = useState(null);
  const [hasPermission, setHasPermission] = useState(null);

  let { slug } = useParams();

  useEffect(() => {
    const getGlossaryOptions = glossaryAPI.getGlossaryOptions(slug);
    const getGlossaryBySlug = glossaryAPI.getGlossary(slug);

    // Making API calls to get options (to check the permissions) and Glossary data for the given slug
    axios
      .all([getGlossaryOptions, getGlossaryBySlug])
      .then(
        axios.spread((...response) => {
          setHasPermission(
            (response[0]?.data?.actions?.hasOwnProperty("PUT")) ?? false
          );
          setGlossary(response[1]?.data);
          setEntriesUrl(response[1]?.data.entries);
        })
      )
      .catch((errors) => {
        console.log(errors.toString());
        console.error(errors);
      });
  }, [slug]);

  useEffect(() => {
    const params = new URLSearchParams();
    let isMounted = true;
    if (searchTerm && isMounted) {
      params.append("name__icontains", searchTerm);
    }
    if (pageNumber > 1 && isMounted) {
      params.append("page", `${pageNumber}`);
    }
    if (typeof entriesUrl === "string" && isMounted) {
      axios
        .get(entriesUrl, { params })
        .then((result) => {
          setPage(result.data);
        })
        .catch((error) => {
          toast.error(`${error}`);
        });
    }
    return () => (isMounted = false);
  }, [
    entriesUrl,
    setPage,
    pageNumber,
    searchTerm,
    showAddModal,
    showDeleteModal,
  ]);

  const onDeleteGlossaryItem = () => {
    const toastId = toast.loading("Please wait...");
    glossaryAPI
      .delete(formData?.id, slug)
      .then(() => {
        setShowDeleteModal(false);
        setFormData(null);
        displayToast(
          toastId,
          "success",
          1500,
          "Glossary Entry has been deleted successfully."
        );
      })
      .catch((e) => {
        setShowDeleteModal(false);
        setFormData(null);
        displayToast(
          toastId,
          "error",
          false,
          "Error! Something went wrong.",
          e
        );
      });
  };

  return (
    <>
      <PageLayout
        title={glossary !== null ? `Glossary: ${glossary?.display_name}` : ""}
        parents={[{ to: "/glossaries", title: "Glossaries" }]}
        showHeadingAndBreadcrumb={true}
      >
        <>
          <ConfirmationModal
            show={showAddModal}
            scrollable={true}
            modalBody={
              <AddGlossary
                onClose={() => {
                  setShowAddModal(false);
                  setFormData(null);
                }}
                formData={formData}
              />
            }
            onModalClose={null}
            onConfirmed={null}
            modalClassName={"add-stub-entry-modal-dialog"}
          />
          <ConfirmationModal
            cancelButtonText={"Cancel"}
            confirmButtonText={"Delete"}
            modalTitle={"Delete Glossary Entry"}
            show={showDeleteModal}
            scrollable={true}
            modalBody={
              <div>
                Are you sure you want to delete <b>{formData?.name}</b> Glossary
                Entry?
              </div>
            }
            onModalClose={() => {
              setFormData(null);
              setShowDeleteModal(false);
            }}
            onConfirmed={() => onDeleteGlossaryItem()}
            modalClassName={"add-stub-entry-modal-dialog"}
          />

          <Row>
            <Form className="d-flex justify-content-end align-self-center">
              <Row className="d-flex">
                {hasPermission && (
                  <Col xl={6} lg={6} md={6} sm={12} className="mt-2 p-0">
                    <button
                      type="button"
                      className="btn btn-primary glossary-and-place-add-button"
                      onClick={() => setShowAddModal(true)}
                    >
                      + Add Glossary Entry
                    </button>
                  </Col>
                )}
                <Col className="mt-2">
                  {" "}
                  <FormControl
                    type="search"
                    placeholder="Search"
                    aria-label="Search"
                    value={searchTerm}
                    onChange={(e) => {
                      setSearchTerm(e.target.value);
                      setPageNumber(1)
                    }}
                  />
                </Col>
              </Row>
            </Form>
          </Row>
          <Row className="mt-2">
            <ListGroup as="ol">
              {page !== null ? (
                page.results.map((row, index) => {
                  return (
                    <ListGroup.Item
                      key={index}
                      as="li"
                      className="d-flex justify-content-between align-items-start"
                    >
                      <div className="ms-2 me-auto">
                        <div className="fw-bold">{row.name}</div>
                        {row.description}
                      </div>
                      {hasPermission && (
                        <>
                          <div className="glossary-and-place-action-buttons">
                            <button
                              type="button"
                              className="btn"
                              onClick={() => {
                                setFormData(row);
                                setShowAddModal(true);
                              }}
                            >
                              <i className="bi bi-pencil-square"></i>
                            </button>
                          </div>
                          <div className="glossary-and-place-action-buttons">
                            <button
                              type="button"
                              className="btn"
                              onClick={() => {
                                setFormData(row);
                                setShowDeleteModal(true);
                              }}
                            >
                              <i className="bi bi-trash3"></i>
                            </button>
                          </div>
                        </>
                      )}
                    </ListGroup.Item>
                  );
                })
              ) : (
                <div className="d-flex justify-content-center">
                  <Spinner />
                </div>
              )}
            </ListGroup>
          </Row>
          {page?.results.length > 0 && (
            <Row>
              <nav
                className="navbar navbar-light justify-content-between"
                style={{ boxShadow: "0 0 0" }}
              >
                <Nav.Item>
                  Showing Entry {(pageNumber - 1) * 10 + 1} to{" "}
                  {(pageNumber - 1) * 10 + 10} of {page?.count}
                </Nav.Item>
                <Nav.Item>
                  <ButtonGroup aria-label="Basic example">
                    <Button
                      variant="primary"
                      disabled={page?.previous === null}
                      onClick={() => setPageNumber(pageNumber - 1)}
                    >
                      Previous
                    </Button>
                    <Button
                      variant="primary"
                      disabled={page?.next === null}
                      onClick={() => setPageNumber(pageNumber + 1)}
                    >
                      Next
                    </Button>
                  </ButtonGroup>
                </Nav.Item>
              </nav>
            </Row>
          )}
          {page?.results.length === 0 && (
            <Row>
              <Col>
                {!searchTerm && (
                  <Alert className="info-message">
                    There are no entries in the selected Glossary.
                  </Alert>
                )}
                {searchTerm && (
                  <Alert className="info-message">
                    There are no results for <b>{searchTerm}</b>.
                  </Alert>
                )}
              </Col>
            </Row>
          )}
        </>
      </PageLayout>
    </>
  );
}

export default GlossaryEntries;
