import { useContext, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Controller, useFormContext } from "react-hook-form";
import { useNavigate } from "react-router";
import SearchPage from "../../../pages/SearchPage";
import { BiographyStore } from "../../../stateManager/stores/BiographyStore";
import ConfirmationModal from "../confirmationModal";
import { getIdFromUrl } from "../utils/utils";
import CreateBioEntryFormModal from "./CreateBioEntryFormModal";

export const PersonSearch = ({ registerText, placeholder, displayStubEntryFilterCheckbox }) => {
  const { state } = useContext(BiographyStore);
  const { control, setValue, getValues, clearErrors } = useFormContext();
  const [showSearchModal, setShowSearchModal] = useState(false);
  const [selectedPerson, setSelectedPerson] = useState("");
  const valueOfField = getValues(registerText);
  const [showAddModal, setShowAddModal] = useState(false);
  const [saveEntryResponse, setSaveEntryResponse] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (valueOfField) {
      // Clearing errors when there is a valid value
      clearErrors(registerText);
      // Setting selected person value if any coming from DB
      setSelectedPerson(getFormattedValue(valueOfField));
    } else {
      setSelectedPerson("");
      setValue(registerText, null);
    }
  }, [valueOfField, clearErrors, registerText, setValue]);

  // setting selected person and field value when selecting person from search results.
  const onPersonSelected = ({ row }) => {
    setShowSearchModal(false);
    setSelectedPerson(getFormattedValue(row));
    setValue(registerText, row);
  };

  // Formatting the field value
  const getFormattedValue = (value) => {
    if (!value) return;
    return value.first_name ? value.first_name + " " + value.surname : value.surname;
  };

  // Set the newly created person as the selected person
  useEffect(() => {
    if (saveEntryResponse) {
      setSelectedPerson(getFormattedValue(saveEntryResponse));
      setValue(registerText, saveEntryResponse);
    }
  }, [saveEntryResponse, registerText, setValue]);

  return (
    <>
      <ConfirmationModal
        show={showSearchModal}
        modalTitle={"Search Entry"}
        scrollable={true}
        modalBody={
          <SearchPage
            isSearchModal={true}
            hideActionColumn={true}
            hideStateColumn={true}
            onPersonSelected={onPersonSelected}
            showHeadingAndBreadcrumb={false}
            displayStubEntryFilterCheckbox={displayStubEntryFilterCheckbox}
            displayEntryForState={""}
          />
        }
        cancelButtonText={"Close"}
        onModalClose={() => {
          setShowSearchModal(false);
        }}
        onConfirmed={null}
        backdrop={true}
        modalClassName={"search-modal-dialog"}
      />
      <ConfirmationModal
        show={showAddModal}
        scrollable={true}
        modalBody={
          <CreateBioEntryFormModal
            setResponse={(res) => setSaveEntryResponse(res)}
            onClose={() => setShowAddModal(false)}
          />
        }
        onModalClose={null}
        onConfirmed={null}
        modalClassName={"add-stub-entry-modal-dialog"}
      />

      <div className="col-lg-7 col-md-12" id={registerText}>
        <Controller
          control={control}
          name={registerText}
          id={registerText}
          render={({ field: { onChange } }) => (
            <input
              className="form-control"
              placeholder={placeholder}
              disabled
              value={selectedPerson}
              onChange={(v) => {
                onChange(v);
              }}
              type={"text"}
              readOnly={state.isReadOnly}
            />
          )}
        />
      </div>
      <div className="d-grid gap-3 d-lg-flex justify-content-lg-end col-md-12 col-lg col-sm-12">
        <button
          type="button"
          id={"search-select-button"}
          className="btn btn-primary btn-place"
          disabled={state?.isReadOnly}
          onClick={() => {
            setShowAddModal(false);
            setShowSearchModal(true);
          }}
        >
          <i className="bi bi-search p-2"></i>
          {"Search & Select Entry"}
        </button>
        <button
          type="button"
          className="btn btn-primary"
          id={"add-entry-button"}
          disabled={state?.isReadOnly}
          onClick={() => {
            setShowSearchModal(false);
            setShowAddModal(true);
          }}
        >
          {" + Add Entry"}
        </button>
      </div>
      {state.isReadOnly && (
        <div className="m-0 p-0">
          {" "}
          <Button
            id={getIdFromUrl(valueOfField?.url) + "view-entry-link"}
            className="m-0 p-0"
            variant="link"
            size="sm"
            rel="noopener noreferrer"
            onClick={() => navigate(`/view-bio-entry/${getIdFromUrl(valueOfField?.url)}`)}
          >
            <b className="subTitle">View entry:</b>{" "}
            <span className="click-here-link ">{getFormattedValue(valueOfField)}</span>
          </Button>
        </div>
      )}
    </>
  );
};
