import * as yup from "yup";

export const biographyEntryFormSchema = yup.object().shape({
  first_name: yup
    .string()
    .nullable()
    .test("len", "Max 100 characters", (value) => testValueLength(value, 100)),
  middle_name: yup
    .string()
    .nullable()
    .test("len", "Max 100 characters", (value) => testValueLength(value, 100)),
  surname: yup
    .string()
    .nullable()
    .required("Surname is required!")
    .test("len", "Max 100 characters", (val) => val && val.length <= 100),
  gender: yup.string().ensure().required("Gender is required!"),
  language_group: yup
    .string()
    .nullable()
    .test("len", "Max 200 characters", (value) => testValueLength(value, 200)),
  cultural_heritage: yup
    .string()
    .nullable()
    .test("len", "Max 200 characters", (value) => testValueLength(value, 200)),
  religion: yup
    .string()
    .nullable()
    .test("len", "Max 200 characters", (value) => testValueLength(value, 200)),
  parents: yup.array().of(
    yup.object().shape({
      parent: yup
        .mixed()
        .required("The parent selection is required! Please make the appropriate selection or delete the field."),
    })
  ),
  children: yup.array().of(
    yup.object().shape({
      child: yup
        .mixed()
        .required("The child selection is required! Please make the appropriate selection or delete the field."),
    })
  ),
  marriages: yup.array().of(
    yup.object().shape(
      {
        place_of_marriage: yup
          .mixed()
          .nullable(true)
          .when(["notes", "start_date", "end_date", "spouses"], {
            is: (notes, start_date, end_date, spouses) =>
              !notes && !start_date && !end_date && (spouses?.length === 0 || !spouses),
            then: yup.mixed().nullable(true).required("At least one of the fields is required!"),
          }),
        spouses: yup
          .array()
          .nullable(true)
          .when(["notes", "start_date", "end_date", "place_of_marriage"], {
            is: (notes, start_date, end_date, place_of_marriage) =>
              !notes && !start_date && !end_date && !place_of_marriage,
            then: yup.array().of(yup.mixed().nullable(true).required("At least one of the fields is required!")),
          }),
        start_date: yup
          .mixed()
          .nullable(true)
          .when(["place_of_marriage", "notes", "end_date", "spouses"], {
            is: (place_of_marriage, notes, end_date, spouses) =>
              !place_of_marriage && !notes && !end_date && (spouses?.length === 0 || !spouses),
            then: yup.string().nullable(true).required("At least one of the fields is required!"),
          }),
        end_date: yup
          .mixed()
          .nullable(true)
          .when(["place_of_marriage", "notes", "start_date", "spouses"], {
            is: (place_of_marriage, notes, start_date, spouses) =>
              !place_of_marriage && !notes && !start_date && (spouses?.length === 0 || !spouses),
            then: yup.string().nullable(true).required("At least one of the fields is required!"),
          }),
        notes: yup.mixed().when(["place_of_marriage", "start_date", "end_date", "spouses"], {
          is: (place_of_marriage, start_date, end_date, spouses) =>
            !place_of_marriage && !start_date && !end_date && (spouses?.length === 0 || !spouses),
          then: yup.string().required("At least one of the fields is required!"),
        }),
      },
      [
        ["start_date", "end_date"],
        ["start_date", "notes"],
        ["start_date", "place_of_marriage"],
        ["start_date", "spouses"],

        ["end_date", "notes"],
        ["end_date", "place_of_marriage"],
        ["end_date", "spouses"],

        ["notes", "place_of_marriage"],
        ["notes", "spouses"],
        ["place_of_marriage", "spouses"],
      ]
    )
  ),
  property_owned: yup.array().of(
    yup.object().shape({
      name: yup.string().required("Name is required!"),
    })
  ),
  other_names: yup.array().of(
    yup.object().shape({
      name: yup.string().required("Name is required!"),
    })
  ),
  related_organisations: yup.array().of(
    yup.object().shape({
      name: yup.string().required("Name is required!"),
    })
  ),
  occupation: yup.array().of(
    yup.object().shape({
      occupation_name: yup.string().nullable().required("Occupation name is required!"),
    })
  ),
  online_resources: yup.array().of(
    yup.object().shape({
      title: yup.string().required("Title is required!"),
    })
  ),
  newspapers: yup.array().of(
    yup.object().shape({
      newspaper_title: yup.string().required("Newspaper Title is required!"),
    })
  ),
  published_sources: yup.array().of(
    yup.object().shape({
      title: yup.string().required("Title is required!"),
      year: yup.number().nullable().typeError('Year must be a number and is required!').required("Year is required!"),
    })
  ),
  archival_records: yup.array().of(
    yup.object().shape({
      record: yup.string().required("Record name is required!"),
    })
  ),
});

const testValueLength = (value, length) => {
  if (value) return value.length <= length;
  else return true;
};
