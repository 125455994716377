import PageLayout from "../components/PageLayout";
import clearAllButton from "../../src/assets/clearAllButton.png";
import exportSearchResultButton from "../../src/assets/exportSearchResultButton.png";
import filterOptions from "../../src/assets/filterOptions.png";
import hideShowColumnButton from "../../src/assets/hideShowColumnButton.png";
import toggleArrow from "../../src/assets/toggleArrow.png";
import searchPanel from "../../src/assets/searchPanel.png";
import searchPage from "../../src/assets/searchPage.png";
import hideShowColsDropdown from "../../src/assets/hideShowColsDropdown.png";
import viewingEntries from "../../src/assets/viewingEntries.png";
import homePageMenu from "../../src/assets/homePageMenu.png";
import homePageBasicSearch from "../../src/assets/homePageBasicSearch.png";
import mainMenuSearchOption from "../../src/assets/mainMenuSearchOption.png";
import addFilterbuttonSearchPage from "../../src/assets/addFilterbuttonSearchPage.png";
import searchResults from "../../src/assets/searchResults.png";
import clearFieldGuide from "../../src/assets/clearFieldGuide.png";

import "../css/Help.css";
import { Row } from "react-bootstrap";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const PublicHelpPage = () => {
  const location = useLocation();

  useEffect(() => {
    const hash = location.hash;
    if (hash && document.getElementById(hash.substring(1))) {
      document.getElementById(hash.substring(1)).scrollIntoView({ behavior: "smooth" });
    }
  }, [location.hash]);

  return (
    <PageLayout title={"Search Guide"} showHeadingAndBreadcrumb={true} parents="Home">
      <Row className="mx-1">
        <HomePageHelpSection />
        <SearchPageHelpSection />
      </Row>
    </PageLayout>
  );
};

export default PublicHelpPage;

const HomePageHelpSection = () => {
  return (
    <Row className="p-2">
      <h5 className="help-page-headings">HOME PAGE</h5>
      <Row className="mx-1">
        <p>
          The home page has five menu options at the top of the page{" "}
          <span className="highlight-words">Home, Search, Search Guide, FAQs & Sign In</span>. These menu options will
          appear on every page throughout the Dictionary of Biography.
        </p>
        <p className="text-center">
          <img alt="HarryGentle Biography of Dictionary Home Page" src={homePageMenu} style={{ width: "100%", height: "auto" }} />
        </p>

        <p>
          <span className="highlight-words">Home:</span> This menu option will always bring you back to the Biographical Dictionary home page.
        </p>
        <p>
          <span className="highlight-words">Search:</span> This menu option will take you to the the site’s Search page.
        </p>
        <p>
          <span className="highlight-words">FAQs:</span> This menu option will take you to the Frequently Asked Questions page.
        </p>
        <p>
          <span className="highlight-words">Sign In:</span> This menu option is only for site administrators, researchers and registered volunteers.
        </p>
        <h5 className="sub-headings p-1 px-2">BASIC SEARCH</h5>
        <p>
          This simple search function is based solely on a person’s name or part of a name. For instance, typing ‘A’ in the <span className="highlight-words"> Surname </span> field will return all surnames beginning with A; typing ‘Mar’ in the <span className="highlight-words">First Name</span> field will return people named Mary, Maria, Margaret, Martin, and so on.
        </p>
        <p>
          Note: To search for a First Nations or ethnic minority person (e.g., Chinese or Pacific Islander), a married woman (e.g. Ann Jane Ray née Burnside), or a person known by an alias or nickname (e.g., Carbon Bob), read the following section on conducting an advanced search.
        </p>
        <p className="text-center">
          <img alt="HarryGentle Biography of Dictionary Home Page Basic Search" src={homePageBasicSearch} style={{ width: "100%", height: "auto" }} />
        </p>
      </Row>
    </Row>
  );
};

const SearchPageHelpSection = () => {
  return (
    <Row className="p-2" id="search-section">
      <h5 className="help-page-headings">SEARCH PAGE</h5>



      <Row className="mx-1 my-3">
        <h5 className="sub-headings p-1 px-2">NAVIGATION</h5>
        <p>To navigate to the <span className="highlight-words">Search</span> page to conduct an advanced search, select <span className="highlight-words">Search</span> from the menu options at the top of the home page.</p>

        <p className="text-center">
          <img alt="HarryGentle Biography of Dictionary Home Page" src={mainMenuSearchOption} style={{ width: "100%", height: "auto" }} />
        </p>

        <p>When you first navigate to the search page, you will see a page similar to that shown below.</p>

        <p className="text-center">
          <img alt="HarryGentle Biography of Dictionary Search Page" src={searchPage} />
        </p>

        <p>
          Before you search, you can see all current published records in the Biographical Dictionary.
          The default listing on this page is ascending order by surname (A-Z).
          If you prefer to see the entries in descending order by surname (Z-A), place your mouse over the <span className="highlight-words">Surname</span> title,
          where the <span className="highlight-words">Toggle Sortby</span> tooltip will appear.
          Click on the word <span className="highlight-words">Surname</span> and the list will now be sorted in descending order by surname.
          To return to the original order (A-Z), click on the <span className="highlight-words">Surname</span> title again or click the
          <img
            alt="HarryGentle Biography of Dictionary Search Page - Clear All Button"
            src={clearAllButton}
            style={{ marginLeft: 6, marginRight: 6 }}
          />
          button.
        </p>

        <p>
          To download a copy of all entries in the Biographical Dictionary, click on
          <img
            alt="HarryGentle Biography of Dictionary Search Page - Export Results Button"
            src={exportSearchResultButton}
            style={{ marginLeft: 6, marginRight: 6 }}
          />
          A pop-up will let you know when the download is ready, and the system will automatically create a CSV (.csv) file and download it to your local drive.
          Note that a CSV file is best opened in a spreadsheet programme like Excel or Google Sheets.
        </p>






        <h5 className="sub-headings p-1 px-2">CONDUCTING A SEARCH</h5>
        <h5>ADD FILTERS</h5>
        <p>
          In addition to conducting a standard name search, you can click on <img alt="HarryGentle Biography of Dictionary Search Page - Filter options" src={addFilterbuttonSearchPage} style={{ marginLeft: 6, marginRight: 6, width: "10%", height: "auto" }} />, allowing you to search on other attributes in the Biographical Dictionary.
          The attributes you can choose from are:
        </p>
        <p>   <img alt="HarryGentle Biography of Dictionary Search Page - Filter options" src={filterOptions} /></p>
        <p>
          For example, if you run a name search and do not get any results, add the <span className="highlight-words">Other Names</span> filter to search for aliases, nicknames, other surnames (e.g., married surnames) and/or spelling variations (Jefferies, Jeffries, Jeffreys, Gefferies, etc).
        </p>


        <h5>First Nations and ethnic minority names</h5>
        <p>
          During the colonial era, First Nations, Chinese, Pacific Islander and other ethnic minorities were often known by a single name or given anglicised names and nicknames.
          Examples include First Nations people known as Multuggerah, Captain Piper and Queen Beauty, and Chinese people known as Tang and Gentleman John. In these other cases, it may not be apparent from the record which part of a name is the equivalent of a first name or surname (e.g., Ta Ke and Tam Gee Wah).
          Following established protocols, the names of these individuals are entered under <span className="highlight-words">Surname</span>.
        </p>

        <p>This does not apply where an individual was given an anglicised first name in addition to or in place of their native language first name.
          For instance, Tommy Maura and Ernest Tam. </p>

        <p>
          Note: If you cannot find the First Nations or ethnic minority individual you are searching for, use the <span className="highlight-words">Other Names</span> filter as they may have been entered into the Biographical Dictionary under a name or nickname you are not aware of.
        </p>



        <h5>Married women</h5>
        <p>As noted above, married women are entered in the Biographical Dictionary with their maiden (birth) surname under the <span className="highlight-words">Surname</span> attribute.
          Therefore, married surnames usually appear under <span className="highlight-words">Other Names</span>.
          To search for a woman by her maiden surname, enter ‘Mary’ under <span className="highlight-words">First Name</span> and ‘Smith’ under <span className="highlight-words">Surname</span>.
          To search by her married name, enter ‘Mary’ under <span className="highlight-words">First Name</span> and ‘Jones’ under <span className="highlight-words">Other Names.</span> </p>

        <p>An exception occurs when a married woman’s maiden name is not known; then her married name will appear under the <span className="highlight-words">Surname</span> attribute.</p>



        <h5>Dates and date ranges</h5>
        <p>When using <span className="highlight-words">Add Filters</span> to search for dates with an entry, such as date of birth, you can set an exact birth year (e.g., 1890) or insert a range of years (e.g., 1890 to 1895).</p>

        <img alt="HarryGentle Biography of Dictionary Search Page - Search Panel" src={searchPanel} />

        <p className="mt-2">When searching, you can use as many filters as you would like. However, the more filters you use, the more you narrow your search and reduce your search results. </p>
        <p>
          If you use multiple filters, you can hide them — so you have more room on your page to view your search results — by clicking the
          <img
            alt="HarryGentle Biography of Dictionary Search Page - Toggle Arrow"
            src={toggleArrow}
            style={{ marginLeft: 6, marginRight: 6 }}
          />
          button.
        </p>

        <p>To clear the filters at any time, click the   <img
          alt="HarryGentle Biography of Dictionary Search Page - Clear All Button"
          src={clearAllButton}
          style={{ marginLeft: 6, marginRight: 6 }}
        />button or can clear them one at a time by selecting the X next to each filter.
        </p>
        <img
          alt="HarryGentle Biography of Dictionary Search Page - Clear Fields Guide"
          src={clearFieldGuide}
        />



        <h5>HIDE/SHOW COLUMNS</h5>
        <p>
          Once you have your search results, you might like different columns to display on your screen because you are looking for specific information. Click
          <img
            alt="HarryGentle Biography of Dictionary Search Page - Hide Show Column Button"
            src={hideShowColumnButton}
            style={{ marginLeft: 6, marginRight: 6 }}
          />{" "}
          to select (tick) the columns you would like to display and deselect (untick) the columns you would like hidden.
        </p>
        <p> The default columns are already selected (see below) but you can tick and untick any columns in the database by using <span className="highlight-words">Hide/Show Columns</span>.</p>
        <img alt="HarryGentle Biography of Dictionary Search Page - Date Range" src={hideShowColsDropdown} style={{ width: "40%", height: "auto" }} />




        <h5>EXPORT SEARCH RESULTS</h5>
        <p>To download a copy of search results created by using <span className="highlight-words">Add Filters</span> or <span className="highlight-words">Hide/Show Columns</span>, click on
          <img
            alt="HarryGentle Biography of Dictionary Search Page - Export Results Button"
            src={exportSearchResultButton}
            style={{ marginLeft: 6, marginRight: 6 }}
          />. A
          pop-up will let you know when the download is ready and the system will automatically create a CSV (.csv) file and download it to your local drive.
        </p>

        <h5 className="sub-headings p-1 px-2">VIEWING AN INDIVIDUAL’S ENTRY IN THE BIOGRAPHICAL DICTIONARY</h5>
        <p>
          On the <span className="highlight-words">Search</span> page where your search results are displayed, click on an individual’s name (or anywhere on that line) and the complete entry for that individual will be displayed.
        </p>
        <p>Names, locations and links underlined in red are hyperlinked. </p>
        <p>Clicking on a hyperlinked <span className="highlight-words">name</span> will take you to their entry in the Biographical Dictionary. Only individuals born and/or living in Queensland will be hyperlinked. For individuals who emigrated or moved from interstate to Queensland, this will generally mean that the names of their children, grandchildren and other family members born in Queensland will be hyperlinked to their entries. The names of family members who remained overseas or interstate, such as parents and siblings, will not be hyperlinked.</p>
        <p>Clicking on a hyperlinked <span className="highlight-words">location</span> will take you to a list of all records associated with that place. For example, if you were reading the Biographical Dictionary entry of Ellen Ahern, a convict at the Moreton Bay penal station, clicking on her birthplace of Cork, Ireland in the Life Summary section will bring up a list of other people born in Cork.</p>

        <img
          alt="HarryGentle Biography of Dictionary Search Page - Results"
          src={searchResults}
        />

        <p>Similarly, clicking on a <span className="highlight-words">link</span> underlined in red under the sections for <span className="highlight-words">Newspaper Articles, Online Resources</span> and <span className="highlight-words">Archival Records</span> will take you directly to digitised newspaper articles on Trove, to online resources such as birth, marriage and death index entries, and to indexes or digitised records held by repositories including the Queensland and New South Wales state archives. </p>
        <p>Where archival and other records are not underlined in red or the hyperlink is only to an index, not a digitised record, the original record can be viewed at the named repository. Alternatively, digitised copies of many records (including convict and gaol records held by New South Wales State Archives) can be accessed via a genealogy service that may require a subscription (e.g. Ancestry or Find My Past). </p>
        <p>For example, in the following record for Ann Jefferies, a convict at the Moreton Bay penal station from 1833 to 1837, there are hyperlinks to all of the newspaper articles and online resources listed, but of the 11 archival records, only one record is digitised (the Chronological Register of Convicts at Moreton Bay held by Queensland State Archives) and the other two hyperlinks are to the NSW State Archives Convicts Indexes listing the citation details for Ann Jefferies’ certificates of freedom. However, all of the NSW State Archives convict records listed in Jefferies’ entry are digitised and available via genealogy services which may be available via your local library, genealogical society or a personal subscription.</p>
        <img
          alt="HarryGentle Biography of Dictionary Search Page - Viewing an entry"
          src={viewingEntries}
        />
      </Row>
    </Row>
  );
};
