import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

function ErrorHandler() {
  const navigate = useNavigate();

  // Global axios interceptor that handles 500 errors globally
  useEffect(() => {
    axios.interceptors?.response?.use(undefined, (error) => {
      const statusCode = error.response ? error.response.status : null;
      if (statusCode >= 500) {
        navigate("/internal-server-error");
      }
      return Promise.reject(error)
    });
  }, [navigate]);

  return null;
}

export default ErrorHandler;
