import React, { useContext, useEffect, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import UncertainDateController from "../FormComponents/UncertainDateController";
import { updateFormData } from "../../../stateManager/actions/BiographyActions";
import { BiographyStore } from "../../../stateManager/stores/BiographyStore";
import NoDataProvidedMessage from "./NoDataProvidedMessage";
import { ArrayFieldAddButton } from "./ArrayFieldAddButton";
import { ArrayFieldUndoButton } from "./ArrayFieldUndoButton";
import { ArrayFieldDeleteButton } from "./ArrayFieldDeleteButton";
import { ArrayFieldMoveUpDownButtons } from "./ArrayFieldMoveUpDownButtons";
import LabelAndLocationSearch from "../FormComponents/LabelAndLocationSearch";

function PropertyOwnedArray({ classNames, onShowDatesFormatLinkClicked }) {
  const {
    control,
    register,
    getValues,
    setValue,
    reset,
    formState: { errors },
  } = useFormContext();

  const { fields, append, remove, swap } = useFieldArray({
    control,
    name: "property_owned",
  });

  const [displayUndoButton, setDisplayUndoButton] = useState(false);
  const { state, dispatch } = useContext(BiographyStore);
  const propertyOwnedValues = getValues("property_owned");

  useEffect(() => {
    if (JSON.stringify(propertyOwnedValues) === JSON.stringify(state.formData.property_owned)) {
      setDisplayUndoButton(false);
    } else {
      setDisplayUndoButton(true);
    }
  }, [propertyOwnedValues, state]);

  if (state?.formData.property_owned.length === 0 && state?.isReadOnly) {
    return <NoDataProvidedMessage classNames={classNames} />;
  }

  return (
    <div className={"col-12 " + classNames}>
      {fields.map((item, index) => {
        const itemNum = index + 1;

        return (
          <div className="row g-2 field-array-row" key={item.id}>
            <div className="col-md-9 field-column">
              <div className="sub-field-array-style row g-2 mb-3" id={item.id}>
                <p className="subTitle">{`Residence or Property owned ` + itemNum}</p>
                <div className="col-6" id={`property_owned[${index}].name-div`}>
                  <input
                    name={`property_owned[${index}].name`}
                    id={`property_owned[${index}].name`}
                    {...register(`property_owned[${index}].name`)}
                    placeholder="Name*"
                    className="form-control"
                    readOnly={state.isReadOnly}
                  />
                  {errors?.property_owned?.[index]?.name && (
                    <span className="text-danger" data-testid="error-message">
                      {errors?.property_owned[index].name.message}
                    </span>
                  )}
                </div>
                <div className="col-6" id={`property_owned[${index}].description-div`}>
                  <input
                    name={`property_owned[${index}].description`}
                    id={`property_owned[${index}].description`}
                    {...register(`property_owned[${index}].description`)}
                    placeholder="Description"
                    className="form-control"
                    readOnly={state.isReadOnly}
                  />
                </div>
                <div className="col-6" id={`property_owned[${index}].owned_from-div`}>
                  <UncertainDateController
                    name={`property_owned[${index}].owned_from`}
                    error={errors?.property_owned?.[index]?.owned_from?.message}
                    placeholder="Date from"
                    displayDatesInfoLink={true}
                    onShowDatesFormatLinkClicked={onShowDatesFormatLinkClicked}
                  />
                </div>
                <div className="col-6">
                  <UncertainDateController
                    name={`property_owned[${index}].owned_until`}
                    error={errors?.property_owned?.[index]?.owned_until?.message}
                    placeholder="Date until"
                    displayDatesInfoLink={true}
                    onShowDatesFormatLinkClicked={onShowDatesFormatLinkClicked}
                  />
                </div>
                <div className="col-12">
                  <LabelAndLocationSearch
                    displayLabel={false}
                    name={`property_owned[${index}].location_name`}
                    classNames={"col-md-12 mt-2"}
                    placeholder={"Search and select or click + Place to add a new place"}
                    displayAddButton={true}
                    control={control}
                    propertyOwnedLocation={true}
                    getValues={getValues}
                    setValue={setValue}
                  />
                </div>
              </div>
            </div>
            {!state?.isReadOnly && (
              <div className="col-md-3">
                <div className="row flex-row p-0">
                  <ArrayFieldDeleteButton
                    dataTestId={`property-owned-delete-button-${index}`}
                    onClick={() => {
                      updateFormData(getValues(), dispatch);
                      remove(index);
                    }}
                  />
                  <ArrayFieldMoveUpDownButtons
                    onDownButtonClick={() => swap(index, index + 1)}
                    onUpButtonClick={() => swap(index, index - 1)}
                    index={index}
                    fields={fields}
                    upButtonDataTestId={`property-owned-${index}-up`}
                    downButtonDataTestId={`property-owned-${index}-down`}
                  />
                </div>
              </div>
            )}
          </div>
        );
      })}

      {!state?.isReadOnly && (
        <ArrayFieldAddButton
          dataTestId={"property-owned-add-button"}
          onClick={() => {
            append({ name: "", description: "", owned_from: null, owned_until: null, location_name: null });
            updateFormData(getValues(), dispatch);
          }}
        />
      )}
      {displayUndoButton && (
        <ArrayFieldUndoButton
          dataTestId={"property-owned-undo-button"}
          getValues={getValues}
          append={append}
          reset={reset}
        />
      )}
    </div>
  );
}

export default PropertyOwnedArray;
