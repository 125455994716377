import { Tooltip, OverlayTrigger as ReactOverlayTrigger } from "react-bootstrap"


export const OverlayTrigger = ({text, children, placement}) => {
    return (
        <ReactOverlayTrigger
        placement={placement ? placement : "top"}
        overlay={
          <Tooltip id="tooltip-disabled">{text}</Tooltip>
        }
      >
          {children}
          </ReactOverlayTrigger>
    )
}