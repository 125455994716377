import axios from "axios";

export const searchAPI = {
  search,
};

const baseURL = `/api/v1/persons`;

const headerConfig = () => {
  return {
    headers: {
      "Content-Type": "application/json",
    },
  };
};

function search({ currentPage, parameters, bioState }) {
  const headers = headerConfig();

  const paramsConfig = getParamsConfig(parameters, currentPage, bioState).params;

  return axios.get(`${baseURL}`, {
    params: paramsConfig,
    headers: headers,
  });
}

export function getParamsConfig(parameters, currentPage, bioState) {
  const getYear = (from_year, to_year) => {
    if (from_year && to_year) return `${from_year},${to_year}`;
    else if (from_year) return from_year;
  };

  const birthYear = getYear(parameters?.from_birth_year, parameters?.to_birth_year);
  const deathYear = getYear(parameters?.from_death_year, parameters?.to_death_year);
  const yearArrivedAustralia = getYear(parameters?.from_year_arrived_australia, parameters?.to_year_arrived_australia);
  const yearArrivedQld = getYear(parameters?.from_year_arrived_qld, parameters?.to_year_arrived_qld);

  return {
    params: {
      page: currentPage || 1,
      state__icontains: bioState,
      ordering: parameters.ordering?.length === 0 ? "" : parameters.ordering?.join(","),
      ...(parameters?.first_name ? { first_name__iregex: parameters?.first_name } : {}),
      ...(parameters?.middle_name ? { middle_name__iregex: parameters?.middle_name } : {}),
      ...(parameters?.surname ? { surname__iregex: parameters?.surname } : {}),
      ...(parameters?.religion ? { religion__icontains: parameters?.religion } : {}),
      ...(parameters?.cultural_heritage ? { cultural_heritage__icontains: parameters?.cultural_heritage } : {}),
      ...(parameters?.occupation_name ? { occupation__icontains: parameters?.occupation_name } : {}),
      ...(parameters?.other_names ? { other_names__icontains: parameters?.other_names } : {}),
      ...(parameters?.birth_place_id ? { place_of_birth: parameters?.birth_place_id } : {}),
      ...(parameters?.death_place_id ? { place_of_death: parameters?.death_place_id } : {}),
      ...(parameters?.burial_place_id ? { place_of_burial: parameters?.burial_place_id } : {}),
      ...(parameters?.place_of_residence_or_property_owned_id
        ? { residence_or_property_owned: parameters?.place_of_residence_or_property_owned_id }
        : {}),
      ...(parameters?.residence_or_property_owned_name
        ? { property_owned__icontains: parameters?.residence_or_property_owned_name }
        : {}),
      ...(parameters?.ship_arrived_in_australia
        ? { ship_arrived_in_australia__icontains: parameters?.ship_arrived_in_australia }
        : {}),
      ...(parameters?.ship_arrived_in_qld ? { ship_arrived_in_qld__icontains: parameters?.ship_arrived_in_qld } : {}),
      ...(parameters?.indigenous_status ? { indigenous_status: parameters?.indigenous_status } : {}),
      ...(birthYear ? { birth_year: birthYear } : {}),
      ...(deathYear ? { death_year: deathYear } : {}),
      ...(yearArrivedAustralia ? { year_arrived_australia: yearArrivedAustralia } : {}),
      ...(yearArrivedQld ? { year_arrived_qld: yearArrivedQld } : {}),
      ...(parameters?.gender ? { gender__icontains: parameters?.gender } : {}),
    },
  };
}
