import React, { useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Container } from "react-bootstrap";
import BiographyEntryForm from "../components/BiographyEntryForm";
import { personsAPI } from "../api/personsAPI";
import { BiographyStore } from "../stateManager/stores/BiographyStore";
import {
  setPermissionsAction,
  setIdAction,
  setIsLoadingBiographyDataAction,
  setIsReadOnly,
  updateFormData,
  updateInitialFormData,
} from "../stateManager/actions";
import { formatDataToDisplayInForm } from "../components/BiographyEntryForm/utils/Reformat";
import axios from "axios";
import { toast } from "react-toastify";
import { Spinner } from "../components/Spinner";
import { SignInContext } from "../stateManager/stores/SignInContext";

const EditViewBiographyEntryPage = ({ isReadOnly }) => {
  let { id } = useParams();
  const navigate = useNavigate();
  const { state, dispatch } = useContext(BiographyStore);
  const { isApprovedUser } = useContext(SignInContext);

  useEffect(() => {
    setIsLoadingBiographyDataAction(true, dispatch);
    const getPersonOptions = personsAPI.getPersonOptions(id);
    const getBiographyDataById = personsAPI.getById(id, isApprovedUser);
    // Making API calls to get options (to check the permissions) and biography data or the given id
    axios
      .all([getPersonOptions, getBiographyDataById])
      .then(
        axios.spread((...response) => {
          const options = response[0];
          const biographyData = response[1]?.data;
          const hasEditPermission = (options?.data?.actions?.hasOwnProperty("PUT")) ?? false;
          const hasDeletePermission = (options?.data?.delete_action?.hasOwnProperty("DELETE")) ?? false;
          // Formatting response data to display in the form.
          // For example, splitting all children data into children and step children, all parents data into parents and step parents
          const formattedFormData = formatDataToDisplayInForm(biographyData);
          setPermissionsAction(hasEditPermission, hasDeletePermission, dispatch);
          setIsReadOnly(isReadOnly, dispatch);
          updateFormData(formattedFormData, dispatch);
          updateInitialFormData(formattedFormData, dispatch);
          setIdAction(id, dispatch);
          setIsLoadingBiographyDataAction(false, dispatch);
        })
      )
      .catch((errors) => {
        const statusCode = errors.response ? errors.response.status : null;
        if (statusCode === 404) {
          navigate("/notfound");
        } else {
          const toastId = toast.loading("Please wait...");
          toast.update(toastId, {
            render: "Error! Something went wrong",
            type: "error",
            isLoading: false,
            autoClose: true,
            closeButton: true,
            hideProgressBar: false,
          });
        }
        console.log(errors.toString());
        console.error(errors);
      });
  }, [id, dispatch, isReadOnly, isApprovedUser, navigate]);

  return (
    <Container className="CreateBiographyEntryPage my-md-4 px-md-5 my-3 px-2">
      {!state?.isLoading ? <BiographyEntryForm /> : <Spinner />}
    </Container>
  );
};

export default EditViewBiographyEntryPage;
