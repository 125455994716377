import { Alert, Container } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { getOptions } from "../FormComponents/formSelectOptions";
import ReactSelect from "react-select";
import { LabelAndInput } from "../FormComponents/LabelAndInput";
import { placeAPI } from "../../../api/placeAPI";
import { toast } from "react-toastify";
import SearchAndAsyncSelect from "../FormComponents/SearchAndAsyncSelect";
import { useEffect, useState } from "react";
import { getIdFromUrl } from "../utils/utils";
import { displayToast } from "../../DisplayToast";

const defaultValues = {
  title: null,
  description: null,
  place_type: null,
};
const CreatePlace = ({ setResponse, onClose, saveAndCloseButtonText, formData }) => {
  const {
    register,
    control,
    getValues,
    reset,
    setError,
    clearErrors,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: defaultValues,
  });
  const [selectedPlace, setSelectedPlace] = useState(null);
  const options = getOptions("places");
  const selectedValue = (value) => {
    if (value) return options.find((o) => o?.value === value);
    return null;
  };

  // Setting the form values when a place row has been selected and there is formData
  useEffect(() => {
    if (formData) {
      setValue("title", formData?.title);
      setValue("description", formData?.description);
      setValue("place_type", formData.place_type);
      setValue("located_in", formData.located_in);
      setSelectedPlace({
        value: formData.located_in,
        label: formData.located_in_str,
      });
    }
  }, [formData, setValue]);

  const updatePlace = () => {
    let data = getValues();
    validate(data);

    if (data?.title && data?.place_type) {
      const toastId = toast.loading("Please wait...");
      if (!data?.located_in) {
        data = { ...data, located_in: null };
      }
      placeAPI
        .update(getIdFromUrl(formData.url), data)
        .then(() => {
          onClose();
          displayToast(toastId, "success", 1500, "Place has been updated successfully.");
          reset();
        })
        .catch(handleErrors(toastId));
    }
  };

  const createPlace = () => {
    const data = getValues();
    validate(data);

    if (data?.title && data?.place_type) {
      const toastId = toast.loading("Please wait...");
      placeAPI
        .create(data)
        .then((res) => {
          setResponse(res?.data);
          displayToast(toastId, "success", 1500, "Place has been saved successfully.");
          reset();
        })
        .catch(handleErrors(toastId));
    }
  };

  const handleErrors = (toastId) => {
    return (e) => {
      window.scrollTo(0, 0);
      displayToast(toastId, "error", false, "Error! Something went wrong.", e);
      onClose();
    };
  };

  const validate = (data) => {
    if (!data?.title) {
      setError("title", { message: "Surname is required!" });
    } else if (!data?.place_type) {
      setError("place_type", { message: "Type is required!" });
    }
  };

  const onSaveAndSelect = () => {
    const data = getValues();
    validate(data);
    if (data?.title && data?.place_type) {
      createPlace();
      onClose();
    }
  };

  return (
    <Container>
      <div className="col-auto">
        <div className="row mb-3 mt-3">
          <div className="col-md-2">
            <h3 id="place-form-heading">{formData ? "Update Place" : "Add Place"}</h3>
          </div>
          <div className="col-md-10 float-end text-end">
            <button type="button" onClick={onClose} className="btn btn-secondary mx-2" id="cancel-place-button">
              Cancel
            </button>
            {!formData && (
              <>
                {" "}
                <button
                  type="button"
                  onClick={() => onSaveAndSelect()}
                  className="btn btn-primary mx-2"
                  id="save-select-button"
                >
                  {saveAndCloseButtonText ?? "Save & Select"}
                </button>
                <button
                  type="button"
                  onClick={() => createPlace()}
                  className="btn btn-primary mx-2"
                  id="save-add-another-button"
                >
                  {"Save & Add Another"}
                </button>
              </>
            )}
            {formData && (
              <button type="button" onClick={() => updatePlace()} className="btn btn-primary mx-2" id="update-button">
                {"Update"}
              </button>
            )}
          </div>
        </div>
        <hr />
        <Alert className="info-message">
          Try to search the place you want to add in the "Located in" field first to see if it already exists before
          adding a new one to eliminate duplication.
        </Alert>
        <div className="col-md-12 field-array-style mb-4 mt-4">
          <div className="row">
            <LabelAndInput
              labelText="Title"
              classNames={"col-md-6"}
              placeholder={"Enter title"}
              labelClassName={"form-label required"}
              registerText="title"
              required={true}
              register={register}
              error={errors.title?.message}
              onFocus={() => clearErrors("title")}
            />

            <div className={"col-md-6 mb-3"}>
              <label htmlFor={"gender"} className={"form-label required"}>
                Type:
              </label>
              <Controller
                name={"place_type"}
                control={control}
                render={({ field: { value, onChange } }) => (
                  <ReactSelect
                    isClearable
                    aria-label={"type"}
                    placeholder={"Select place type"}
                    value={typeof value === "string" ? selectedValue(value) : selectedValue(value?.url)}
                    name={"place_type"}
                    options={options}
                    onFocus={() => clearErrors("place_type")}
                    onChange={(selectedOption) => {
                      onChange(selectedOption?.value);
                    }}
                  />
                )}
              />
              {errors.place_type && (
                <span className="text-danger" data-testid="error-message">
                  {errors.place_type.message}
                </span>
              )}
            </div>

            <div className={"col-md-12 mt-2 mb-3"}>
              <label htmlFor="labelText" className={"form-label"}>
                Located in:
              </label>
              <SearchAndAsyncSelect
                onselectionchange={(option, onChange) => {
                  onChange(option?.value);
                  setSelectedPlace(option);
                }}
                name={"located_in"}
                place={selectedPlace}
                placeholder={"Select located-in area or a parent region"}
                control={control}
                fieldValue={getValues("located_in")}
              />
            </div>

            <div className={"col-md-12 mt-2 mb-5"}>
              <label htmlFor={"description"} className={"form-label"} id={"description-label"}>
                Description:
              </label>
              <textarea
                id={"description-textarea"}
                aria-label={"description"}
                className="form-control"
                placeholder={""}
                rows="3"
                {...register("description", { required: false })}
                type={"textarea"}
              />
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default CreatePlace;
