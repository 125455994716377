export const resetBiographyData = ({ formData, isSaved, dispatch }) => {
  dispatch({
    type: "RESET_BIOGRAPHY_DATA",
    formData: formData,
    isSaved: isSaved,
  });
};

export const hasAnyAPIErrors = ({
  hasAnyErrors,
  APIErrorMessages,
  APIErrorResponseStatus,
  dispatch,
}) => {
  dispatch({
    type: "HAS_ANY_API_ERRORS_BIOGRAPHY_DATA",
    hasAnyAPIErrors: hasAnyErrors,
    APIErrorResponseStatus: APIErrorResponseStatus,
    APIErrorMessages: APIErrorMessages,
  });
};

// Updated
export const updateFormData = (formData, dispatch) => {
  dispatch({
    type: "UPDATE_FORM_DATA",
    formData,
  });
};

export const updateInitialFormData = (initialFormData, dispatch) => {
  dispatch({
    type: "UPDATE_INITIAL_FORM_DATA",
    initialFormData,
  });
};


export const setIsLoadingBiographyDataAction = (isLoading, dispatch) => {
  dispatch({
    type: "LOADING_BIOGRAPHY_DATA",
    isLoading,
  });
};

export const setPermissionsAction = (hasEditPermission, hasDeletePermission, dispatch) => {
  dispatch({
    type: "SET_PERMISSIONS",
    hasEditPermission,
    hasDeletePermission,
  });
};

export const setIsReadOnly = (isReadOnly, dispatch) => {
  dispatch({
    type: "SET_IS_READ_ONLY",
    isReadOnly,
  });
};

export const resetState = (dispatch) => {
  dispatch({
    type: "RESET_STATE",
  });
};

export const setPossibleTransitionsAction = (possibleTransitions, dispatch) => {
  dispatch({
    type: "SET_POSSIBLE_TRANSITIONS",
    possibleTransitions,
  });
};

export const setIdAction = (id, dispatch) => {
  dispatch({
    type: "SET_ID",
    id,
  });
};
