import React from "react";

export const ErrorAlertFor400 = ({ APIErrorMessages }) => {

    const messages = [];
    const flatten = (obj, flatKey) => {
        Object.entries(obj).forEach(([key, val]) => {
            const keys = Object.keys(APIErrorMessages);
            if (keys.includes(key)) {
                messages.push(key)
            }
            if (typeof (val) == 'object') {
                flatten(obj[key], key)
            } else {
                messages.push(`${flatKey}: ${val}`)
            }
        });
        return messages;
    }

    const errorMessages = flatten(APIErrorMessages);
    const formateMessage = (text) => text.charAt(0).toUpperCase() + text.slice(1)

    return (
        <div className="alert alert-danger alert-dismissible fade show" role="alert">
            <i className="bi bi-exclamation-triangle-fill me-2 flex-shrink-0"></i>
            <span><b>Please fix the following errors:</b></span>
            <div className="d-flex align-items-center">
                <ul className="list-group ms-4">
                    {errorMessages.map((message, index) => {
                        if (Object.keys(APIErrorMessages).includes(message)) {
                            return <li key={index} data-testid={formateMessage(message)}><b>{formateMessage(message)}:</b></li>
                        } else {
                            return <span key={index} data-testid={`message-${index}`} className="ms-4">- {formateMessage(message)}</span>
                        }

                    })}
                </ul>
            </div>
        </div>
    )
}
