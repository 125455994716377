import axios from "axios";

const baseURL = `/api/v1/whoami/whoami`;

const headerConfig = () => {
  return {
    headers: {
      "Content-Type": "application/json",
    },
  };
};

const whoAmI = () => {
  const headers = headerConfig();

  return axios.get(baseURL, headers);
};

export const whoAmIAPI = {
  whoAmI,
};
