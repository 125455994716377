import React, { useContext } from "react";
import { ButtonGroup, Container, Dropdown, DropdownButton } from "react-bootstrap";
import { BiographyStore } from "../../../stateManager/stores/BiographyStore";
import { actions } from "../utils/utils";

function WorkflowActionButtons({ onSubmit, onPublish, onReject, onArchive, onDraft, onMoveToOutOfScope, onRemove }) {
  const { state } = useContext(BiographyStore);
  return (
    <Container>
      <div className="col-auto" id={"workflow-action-button-div"}>
        <ButtonGroup>
          <DropdownButton as={ButtonGroup} title="Workflow Actions" id="workflow-action-button">
            {state.possibleTransitions?.includes(actions.outOfScope) && (
              <Dropdown.Item
                as="button"
                data-testid="top-out-of-scope-button"
                id="top-out-of-scope-button"
                onClick={onMoveToOutOfScope}
              >
                Out of Scope
              </Dropdown.Item>
            )}

            {state.possibleTransitions?.includes(actions.remove) && (
              <Dropdown.Item
                as="button"
                data-testid="top-out-of-scope-button"
                id="top-out-of-scope-button"
                onClick={onRemove}
              >
                Out of Scope
              </Dropdown.Item>
            )}

            {state.possibleTransitions?.includes(actions.requestReview) && (
              <Dropdown.Item as="button" data-testid="top-submit-button" id="top-submit-button" onClick={onSubmit}>
                Submit for Review
              </Dropdown.Item>
            )}

            {state.possibleTransitions?.includes(actions.publish) && (
              <Dropdown.Item as="button" data-testid="top-publish-button" id="top-publish-button" onClick={onPublish}>
                Publish
              </Dropdown.Item>
            )}

            {state.possibleTransitions?.includes(actions.reject) && (
              <Dropdown.Item as="button" data-testid="top-reject-button" id="top-reject-button" onClick={onReject}>
                Reject
              </Dropdown.Item>
            )}

            {state.possibleTransitions?.includes(actions.draft) && (
              <Dropdown.Item as="button" data-testid="top-draft-button" id="top-draft-button" onClick={onDraft}>
                Draft
              </Dropdown.Item>
            )}

            {state.possibleTransitions?.includes(actions.archive) && (
              <Dropdown.Item as="button" data-testid="top-archive-button" id="top-archive-button" onClick={onArchive}>
                Archive
              </Dropdown.Item>
            )}
          </DropdownButton>
        </ButtonGroup>
      </div>
    </Container>
  );
}

export default WorkflowActionButtons;
