import { Controller, useFormContext } from "react-hook-form";
import MDEditor from "@uiw/react-md-editor";
import rehypeSanitize from "rehype-sanitize";
import { BiographyStore } from "../../../stateManager/stores/BiographyStore";
import { useContext } from "react";

export const LabelAndMDEditor = ({ labelText, classNames, registerText, message }) => {
  const { control } = useFormContext();
  const { state } = useContext(BiographyStore);

  return (
    <div className={classNames} id={registerText + "-div"}>
      <div className="row">
        <>
          <label htmlFor={registerText} className="form-label col-md-6" id={"label" + registerText}>
            {labelText}:
          </label>
          {/* For now added hardcoded link  */}
          <small className="col-md-6 float-end text-end" id={"markdown-link"}>
            {`To refer basic markdown syntax `}
            <a href="https://www.markdownguide.org/basic-syntax/" target="_blank" rel="noopener noreferrer">
              <b>Click here</b>
            </a>
          </small>
        </>
      </div>
      <Controller
        control={control}
        name={registerText}
        id={registerText}
        render={({ field: { onChange, value } }) => (
          <MDEditor
            value={value}
            id={registerText}
            hideToolbar={state.isReadOnly}
            preview={state.isReadOnly && "preview"}
            onChange={(v) => onChange(v)}
            previewOptions={{
              rehypePlugins: [[rehypeSanitize]],
            }}
          />
        )}
      />
      <div></div>
      {message && (
        <div>
          <small>
            <i>{message}</i>
          </small>
        </div>
      )}
    </div>
  );
};
