import React from "react";
import { Container, Navbar, Nav } from "react-bootstrap";
import logo from "../assets/griffith_logo.jpg";
import UserMenu from "./UserMenu";
import { LinkContainer } from "react-router-bootstrap"; // Found at https://medium.com/how-to-react/use-react-router-link-with-bootstrap-315a8b88e129
import "../css/Navbar.css";
import griffithLogoBlack from "../assets/Griffith-logo-black.png";

function AppNavbar({ type, customStyleClassName }) {
  if (type === 1) {
    return (
      <Navbar id="Navbar" bg="light" className={`${customStyleClassName}  navbar-light fixed-top bg-white py-3`} expand="lg">
        {
          <NavContainer
            navMenuClassName="nav-1-menu text-black mt-auto"
            griffithLogo={logo}
            navTitleBoxClassName={"nav-title-box"}
            brandLogoClassName={"brand-logo-1"}
          />
        }
      </Navbar>
    );
  }
  return (
    <Navbar id="Navbar" className="nav-bar-2 navbar-light fixed-top" expand="lg">
      {
        <NavContainer
          navMenuClassName="nav-2-menu mt-auto"
          griffithLogo={griffithLogoBlack}
          navTitleBoxClassName={"nav-title-box-2"}
          brandLogoClassName={"brand-logo-2"}
        />
      }
    </Navbar>
  );
}

export default AppNavbar;

const NavContainer = ({
  navMenuClassName,
  griffithLogo,
  navTitleBoxClassName,
  brandLogoClassName,
}) => {
  return (
    <Container>
      <Navbar.Brand href="/" className="d-flex">
        <img
          alt="Griffith Logo"
          src={griffithLogo}
          className={`d-inline-block align-top ${brandLogoClassName}`}
        />
        <div className={`${navTitleBoxClassName} flex-column justify-content-around m-auto`}>
          <div className="nav-title-text">{window?.__RUNTIME_CONFIG__?.app_title ? window.__RUNTIME_CONFIG__.app_title : 'Harry Gentle Resource Centre'}</div>
          <div className="nav-subtitle-text">{window?.__RUNTIME_CONFIG__?.app_sub_title ? window.__RUNTIME_CONFIG__.app_sub_title : 'Dictionary of Biography'}</div>
        </div>
      </Navbar.Brand>

      <Navbar.Toggle aria-controls="top-navbar-nav" className="bg-light navbar-light" />
      <Navbar.Collapse id="top-navbar-nav" className="justify-content-end">
         <Nav>
          <LinkContainer to="/">
            <Nav.Link className={`${navMenuClassName} nav-link-ltr`}>Home</Nav.Link>
          </LinkContainer>
          <hr className={'menu-divider'} />
          <LinkContainer to="/search">
            <Nav.Link className={`${navMenuClassName} nav-link-fade-up`}>Search</Nav.Link>
          </LinkContainer>
          <hr className={'menu-divider'} />
          <LinkContainer to="/help">
            <Nav.Link className={`${navMenuClassName} nav-link-fade-up`}>Search Guide</Nav.Link>
          </LinkContainer>
          <hr className={'menu-divider'} />
          <LinkContainer to="/faq">
            <Nav.Link className={`${navMenuClassName} nav-link-fade-up`}>FAQs</Nav.Link>
          </LinkContainer>
          <hr className={'menu-divider'} />
          <UserMenu navClassName={navMenuClassName} />
        </Nav>
      </Navbar.Collapse>
    </Container>
  );
};
