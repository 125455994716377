import { toast } from "react-toastify";

export const displayToast = (toastId, toastType, autoClose, message, e) => {
  toast.update(toastId, {
    render: message,
    type: toastType,
    isLoading: false,
    autoClose: autoClose,
    closeButton: true,
    hideProgressBar: false,
  });
  e && console.log(e.toString());
};
