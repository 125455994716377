import React from "react";
import SearchPage from "../../pages/SearchPage";
import { biographyState } from "../BiographyEntryForm/utils/utils";

const AdminOutOfScopeEntries = () => {
  return (
    <SearchPage
      displayAdminBreadcrumb={true}
      pageTitle={"Out of Scope Entries"}
      displayEntryForState={biographyState.OUT_OF_SCOPE}
      hideActionColumn={false}
      hideStateColumn={true}
      alertMessage={"There are no Out of Scope Entries."}
    />
  );
};

export default AdminOutOfScopeEntries;
