import { Col, Container, Row, Spinner } from "react-bootstrap";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import "../css/markdown-styles.css";
import videoPlaceholder from "../assets/videoPlaceholder.png";

export const HowToUseDictionary = (content) => {

  return (
    <section className="p-5 how-to-use-dictionary text-black">
      <Container style={{ minHeight: "5vh " }}>
        {!content.howToUseDictionarySectionContent && <Spinner animation="border" variant="light" className="align-center" />}
        {content.howToUseDictionarySectionContent && (
          <Row className="flex-row justify-content-center">
            <h5 className="display-5 home-page-headings text-center">{content.howToUseDictionarySectionContent.title}</h5>
            <Col xl={6} lg={6} md={6} sm={12} className="pt-3 d-flex justify-content-center">
              {" "}
              <ReactMarkdown
                className="reactMarkDownHomePage"
                remarkPlugins={[remarkGfm]}
                skipHtml={true}
                linkTarget="_blank"
              >
                {content.howToUseDictionarySectionContent.content}
              </ReactMarkdown>
            </Col>
            <Col xl={6} lg={6} md={6} sm={12} className="pt-3 d-flex justify-content-center">
              <img
                alt="HarryGentle Biography of Dictionary Home Page"
                src={videoPlaceholder}
                style={{ width: "30%", height: "auto" }}
              />
            </Col>
          </Row>
        )}
      </Container>
    </section>
  );
};
