export const initialFormData = {
    "other_names": [],
    "parents": [],
    "children": [],
    "occupation": [],
    "related_organisations": [],
    "property_owned": [],
    "published_sources": [],
    "online_resources": [],
    "newspapers":[],
    "archival_records": [],
    "marriages": [],
    "first_name": "",
    "middle_name": "",
    "surname": "",
    "gender": null,
    "indigenous_status": false,
    "language_group": "",
    "blanket_distribution_date": null,
    "cultural_heritage": "",
    "date_of_birth": null,
    "date_of_death": null,
    "religion": "",
    "date_arrived_australia": null,
    "date_arrived_qld": null,
    "employer": "test employer (deleted)",
    "user_defined_fields": [],
    "notes": "",
    "state": "draft",
    "familynotes": "",
    "blanket_distribution_centre": null,
    "place_of_birth": null,
    "place_of_death": null,
    "place_of_burial": null,
    "ship_arrived_in_australia": null,
    "ship_arrived_in_qld": null,
}