import React, { useContext, useEffect } from "react";
import { Container } from "react-bootstrap";
import BiographyEntryForm from "../components/BiographyEntryForm";
import { BiographyStore } from "../stateManager/stores/BiographyStore";
import { resetState } from "../stateManager/actions/BiographyActions";

const CreateBiographyEntryPage = () => {
  const { dispatch } = useContext(BiographyStore);

  // Reset the state when creating new bio entry - needs to do this when you switch from edit/view form to new entry form
  useEffect(() => {
    resetState(dispatch);
  }, [dispatch]);

  return (
    <Container className="CreateBiographyEntryPage my-md-4 px-md-5 my-3 px-2">
      <BiographyEntryForm />
    </Container>
  );
};

export default CreateBiographyEntryPage;
