import { useContext } from "react";
import { Nav, NavDropdown, Dropdown, NavLink } from "react-bootstrap";
import { useAuth } from "react-oidc-context";
import { LinkContainer } from "react-router-bootstrap"; // Found at https://medium.com/how-to-react/use-react-router-link-with-bootstrap-315a8b88e129
import { SignInContext } from "../stateManager/stores/SignInContext";

function UserMenu({ navClassName }) {
  const auth = useAuth();
  const { isApprovedUser } = useContext(SignInContext);

  if (!auth.isAuthenticated) {
    return (
      <Nav.Link
        className={navClassName ? navClassName : "text-black nav-1-menu mt-auto"}
        onClick={() => {
          auth.signinRedirect();
        }}
      >
        Sign In
      </Nav.Link>
    );
  }

  const signOutRedirect = () =>
    auth
      .revokeTokens(["access_token", "refresh_token"])
      .then(() => {
        console.log("tokens revoked");

        return auth.removeUser();
      })
      .catch((_err) => Promise.reject("Logout Error: err"));

  if (auth.isAuthenticated && isApprovedUser) {
    return (
      <>
        {" "}
        <Dropdown className="mt-auto">
          <Dropdown.Toggle
            id="user-dropdown"
            as={NavLink}
            className={navClassName ? navClassName : "text-black nav-1-menu"}
          >
            {auth.user?.profile.given_name ||
              auth.user?.profile.name ||
              auth.user?.profile.preferred_username ||
              auth.user?.profile.email}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <LinkContainer to="/my-biographies-entries">
              <NavDropdown.Item>Manage Entries</NavDropdown.Item>
            </LinkContainer>
            <LinkContainer to="/create-bio-entry">
              <NavDropdown.Item>Create Entry</NavDropdown.Item>
            </LinkContainer>
            <NavDropdown.Divider />
            <LinkContainer to="/admin">
              <NavDropdown.Item>Dashboard</NavDropdown.Item>
            </LinkContainer>
            <LinkContainer to="/glossaries">
              <NavDropdown.Item>Glossaries</NavDropdown.Item>
            </LinkContainer>
            <LinkContainer to="/places">
              <NavDropdown.Item>Places</NavDropdown.Item>
            </LinkContainer>
            <NavDropdown.Divider />
            <LinkContainer to="/administrative-help">
              <NavDropdown.Item>Administrative Help</NavDropdown.Item>
            </LinkContainer>
            <NavDropdown.Divider />
            <Dropdown.Item onClick={() => signOutRedirect()}> Sign out</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </>
    );
  }

  if (auth.isAuthenticated && !isApprovedUser) {
    return (
      <>
        {" "}
        <Dropdown className="mt-auto">
          <Dropdown.Toggle
            id="user-dropdown"
            as={NavLink}
            className={navClassName ? navClassName : "text-black nav-1-menu"}
          >
            {auth.user?.profile.given_name ||
              auth.user?.profile.name ||
              auth.user?.profile.preferred_username ||
              auth.user?.profile.email}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item onClick={() => signOutRedirect()}> Sign out</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </>
    );
  }
}

export default UserMenu;
