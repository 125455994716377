export const ArrayFieldAddButton = ({ onClick, dataTestId }) => {
  return (
    <button
      id={dataTestId}
      type="button"
      data-testid={dataTestId}
      onClick={() => onClick()}
      className="btn btn-primary"
    >
      + ADD
    </button>
  );
};
