import React from "react";
import SearchPage from "./SearchPage";

const ManageBiographyEntriesPage = () => {
  return (
    <SearchPage
      pageTitle={"Manage Entries"}
      displayEntryForState={""}
      hideActionColumn={false}
      hideStateColumn={false}
      displayAdminBreadcrumb={true}
      alertMessage={"There are no Draft Entries."}
    />
  );
};

export default ManageBiographyEntriesPage;