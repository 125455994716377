import React from "react";
import UncertainDateController from "./UncertainDateController";

function LabelAndDateInput({ labelText, registerText, classNames, error, onShowDatesInfoIconClicked }) {
  return (
    <div className={classNames}>
      <label htmlFor={registerText} className="form-label" id={registerText}>
        {labelText}:
      </label>
      <button
        id={registerText + "-info-button"}
        className="date-info-button"
        type="button"
        onClick={onShowDatesInfoIconClicked}
      >
        <i className="bi bi-info-circle-fill"></i>
      </button>

      <UncertainDateController name={registerText} error={error} displayDatesInfoLink={false} />
    </div>
  );
}

export default LabelAndDateInput;
