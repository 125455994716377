import React, { useContext, useEffect, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { updateFormData } from "../../../stateManager/actions/BiographyActions";
import { BiographyStore } from "../../../stateManager/stores/BiographyStore";
import { ArrayFieldAddButton } from "./ArrayFieldAddButton";
import { ArrayFieldDeleteButton } from "./ArrayFieldDeleteButton";
import { ArrayFieldMoveUpDownButtons } from "./ArrayFieldMoveUpDownButtons";
import { ArrayFieldUndoButton } from "./ArrayFieldUndoButton";
import NoDataProvidedMessage from "./NoDataProvidedMessage";

function OtherNamesArray({ classNames }) {
  const {
    control,
    register,
    getValues,
    reset,
    formState: { errors },
  } = useFormContext();

  const { fields, append, remove, swap } = useFieldArray({
    control,
    name: "other_names",
  });
  const [displayUndoButton, setDisplayUndoButton] = useState(false);
  const { state, dispatch } = useContext(BiographyStore);
  const otherNamesValues = getValues("other_names");

  useEffect(() => {
    if (JSON.stringify(otherNamesValues) === JSON.stringify(state.formData.other_names)) {
      setDisplayUndoButton(false);
    } else {
      setDisplayUndoButton(true);
    }
  }, [otherNamesValues, state]);

  const OtherNamesFieldArrayLabel = () => {
    return (
      <label htmlFor="other_names" className="form-label">
        Other Names:
      </label>
    );
  };

  if (state?.formData.other_names.length === 0 && state?.isReadOnly) {
    return (
      <div className={"col-12 " + classNames}>
        <OtherNamesFieldArrayLabel />
        <NoDataProvidedMessage />
      </div>
    );
  }

  return (
    <div className={"col-12 " + classNames}>
      <OtherNamesFieldArrayLabel />

      <div>
        {fields.map((item, index) => {
          const itemNum = index + 1;

          return (
            <div className="row g-2 field-array-row" key={item.id}>
              <div className="col-md-9 field-column">
                <div className="sub-field-array-style row g-2 mb-3">
                  <p className="subTitle">{`Other name ` + itemNum}</p>
                  <div className="col-6">
                    <input
                      id={`other_names[${index}].name`}
                      name={`other_names[${index}].name`}
                      {...register(`other_names[${index}].name`)}
                      placeholder="Name*"
                      data-testid={`other_names[${index}].name`}
                      className="form-control"
                      readOnly={state.isReadOnly}
                    />
                    {errors?.other_names?.[index]?.name && (
                      <span className="text-danger" data-testid="error-message">
                        {errors?.other_names[index].name.message}
                      </span>
                    )}
                  </div>
                  <div className="col-12">
                    <input
                      id={`other_names[${index}].description`}
                      name={`other_names[${index}].description`}
                      {...register(`other_names[${index}].description`)}
                      placeholder="Description"
                      data-testid={`other_names[${index}].description`}
                      className="form-control"
                      readOnly={state.isReadOnly}
                    />
                  </div>
                </div>
              </div>
              {!state?.isReadOnly && (
                <div className="col-md-3">
                  <div className="row flex-row p-0">
                    <ArrayFieldDeleteButton
                      onClick={() => {
                        updateFormData(getValues(), dispatch);
                        remove(index);
                      }}
                      dataTestId={`other-names-delete-button-${index}`}
                    />
                    <ArrayFieldMoveUpDownButtons
                      onDownButtonClick={() => swap(index, index + 1)}
                      onUpButtonClick={() => swap(index, index - 1)}
                      index={index}
                      fields={fields}
                      upButtonDataTestId={`other_names[${index}].up`}
                      downButtonDataTestId={`other_names[${index}].down`}
                    />
                  </div>
                </div>
              )}
            </div>
          );
        })}

        {!state?.isReadOnly && (
          <ArrayFieldAddButton
            onClick={() => {
              append({ name: "", description: "" });
              updateFormData(getValues(), dispatch);
            }}
            dataTestId={"other-names-add-button"}
          />
        )}
        {displayUndoButton && (
          <ArrayFieldUndoButton
            getValues={getValues}
            dataTestId={"other-names-undo-button"}
            append={append}
            reset={reset}
          />
        )}
      </div>
    </div>
  );
}

export default OtherNamesArray;
