import React from "react";
import "../scss/timeline.scss";
import { Container } from "react-bootstrap";
import { FormatToDate } from "../components/BiographyEntryForm/utils/Reformat";
import { Link } from "react-router-dom";
import { getIdFromUrl } from "./BiographyEntryForm/utils/utils";

class Event {
  constructor(date, title, text, id) {
    this.date = typeof date === "string" ? date : formatDate();
    this.raw_date = date;
    this.title = title;
    this.text = text;
    this.id = id;

    function formatDate() {
      return date ? FormatToDate(date) : "Unknown Date";
    }
  }

  key() {
    return this.date + this.title;
  }
}

// compare two events for the purpose of sorting events chronologically
// birth and death will always come first and last respectively, all
// other events will be sorted by date, using the lower bound for any
// incomplete date.
const compareEvents = (a, b) => {
  if (a.title === "Birth" || b.title === "Death") {
    return -1;
  } else if (a.title === "Death" || b.title === "Birth") {
    return 1;
  }
  const [a_year, b_year] = [a.raw_date.year, b.raw_date.year];
  const [a_month, b_month] = [a.raw_date.month || 1, b.raw_date.month || 1];
  const [a_day, b_day] = [a.raw_date.day || 1, b.raw_date.day || 1];
  if (a_year !== b_year) {
    return a_year - b_year;
  }
  if (a_month !== b_month) {
    return a_month - b_month;
  }
  if (a_day !== b_day) {
    return a_day - b_day;
  }
  return 0;
};

// the list of spouses contains both partners. this function will return the
// partner that is not described by "entry"
const getSpouse = (entry, spouses) => {
  for (let spouse of spouses) {
    if (spouse.url !== entry.url) {
      return spouse;
    }
  }
  return null;
};

const Timeline = (props) => {
  const events = [];

  // birth event
  events[events.length] = new Event(
    props.entry?.date_of_birth,
    "Birth",
    props.entry?.place_of_birth?.full_title || "Unknown Place",
    getIdFromUrl(props.entry?.place_of_birth?.url)
  );

  for (let marriage of props.entry.marriages) {
    if (marriage?.start_date?.year) {
      const spouse = getSpouse(props.entry, marriage.spouses);
      const spouseName = spouse ? `${spouse.first_name || "[Unknown]"} ${spouse.surname}` : "[Unknown]";
      events[events.length] = new Event(
        marriage.start_date,
        `Marriage to ${spouseName}`,
        marriage.place_of_marriage?.full_title || ""
      );
    }
  }

  events[events.length] = new Event(
    props.entry?.date_of_death,
    "Death",
    props.entry?.place_of_death?.full_title || "Unknown Place",
    getIdFromUrl(props.entry?.place_of_death?.url)
  );

  events.sort(compareEvents);

  return (
    <>
      <Container className="timeline-container">
        {events.map((event) => (
          <div className="timeline-item" date-is={event.date} key={event.key()}>
            <strong>{event.title}</strong>
            {event.title !== "Birth" && event.title !== "Death" && <p>{event.text}</p>}
            {event.title === "Birth" && event.text !== "Unknown Place" && (
              <Link to={`/search?birth_place=${event?.text}&birth_place_id=${getIdFromUrl(event?.id)}&page=1`}>
                <p className="timeline-link">{event.text}</p>
              </Link>
            )}
            {event.title === "Birth" && event.text === "Unknown Place" && <p>{event.text}</p>}
            {event.title === "Death" && event.text !== "Unknown Place" && (
              <Link to={`/search?death_place=${event?.text}&death_place_id=${getIdFromUrl(event?.id)}&page=1`}>
                <p className="timeline-link">{event.text}</p>
              </Link>
            )}
            {event.title === "Death" && event.text === "Unknown Place" && <p>{event.text}</p>}
          </div>
        ))}
      </Container>
    </>
  );
};

export default Timeline;
