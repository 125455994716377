import PageLayout from "../components/PageLayout";
import { Row } from "react-bootstrap";


const NotFound = () => {
    return (
        <PageLayout title={"404: Not Found"} showHeadingAndBreadcrumb={true} parents="Home">
            <Row className="mx-1">
                We're sorry but this page appears to be lost to history. 
            </Row>
        </PageLayout>
    );
};

export default NotFound;
