import React, { useContext } from "react";
import { ButtonGroup, Container, Dropdown, DropdownButton } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {
  resetBiographyData,
  resetState,
  setIsLoadingBiographyDataAction,
} from "../../../stateManager/actions/BiographyActions";
import { BiographyStore } from "../../../stateManager/stores/BiographyStore";
import { initialFormData } from "./initialFormData";

function FormActionButtons({ methods, onDelete, onSave }) { 
  const { state, dispatch } = useContext(BiographyStore);
  const navigate = useNavigate();
  const {
    formState: { errors },
  } = useFormContext();

  return (
    <Container>
      <div className="col-auto" id={"form-action-buttons-div"}>
        <ButtonGroup>
          <DropdownButton as={ButtonGroup} title="Form Actions" id="form-action-buttons">
            {/* Should always be there */}
            <Dropdown.Item
              as="button"
              id="top-cancel-button"
              data-testid="top-cancel-button"
              onClick={() => navigate("/my-biographies-entries")}
            >
              Cancel
            </Dropdown.Item>

            {/* Should be there if there an state.id and user has access to perform the action */}
            {state.id && state?.hasDeletePermission && (
              <Dropdown.Item as="button" id="top-delete-button" data-testid="top-delete-button" onClick={onDelete}>
                Delete
              </Dropdown.Item>
            )}

            {/* Should only display Edit button if the form is in readOnly mode, 
            so that user can switch from View/Review mode to Edit mode,
            and if the user has access */}
            {state.isReadOnly && state.hasEditPermission && (
              <Dropdown.Item
                id="top-edit-button"
                as="button"
                onClick={() => navigate(`/edit-bio-entry/${state.id}`)}
              >
                Edit
              </Dropdown.Item>
            )}

            {/* Reset, Review, Update and Save button should be there when it's either in edit or create mode, 
            i.e. when it's not readOnly mode or Review  */}
            {!state?.isReadOnly && (
              <>
                {/* Display Review button if there is an state.id ie. form is in Edit mode, 
                so that user can switch from Edit to View/Review mode. */}
                {state.id && (
                  <Dropdown.Item
                    as="button"
                    id="top-review-button"
                    onClick={() => navigate(`/view-bio-entry/${state.id}`)}
                  >
                    Review
                  </Dropdown.Item>
                )}

                {/* Display Reset button for both create and edit mode */}
                <Dropdown.Item
                  as="button"
                  data-testid="top-reset-button"
                  id="top-reset-button"
                  onClick={() => {
                    if (state.id) {
                      setIsLoadingBiographyDataAction(true, dispatch);
                      methods.reset(state?.initialFormData);
                      resetBiographyData({
                        formData: state?.initialFormData,
                        isSaved: false,
                        dispatch: dispatch,
                      });
                      setIsLoadingBiographyDataAction(false, dispatch);
                    } else {
                      resetState(dispatch);
                      methods.reset(initialFormData);
                    }
                  }}
                >
                  Reset
                </Dropdown.Item>

                {/* Display when there is an state.id i.e the form is in edit mode */}
                {state.id && state.hasEditPermission && (
                  <Dropdown.Item
                    as="button"
                    disabled={Object.keys(errors || {}).length}
                    data-testid="top-update-button"
                    id="top-update-button"
                    onClick={onSave}
                  >
                    Update
                  </Dropdown.Item>
                )}

                {/* Display when there isn't state.id i.e. in create mode */}
                {!state.id && (
                  <Dropdown.Item
                    as="button"
                    disabled={Object.keys(errors || {}).length}
                    data-testid="top-save-button"
                    id="top-save-button"
                    onClick={onSave}
                  >
                    Save
                  </Dropdown.Item>
                )}
              </>
            )}
          </DropdownButton>
        </ButtonGroup>
      </div>
    </Container>
  );
}

export default FormActionButtons;
