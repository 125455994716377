import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import AsyncSelect from "react-select/async";
import "../../../scss/biography-entry-form.scss";
import { placeAPI } from "../../../api/placeAPI";

function SearchAndAsyncSelect({
  name,
  control,
  placeholder,
  place,
  onselectionchange,
  isReadOnly,
  styles,
  fieldValue,
  onFocus,
}) {
  const [inputValue, setInputValue] = useState("");
  const [responseData, setResponseData] = useState([]);
  const [selectedPlace, setSelectedPlace] = useState(null);

  // Update the selectedPlace when place gets updated. Place holds DB values of the location fields if any.
  useEffect(() => {
    place?.value ? setSelectedPlace(place) : setSelectedPlace(null);
  }, [place]);

  // look for fieldValue and if it changes to null when resetting the form then update the selectedPlace too
  useEffect(() => {
    if (!fieldValue) setSelectedPlace(null);
  }, [fieldValue]);

  // Maps response data into options list and returns that list
  const mapOptions = (data) => {
    return data?.map((element) => {
      const value = element.url;
      const label = element.full_title;
      return { value, label };
    });
  };

  // API call to search glossaries based on given name
  const searchPlaces = (title) => {
    placeAPI
      .getPlaceByTitle({ title })
      .then((response) => {
        const data = response?.data?.results;

        setResponseData(data);
      })
      .catch((e) => {
        console.log("error", e);
      });
  };

  // Search glossaries based on input value. Set and return new input value
  const handleInputChange = (newValue) => {
    const newInputValue = newValue.replace(/\W/g, "");
    setInputValue(newInputValue);
    searchPlaces(inputValue);
    return inputValue;
  };

  // AsyncSelect loadOptions prop requires to pass a function that returns a promise,
  // which is the set of options to be used once the promise resolves.
  const loadOptions = async () => {
    // return null if user hasn't entered anything or inputValue is undefined or null
    if (!inputValue) {
      return null;
    }

    // Returned mapped options if found match glossary entries
    return mapOptions(responseData);
  };

  return (
    <>
      <div id={name}>
        <Controller
          name={name}
          control={control}
          render={({ field: { onChange, ref } }) => (
            <AsyncSelect
              onFocus={onFocus}
              styles={styles}
              onChange={(option) => {
                onselectionchange(option, onChange);
              }}
              inputRef={ref}
              value={selectedPlace}
              id={name}
              isDisabled={isReadOnly}
              placeholder={placeholder ? placeholder : "Search and select or click + Place to add a new place"}
              loadOptions={loadOptions}
              onInputChange={handleInputChange}
              isClearable
              cacheOptions={false}
              getOptionValue={(e) => e.value}
            />
          )}
        />
      </div>
    </>
  );
}

export default SearchAndAsyncSelect;
