import PageLayout from "../components/PageLayout";

import "../css/FAQs.css";
import { Accordion, Alert, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import { BiographyFormAccordionItem } from "../components/BiographyEntryForm/FormComponents/BiographyFormAccordionItem";
import { faqAPI } from "../api/faqAPI";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { Link } from "react-router-dom";
import "../css/markdown-styles.css";

// Modify output component for links to render Router Link components for internal links and html a tags otherwise. 
const RouterLink = ({ node, href, children, ...props }) => {
    return (
        href.match(/^\//)
            ? <Link to={href}>{children}</Link>
            : <a href={href}>{children}</a>
    );
}


const FAQPage = () => {
    const [responseData, setResponseData] = useState(null);

    useEffect(() => {
        faqAPI
            .getFAQs()
            .then((response) => {
                setResponseData(response.data.results);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    return (
        <PageLayout title={"Frequently Asked Questions"} showHeadingAndBreadcrumb={true} parents="Home">
            <Row className="mx-1">
                {responseData && responseData?.length === 0 && (
                    <>
                        {" "}
                        <Alert className="info-message">No FAQs found.</Alert>
                    </>
                )}
                <Accordion defaultActiveKey={["1"]} alwaysOpen className="faq-page-accordion">
                    {responseData &&
                        responseData?.length !== 0 &&
                        responseData.map((data, index) => (
                            <BiographyFormAccordionItem
                                eventKey={index + 1}
                                key={index}
                                header={
                                    <>
                                        {" "}
                                        <p>{data.question}</p>
                                    </>
                                }
                            >
                                <ReactMarkdown className="reactMarkDownHomePage" remarkPlugins={[remarkGfm]} skipHtml={true} components={{ a: RouterLink }}>
                                    {data.answer}
                                </ReactMarkdown>
                            </BiographyFormAccordionItem>
                        ))}
                </Accordion>
            </Row>
        </PageLayout>
    );
};

export default FAQPage;
