import React from "react";
import SearchPage from "../../pages/SearchPage";
import { biographyState } from "../BiographyEntryForm/utils/utils";

const AdminPendingApproval = () => {
  return (
    <SearchPage
      displayAdminBreadcrumb={true}
      pageTitle={"Pending Reviews"}
      hideActionColumn={false}
      hideStateColumn={true}
      displayEntryForState={biographyState.PENDING_REVIEW}
      alertMessage={"There are no Pending Reviews."}
    />
  );
};

export default AdminPendingApproval;
