import { Row, Col, Button, Badge, OverlayTrigger, Tooltip, Accordion } from "react-bootstrap";
import axios from "axios";
import React, { useState } from "react";
import { useParams, Link, useLocation, useNavigate } from "react-router-dom";
import { FormatToDate } from "../components/BiographyEntryForm/utils/Reformat";
import { Spinner } from "../components/Spinner";

import PageLayout from "../components/PageLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBook,
  faBookBookmark,
  faUser,
  faToolbox,
  faPeopleGroup,
  faHouse,
  faSignature,
  faSailboat,
  faNewspaper,
  faBoxArchive,
  faArrowUpRightFromSquare,
} from "@fortawesome/free-solid-svg-icons";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import Cite from "citation-js";
import DOMPurify from "dompurify";

import Timeline from "../components/Timeline";

import "../css/BiographyBox.css";
import "../css/markdown-styles.css";
import { getIdFromUrl } from "../components/BiographyEntryForm/utils/utils";
import { BiographyFormAccordionItem } from "../components/BiographyEntryForm/FormComponents/BiographyFormAccordionItem";
import { personsAPI } from "../api/personsAPI";
import ContactUs from "../components/ContactUs";

const noFamilyInfoProvided = (entry) => {
  return !(entry.marriages.length >= 1 || entry.parents.length >= 1 || entry.children.length >= 1);
};

const noArrivalInfoProvided = (entry) => {
  return !(
    entry.date_arrived_australia?.year ||
    entry.ship_arrived_in_australia ||
    entry.date_arrived_qld?.year ||
    entry.ship_arrived_in_qld
  );
};

const noOccupationInfoProvided = (entry) => {
  return !(entry?.occupation && entry.occupation.length >= 1);
};

const noResidenceInfoProvided = (entry) => {
  return !(entry?.property_owned && entry.property_owned.length >= 1);
};

const noNewspaperInfoProvided = (entry) => {
  return !(entry?.newspapers && entry.newspapers.length >= 1);
};

const noOnlineResourcesProvided = (entry) => {
  return !(entry?.online_resources && entry.online_resources.length >= 1);
};

const noArchivalInfoProvided = (entry) => {
  return !(entry?.archival_records && entry.archival_records.length >= 1);
};

const noBibliographyProvided = (entry) => {
  return !(entry?.published_sources && entry.published_sources.length >= 1);
};

const formatPendingIfNotPublished = (state, title) => {
  if (state === "published" || state === "out_of_scope") {
    return title;
  }
  return (
    <OverlayTrigger
      placement={"right"}
      overlay={<Tooltip>This entry is unconfirmed while it is going through our review process.</Tooltip>}
    >
      <i>
        ({title}){" "}
        <sup>
          <Badge pill bg="dark">
            Pending Review
          </Badge>
        </sup>
      </i>
    </OverlayTrigger>
  );
};

const EventRow = ({ title, subtitle, description, dates }) => (
  <div className="d-flex flex-column flex-md-row justify-content-between">
    <div className="flex-grow-1">
      <h6 className="mb-0 fieldtitle">{title}</h6>
      <div className="subheading mb-1">{subtitle || ""}</div>
      <div className="event-description">{description}</div>
    </div>
    <div className="flex-shrink-0 fieldvalue">
      <span>{dates}</span>
    </div>
  </div>
);

const NamesCard = ({ entry }) => {
  const other_names = [];
  if (Array.isArray(entry?.other_names)) {
    for (let name of entry.other_names) {
      other_names[other_names.length] = name.name;
    }
  }
  return (
    <Row className="">
      <Col lg={4} md={4} xs={12}>
        <div className="fieldtitle">First Name</div>
        {entry.first_name || "-"}
      </Col>
      <Col lg={4} md={4} xs={12}>
        <div className="fieldtitle">Middle Name</div>
        {entry.middle_name || "-"}
      </Col>
      <Col lg={4} md={4} xs={12}>
        <div className="fieldtitle">Surname</div>
        {entry.surname || "-"}
      </Col>
      {other_names.length >= 1 && (
        <Col lg={12} md={12} xs={12}>
          <div className="fieldtitle">Other Names</div>
          {other_names.join(", ")}
        </Col>
      )}
    </Row>
  );
};

const LifeSummary = ({ entry }) => {
  return (
    <Row>
      <Col lg={4} md={4} xs={12}>
        <div className="fieldtitle">Birth</div>* {FormatToDate(entry?.date_of_birth)}
        <br />
        <Link
          to={`/search?birth_place=${entry?.place_of_birth?.full_title}&birth_place_id=${getIdFromUrl(
            entry?.place_of_birth?.url
          )}&page=1`}
        >
          {entry?.place_of_birth?.full_title ? entry?.place_of_birth?.full_title : null}
        </Link>
      </Col>
      <Col lg={4} md={4} xs={12}>
        <div className="fieldtitle">Death</div>
        &dagger; {FormatToDate(entry?.date_of_death)}
        <br />
        <Link
          to={`/search?death_place=${entry?.place_of_death?.full_title}&death_place_id=${getIdFromUrl(
            entry?.place_of_death?.url
          )}&page=1`}
        >
          {entry?.place_of_death?.full_title ? entry?.place_of_death?.full_title : null}
        </Link>
      </Col>

      <Col lg={4} md={4} xs={12}>
        <div className="fieldtitle">Religion</div>
        {entry?.religion || "Unknown"}
      </Col>

      <Col lg={4} md={4} xs={12}>
        <div className="fieldtitle">First Nations Person</div>
        {entry.indigenous_status ? "Yes" : "No"}
      </Col>

      <Col lg={4} md={4} xs={12}>
        <div className="fieldtitle">Cultural Heritage</div>
        {entry?.cultural_heritage || "Unknown"}
      </Col>

      <Col lg={4} md={4} xs={12}>
        <div className="fieldtitle">Occupations</div>
        {entry?.occupation && entry.occupation.length > 0
          ? entry.occupation.map((occupation, i) => (
              <React.Fragment key={`${occupation.occupation_name}-${i}`}>
                {occupation.occupation_name}
                <br />
              </React.Fragment>
            ))
          : "Unknown"}
      </Col>
    </Row>
  );
};

const TravelCard = ({ entry }) => {
  // we will never show this card if the person is a First Nations person.
  if (entry.indigenous_status) {
    return null;
  }
  // or if the person has neither of the travel categories filled.
  if (noArrivalInfoProvided(entry)) {
    return null;
  }

  return (
    <Row>
      <Col>
        <EventRow
          title="First Arrival in Australia"
          subtitle={
            entry.ship_arrived_in_australia ? ` on the '${entry.ship_arrived_in_australia}'` : " (ship unknown)"
          }
          description=""
          dates={FormatToDate(entry?.date_arrived_australia, "Unknown Date")}
        />
        <EventRow
          title="First Arrival in Queensland"
          subtitle={entry.ship_arrived_in_qld ? ` on the '${entry.ship_arrived_in_qld}'` : " (ship unknown)"}
          description=""
          dates={FormatToDate(entry?.date_arrived_qld, "Unknown Date")}
        />
      </Col>
    </Row>
  );
};

const NotesCard = ({ entry }) => {
  if (!entry?.familynotes) {
    return null;
  }
  return (
    <Row>
      <Col>
        <ReactMarkdown
          className="reactMarkDown fieldvalue"
          remarkPlugins={[remarkGfm]}
          skipHtml={true}
          linkTarget="_blank"
        >
          {entry?.familynotes}
        </ReactMarkdown>
      </Col>
    </Row>
  );
};

const OccupationCard = ({ entry }) => {
  if (noOccupationInfoProvided(entry)) {
    return null;
  }
  return (
    <Row>
      <Col>
        {entry.occupation.map((occupation, i) => (
          <EventRow
            key={`${FormatToDate(occupation.start_date, "Unknown")} ${occupation.occupation_name}-${i}`}
            title={occupation.occupation_name}
            subtitle={occupation.employer_name}
            description={occupation.description}
            dates={`${FormatToDate(occupation.start_date, "Unknown")} - ${FormatToDate(
              occupation.end_date,
              "Unknown"
            )}`}
          />
        ))}
      </Col>
    </Row>
  );
};

const ResidenceRow = ({ property }) => {
  const [responseData, setResponseData] = React.useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = React.useCallback(async () => {
    axios({
      method: "GET",
      url: `/api/v1/places/${property.location_name}`,
      headers: { "Content-Type": "application/json" },
      params: {},
    })
      .then((response) => {
        setResponseData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [property.location_name]);

  React.useEffect(() => {
    setIsLoading(true);
    fetchData().then(() => {
      setIsLoading(false);
    });
  }, [fetchData, setIsLoading]);

  return (
    <EventRow
      title={property.name}
      subtitle={
        (responseData && responseData?.full_title) ||
        (isLoading ? <Spinner animation="border" size="sm" /> : "(Unknown Location)")
      }
      description={property.description}
      dates={`${FormatToDate(property.owned_from, "Unknown")} - ${FormatToDate(property.owned_until, "Unknown")}`}
    />
  );
};

const ResidenceCard = ({ entry }) => {
  if (noResidenceInfoProvided(entry)) {
    return null;
  }

  return (
    <Row>
      <Col>
        {entry.property_owned.map((property, i) => (
          <ResidenceRow key={`${property.location_name}-${i}`} property={property} />
        ))}
      </Col>
    </Row>
  );
};

// the list of spouses contains both partners. this function will return the
// partner that is not described by "entry"

const MarriagesCard = ({ entry }) => {
  if (!(entry?.marriages && entry.marriages.length >= 1)) {
    return null;
  }

  const getSpouse = (spouseEntry, spouses) => {
    for (let spouse of spouses) {
      if (spouse.url !== spouseEntry.url) {
        const spouse_name = spouse.first_name ? `${spouse.first_name} ${spouse.surname}` : spouse.surname;
        if (spouse.state === "published") {
          return <Link to={`/biography/${spouse.id}`}>{spouse_name}</Link>;
        } else {
          return formatPendingIfNotPublished(spouse.state, spouse_name);
        }
      }
    }
    return null;
  };

  return (
    <Row>
      <h1 className="family-heading">Marriages</h1>
      {entry.marriages.map((marriage, i) => (
        <EventRow
          key={`${FormatToDate(marriage.start_date, "Unknown")} ${marriage.marriage_name}-${i}`}
          title={<>Marriage to {getSpouse(entry, marriage.spouses)}</>}
          subtitle={marriage.place_of_marriage?.full_title || "(Unknown Location)"}
          description={marriage.notes}
          dates={`${FormatToDate(marriage.start_date, "Unknown")} - ${FormatToDate(marriage.end_date, "Unknown")}`}
        />
      ))}
    </Row>
  );
};

const ParentCard = ({ entry }) => {
  if (!(entry?.parents && entry.parents.length >= 1)) {
    return null;
  }
  const getParent = (parent) => {
    const name = `${parent.first_name || ""} ${parent.surname}`.trim();
    return parent.state === "published" ? (
      <Link id={name} to={`/biography/${parent.id}`}>{name}</Link>
    ) : (
      formatPendingIfNotPublished(parent.state, name)
    );
  };
  const getParentType = (parent) => {
    if (parent.gender === "M") {
      return "Father";
    } else if (parent.gender === "F") {
      return "Mother";
    }
    return "Parent";
  };

  return (
    <Row>
      <h1 className="family-heading">Parents</h1>
      {entry.parents.map((parent) => (
        <EventRow
          key={`parent-${parent.parent.id}`}
          title={getParent(parent.parent)}
          subtitle={getParentType(parent.parent)}
          description={""}
          dates={`* ${FormatToDate(parent.parent.date_of_birth, "Unknown")} - † ${FormatToDate(
            parent.parent.date_of_death,
            "Unknown"
          )}`}
        />
      ))}
    </Row>
  );
};

const ChildRow = ({ entry, child }) => {
  const [responseData, setResponseData] = React.useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = React.useCallback(async () => {
    axios({
      method: "GET",
      url: `/api/v1/persons/${child.id}`,
      headers: { "Content-Type": "application/json" },
      params: {},
    })
      .then((response) => {
        setResponseData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [child.id]);

  React.useEffect(() => {
    if (child.state === "published" || child.state === "out_of_scope") {
      setIsLoading(true);
      fetchData().then(() => {
        setIsLoading(false);
      });
    }
  }, [child.state, fetchData, setIsLoading]);

  const getOtherParent = () => {
    if (isLoading) {
      return <>...</>;
    }
    if (responseData && responseData?.parents) {
      for (let childs_parent of responseData.parents) {
        if (childs_parent.parent.url !== entry.url) {
          const name = `${childs_parent.parent.first_name || ""} ${childs_parent.parent.surname}`.trim();
          return childs_parent.parent.state === "published" ? (
            <>
              With{" "}
              <Link to={`/biography/${childs_parent.parent.id}`}>
                {name}
              </Link>
            </>
          ) : (
            <>With {name}</>
          );
        }
      }
    }
    return <>(Other Parent Unknown)</>;
  };

  return (
    <EventRow
      key={`parent-${child.id}`}
      title={getName(child)}
      subtitle={getOtherParent()}
      description={""}
      dates={`* ${FormatToDate(child.date_of_birth, "Unknown")} - † ${FormatToDate(child.date_of_death, "Unknown")}`}
    />
  );
};

const ChildrenCard = ({ entry }) => {
  if (!(entry?.children && entry.children.length >= 1)) {
    return null;
  }
  return (
    <Row>
      <h1 className="family-heading">Children</h1>
      {entry.children.map((child) => (
        <ChildRow key={`child-${child.child.id}`} entry={entry} child={child.child} />
      ))}
    </Row>
  );
};

const SiblingCard = ({ entry }) => {
  const [siblings, setSiblings] = React.useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchParentData = React.useCallback(async () => {
    const parentPromises = [];
    for (let parentRow of entry.parents) {
      const parent = parentRow.parent;
      if (parent.state === "published" || parent.state === "out_of_scope") {
        const _promise = axios({
          method: "GET",
          url: `/api/v1/persons/${parent.id}`,
          headers: { "Content-Type": "application/json" },
          params: {},
        });
        parentPromises[parentPromises.length] = _promise;
      }
    }
    await Promise.all(parentPromises)
      .then((values) => {
        const children = [];
        const ids = new Set();

        for (let parentResponse of values) {
          const data = parentResponse.data;
          for (let _sibling of data.children) {
            if (_sibling.child.url !== entry.url && !ids.has(_sibling.child.url)) {
              children[children.length] = _sibling.child;
              ids.add(_sibling.child.url);
            }
          }
        }
        setSiblings(children);
      })
      .catch((error) => {
        console.log("Error fetching parents: ", error);
      });
  }, [entry.parents, entry.url, setSiblings]);

  React.useEffect(() => {
    setIsLoading(true);
    fetchParentData().then(() => {
      setIsLoading(false);
    });
  }, [fetchParentData, setIsLoading]);

  const getSiblingType = (person) => {
    if (person.gender === "M") {
      return "Brother";
    } else if (person.gender === "F") {
      return "Sister";
    }
    return "Sibling";
  };

  if (isLoading || siblings.length < 1) {
    return null;
  }

  return (
    <Row>
      <h1 className="family-heading">Siblings</h1>
      {siblings.map((sibling) => (
        <EventRow
          key={`sibling-${sibling.id}`}
          title={getName(sibling)}
          subtitle={getSiblingType(sibling)}
          description={""}
          dates={`* ${FormatToDate(sibling.date_of_birth, "Unknown")} - † ${FormatToDate(
            sibling.date_of_death,
            "Unknown"
          )}`}
        />
      ))}
    </Row>
  );
};

const FamilyCard = ({ entry }) => {
  if (noFamilyInfoProvided(entry)) {
    return null;
  }
  return (
    <>
      <ParentCard entry={entry} />
      <SiblingCard entry={entry} />
      <MarriagesCard entry={entry} />
      <ChildrenCard entry={entry} />
    </>
  );
};

const NewspaperCard = ({ entry }) => {
  if (noNewspaperInfoProvided(entry)) {
    return null;
  }

  return (
    <Row className="mt-3">
      <Col>
        <ul>
          {entry.newspapers.map((newspaper, i) => (
            <li key={`${newspaper.article_title}${i}`}>
              {newspaper.date ? `(${newspaper.date}). ` : "(Unknown Date). "}
              {newspaper.article_title ? `${newspaper.article_title}. ` : "[Unknown Article]. "}
              <i>{newspaper.newspaper_title ? `${newspaper.newspaper_title}` : "[Unknown Newspaper]"}</i>
              {newspaper.page_number ? `, p. ${newspaper.page_number}. ` : ", p. ?. "}
              <br />
              <a href={newspaper.url} target="_blank" rel="noreferrer">
                {newspaper.url}
              </a>
            </li>
          ))}
        </ul>
      </Col>
    </Row>
  );
};

const ArchiveCard = ({ entry }) => {
  if (noArchivalInfoProvided(entry)) {
    return null;
  }

  return (
    <Row className="mt-3">
      <Col>
        <ul>
          {entry.archival_records.map((record, i) => (
            <li key={`${record.article_title}${i}`}>
              {record.record ? `${record.record}. ` : ""}
              <i>{record.archive ? `${record.archive}` : ""}</i>
              {record.item_no ? `, ${record.item_no}. ` : ""}
              <br />
              <a href={record.url} target="_blank" rel="noreferrer">
                {record.url}
              </a>
            </li>
          ))}
        </ul>
      </Col>
    </Row>
  );
};

const OnlineResources = ({ entry }) => {
  if (noOnlineResourcesProvided(entry)) {
    return null;
  }

  return (
    <Row className="mt-3">
      <Col>
        <ul>
          {entry.online_resources.map((record, i) => (
            <li key={`${record.title}${i}`}>
              {record.title ? `${record.title}. ` : ""}
              <i>{record.description ? `${record.description}` : ""}</i>
              <br />
              <a href={record.url} target="_blank" rel="noreferrer">
                {record.url}
              </a>
              <br />
              {record?.type?.map((record_type, _i) => (
                <Badge bg="primary" key={record_type} className={i > 0 ? "mx-1" : null}>
                  {record_type}
                </Badge>
              ))}
            </li>
          ))}
        </ul>
      </Col>
    </Row>
  );
};

const BibliographyCard = ({ entry }) => {
  // This card shows the bibliography in APA referencing style.
  // Since referencing styles are hard to get right, especially for
  // the full range of publication types and for varying fields and optional fields
  // I'm using citation-js (with citeproc-js underneath) to render it.
  // The storage format is closely based on bibtex so in this function
  // stored records first get converted into propert bitex-json and then fed
  // into citaton-js to convert to html.
  // While inserting Raw HTML into react is a security risk and isn't per se best practise,
  // in this case it's justifyiable because no equivalent library exists in react to do this.
  // given the source of the data XSS attacks seem unlikely but the html gets
  // nevertheless santised before inserting to make sure there are no script tags etc
  // in it.

  if (noBibliographyProvided(entry)) {
    return null;
  }

  const citations = entry.published_sources.map((citation) => {
    const _tmp = {};
    // We use a non-BibTex type "edited_book" to make the distinction between edited and authord book clearer
    // and either show an editor or an author field in the entry form. Since CiteProc wouldn't know this type
    // we remove the _edited postfix and make it just "book". The dictinction in the citation will be made
    // simply by whether author(s) or editor(s) are specified.
    _tmp.type = citation.publication_type.split("_")[0];
    _tmp.label = "";
    _tmp.properties = {};
    for (const [key, value] of Object.entries(citation)) {
      if (key !== "published_sources") {
        _tmp.properties[key] = value || "";
      }
      // Ignore url for bibliograpy as we want custom stylings
      if (key === "url") {
        _tmp.properties[key] = "";
      }
    }
    console.log(_tmp);
    const cite = new Cite([_tmp]);
    return DOMPurify.sanitize(
      cite.format("bibliography", {
        format: "html",
        template: "apa",
        lang: "en-US",
      })
    );
  });

  return (
    <Row className="mt-3">
      <Col>
        <ul>
          {citations.map((citation, _i) => (
            <li key={citation} className="mb-3">
              <div dangerouslySetInnerHTML={{ __html: citation }} />
                {entry.published_sources[_i].url ? (
                  <a href={entry.published_sources[_i].url} target="_blank" rel="noreferrer">
                  {entry.published_sources[_i].url}
                  </a>
                ) : (
                  null
                )}
            </li>
          ))}
        </ul>
      </Col>
    </Row>
  );
};

const getName = (c) => {
  const name = `${c.first_name || ""} ${c.surname}`.trim();
  return c.state === "published" ? (
    <Link id={name} to={`/biography/${c.id}`}>{name}</Link>
  ) : (
    formatPendingIfNotPublished(c.state, name)
  );
};

const BiographyPage = () => {
  const [responseData, setResponseData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isEditable, setEditable] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  let { id } = useParams();

  const fetchData = React.useCallback(() => {
    personsAPI
      .getById(id)
      .then((response) => {
        setResponseData(response.data);
      })
      .catch((error) => {
        console.log(error);
        const statusCode = error.response ? error.response.status : null;
        if (statusCode === 404) {
          navigate("/notfound");
        }
      });

    personsAPI
      .getPersonOptions(id)
      .then((response) => {
        setEditable((response?.data?.actions?.hasOwnProperty("PUT")) ?? false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id, navigate]);

  React.useEffect(() => {
    setIsLoading(true);
    fetchData();
    setIsLoading(false);
  }, [fetchData]);

  if (responseData === null) {
    return null;
  }

  const getSubTitle = () => {
    return (
      (responseData?.date_of_birth || responseData?.date_of_death) &&
      (responseData?.date_of_birth?.year || "Unknown") + " - " + (responseData?.date_of_death?.year || "Unknown")
    );
  };

  return (
    <>
      <PageLayout
        title={(responseData?.first_name ? responseData?.first_name + " " : "") + responseData.surname}
        subtitle={getSubTitle()}
        parents={[{ to: "/search", title: "Search" }]}
        showHeadingAndBreadcrumb={true}
      >
        {isEditable && (
          <Row>
            <Col lg={12} md={12} sm={12} className="mb-1 d-flex justify-content-end bio-button-toolbar">
              <OverlayTrigger placement={"bottom"} overlay={<Tooltip>Edit this entry</Tooltip>}>
                <Button size="sm" as={Link} to={location.pathname.replace("/biography/", "/edit-bio-entry/")}>
                  <i className="bi-pencil-square"></i> Edit
                </Button>
              </OverlayTrigger>
            </Col>
          </Row>
        )}
        {isLoading && <Spinner />}
        {!isLoading && (
          <Row>
            <Col lg={9} md={8} sm={12}>
              <Accordion
                defaultActiveKey={["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11"]}
                alwaysOpen
                className="bio-page-accordion"
              >
                <BiographyFormAccordionItem
                  eventKey={1}
                  header={
                    <>
                      {" "}
                      <FontAwesomeIcon icon={faSignature} className={"fa-fw"} />
                      <p>Name</p>
                    </>
                  }
                >
                  <NamesCard entry={responseData} />
                </BiographyFormAccordionItem>
                <BiographyFormAccordionItem
                  eventKey={2}
                  header={
                    <>
                      {" "}
                      <FontAwesomeIcon icon={faUser} className={"fa-fw"} />
                      <p>Life Summary</p>
                    </>
                  }
                >
                  <LifeSummary entry={responseData} />
                </BiographyFormAccordionItem>
                <BiographyFormAccordionItem
                  eventKey={3}
                  accordionArrowClassName={!responseData?.familynotes ? "hide-accordion-arrow" : ""}
                  header={
                    <>
                      {" "}
                      <FontAwesomeIcon icon={faBook} className={"fa-fw"} />
                      <p>Notes</p>
                    </>
                  }
                >
                  {responseData.familynotes && <NotesCard entry={responseData} />}
                </BiographyFormAccordionItem>
                <BiographyFormAccordionItem
                  eventKey={4}
                  accordionArrowClassName={noFamilyInfoProvided(responseData) ? "hide-accordion-arrow" : ""}
                  header={
                    <>
                      {" "}
                      <FontAwesomeIcon icon={faPeopleGroup} className={"fa-fw"} />
                      <p>Family</p>
                    </>
                  }
                >
                  {!noFamilyInfoProvided(responseData) && <FamilyCard entry={responseData} />}
                </BiographyFormAccordionItem>
                <BiographyFormAccordionItem
                  eventKey={5}
                  accordionArrowClassName={
                    noArrivalInfoProvided(responseData) || responseData.indigenous_status ? "hide-accordion-arrow" : ""
                  }
                  header={
                    <>
                      {" "}
                      <FontAwesomeIcon icon={faSailboat} className={"fa-fw"} />
                      <p>Mobilities</p>
                    </>
                  }
                >
                  {!noArrivalInfoProvided(responseData) && !responseData.indigenous_status && (
                    <TravelCard entry={responseData} />
                  )}
                </BiographyFormAccordionItem>

                <BiographyFormAccordionItem
                  eventKey={6}
                  accordionArrowClassName={noOccupationInfoProvided(responseData) ? "hide-accordion-arrow" : ""}
                  header={
                    <>
                      {" "}
                      <FontAwesomeIcon icon={faToolbox} className={"fa-fw"} />
                      <p>Working Life</p>
                    </>
                  }
                >
                  {!noOccupationInfoProvided(responseData) && <OccupationCard entry={responseData} />}
                </BiographyFormAccordionItem>

                <BiographyFormAccordionItem
                  eventKey={7}
                  accordionArrowClassName={noResidenceInfoProvided(responseData) ? "hide-accordion-arrow" : ""}
                  header={
                    <>
                      {" "}
                      <FontAwesomeIcon icon={faHouse} className={"fa-fw"} />
                      <p>Residence and Property Owned</p>
                    </>
                  }
                >
                  {!noResidenceInfoProvided(responseData) && <ResidenceCard entry={responseData} />}
                </BiographyFormAccordionItem>

                <BiographyFormAccordionItem
                  eventKey={8}
                  accordionArrowClassName={noNewspaperInfoProvided(responseData) ? "hide-accordion-arrow" : ""}
                  header={
                    <>
                      {" "}
                      <FontAwesomeIcon icon={faNewspaper} className={"fa-fw"} />
                      <p>Newspaper Articles</p>
                    </>
                  }
                >
                  {!noNewspaperInfoProvided(responseData) && <NewspaperCard entry={responseData} />}
                </BiographyFormAccordionItem>

                <BiographyFormAccordionItem
                  eventKey={9}
                  accordionArrowClassName={noOnlineResourcesProvided(responseData) ? "hide-accordion-arrow" : ""}
                  header={
                    <>
                      {" "}
                      <FontAwesomeIcon icon={faArrowUpRightFromSquare} className={"fa-fw"} />
                      <p>Online Resources</p>
                    </>
                  }
                >
                  {!noOnlineResourcesProvided(responseData) && <OnlineResources entry={responseData} />}
                </BiographyFormAccordionItem>

                <BiographyFormAccordionItem
                  eventKey={10}
                  accordionArrowClassName={noArchivalInfoProvided(responseData) ? "hide-accordion-arrow" : ""}
                  header={
                    <>
                      {" "}
                      <FontAwesomeIcon icon={faBoxArchive} className={"fa-fw"} />
                      <p>Archival Records</p>
                    </>
                  }
                >
                  {!noArchivalInfoProvided(responseData) && <ArchiveCard entry={responseData} />}
                </BiographyFormAccordionItem>

                <BiographyFormAccordionItem
                  eventKey={11}
                  accordionArrowClassName={noBibliographyProvided(responseData) ? "hide-accordion-arrow" : ""}
                  header={
                    <>
                      {" "}
                      <FontAwesomeIcon icon={faBookBookmark} className={"fa-fw"} />
                      <p>Bibliography</p>
                    </>
                  }
                >
                  {!noBibliographyProvided(responseData) && <BibliographyCard entry={responseData} />}
                </BiographyFormAccordionItem>
              </Accordion>
            </Col>
            <Col lg={3} md={4} sm={12}>
              <Row>
                <Timeline entry={responseData} />
                <ContactUs responseData={responseData} />
              </Row>
            </Col>
          </Row>
        )}
      </PageLayout>
    </>
  );
};

export default BiographyPage;
