import React from "react";
import SearchPage from "../../pages/SearchPage";
import { biographyState } from "../BiographyEntryForm/utils/utils";

const DraftEntries = () => {
  return (
    <SearchPage
      displayAdminBreadcrumb={true}
      pageTitle={"Draft Entries"}
      displayEntryForState={biographyState.DRAFT}
      hideActionColumn={false}
      hideStateColumn={true}
      alertMessage={"There are no Draft Entries."}
    />
  );
};

export default DraftEntries;
