import React, { useContext, useEffect, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { updateFormData } from "../../../stateManager/actions/BiographyActions";
import { BiographyStore } from "../../../stateManager/stores/BiographyStore";
import NoDataProvidedMessage from "./NoDataProvidedMessage";
import { ArrayFieldAddButton } from "./ArrayFieldAddButton";
import { PersonSearch } from "./PersonSearch";
import { ArrayFieldDeleteButton } from "./ArrayFieldDeleteButton";
import { ArrayFieldMoveUpDownButtons } from "./ArrayFieldMoveUpDownButtons";
import { ArrayFieldUndoButton } from "./ArrayFieldUndoButton";

function RelationshipDropdownArray({ classNames, mainFieldTitle, registerText, propertyName, headingName }) {
  const {
    control,
    getValues,
    reset,
    formState: { errors },
  } = useFormContext();

  const { fields, append, remove, swap } = useFieldArray({
    control,
    name: registerText,
  });
  const { state, dispatch } = useContext(BiographyStore);
  const [displayUndoButton, setDisplayUndoButton] = useState(false);
  const values = getValues(registerText);

  useEffect(() => {
    if (JSON.stringify(values) === JSON.stringify(state.formData[registerText])) {
      setDisplayUndoButton(false);
    } else {
      setDisplayUndoButton(true);
    }
  }, [values, state, registerText]);

  const RelationshipFieldArrayLabel = () => {
    return (
      <label htmlFor={registerText} className="form-label">
        {mainFieldTitle}
      </label>
    );
  };

  if (state?.formData[registerText]?.length === 0 && state?.isReadOnly) {
    return (
      <div className={"col-12 " + classNames}>
        <RelationshipFieldArrayLabel />
        <NoDataProvidedMessage />
      </div>
    );
  }

  return (
    <div className={"col-12 " + classNames}>
      <RelationshipFieldArrayLabel />

      <div>
        {fields.map((item, index) => {
          const itemNum = index + 1;
          return (
            <div className="row g-2 field-array-row" key={item.id}>
              <div className="col-md-9 field-column" id={item.id}>
                <div className="sub-field-array-style row g-2 mb-3">
                  <p className="subTitle">{`${headingName} ` + itemNum}</p>

                  <PersonSearch
                    registerText={`${registerText}[${index}].${propertyName}`}
                    displayStubEntryFilterCheckbox={true}
                    placeholder={"Search & Select or Add a new Entry"}
                  />

                  {errors &&
                    errors[registerText] &&
                    errors[registerText]?.[index] &&
                    errors[registerText]?.[index][propertyName] && (
                      <span className="text-danger" data-testid="error-message" id={registerText + "-error-message"}>
                        {errors[registerText]?.[index][propertyName].message}
                      </span>
                    )}
                </div>
              </div>
              {!state?.isReadOnly && (
                <div className="col-md-3">
                  <div className="row flex-row p-0">
                    <ArrayFieldDeleteButton
                      onClick={() => {
                        updateFormData(getValues(), dispatch);
                        remove(index);
                      }}
                      id={`parents-delete-button-${index}`}
                      dataTestId={`parents-delete-button-${index}`}
                    />
                    <ArrayFieldMoveUpDownButtons
                      upButtonDataTestId={registerText + index + "-up-button"}
                      downButtonDataTestId={registerText + index + "-down-button"}
                      onDownButtonClick={() => swap(index, index + 1)}
                      onUpButtonClick={() => swap(index, index - 1)}
                      index={index}
                      fields={fields}
                    />
                  </div>
                </div>
              )}
            </div>
          );
        })}

        {!state?.isReadOnly && (
          <ArrayFieldAddButton
            dataTestId={registerText + "-add-button"}
            onClick={() => {
              append({ propertyName: "" });
              updateFormData(getValues(), dispatch);
            }}
          />
        )}
        {displayUndoButton && (
          <ArrayFieldUndoButton
            dataTestId={registerText + "-undo-button"}
            getValues={getValues}
            append={append}
            reset={reset}
          />
        )}
      </div>
    </div>
  );
}

export default RelationshipDropdownArray;
