import React, { useContext, useEffect, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { updateFormData } from "../../../stateManager/actions/BiographyActions";
import { BiographyStore } from "../../../stateManager/stores/BiographyStore";
import { ArrayFieldAddButton } from "./ArrayFieldAddButton";
import { ArrayFieldDeleteButton } from "./ArrayFieldDeleteButton";
import { ArrayFieldMoveUpDownButtons } from "./ArrayFieldMoveUpDownButtons";
import { ArrayFieldUndoButton } from "./ArrayFieldUndoButton";
import NoDataProvidedMessage from "./NoDataProvidedMessage";

function ArchivalRecordsArray({ classNames }) {
  const {
    control,
    register,
    getValues,
    reset,
    formState: { errors },
  } = useFormContext();

  const { fields, append, remove, swap } = useFieldArray({
    control,
    name: "archival_records",
  });

  const [displayUndoButton, setDisplayUndoButton] = useState(false);
  const { state, dispatch } = useContext(BiographyStore);
  const archivalRecordsValues = getValues("archival_records");

  useEffect(() => {
    if (JSON.stringify(archivalRecordsValues) === JSON.stringify(state.formData.archival_records)) {
      setDisplayUndoButton(false);
    } else {
      setDisplayUndoButton(true);
    }
  }, [archivalRecordsValues, state]);

  if (state?.formData.archival_records.length === 0 && state?.isReadOnly) {
    return <NoDataProvidedMessage classNames={classNames} />;
  }

  return (
    <div className={"col-12 " + classNames}>
      {fields.map((item, index) => {
        const itemNum = index + 1;

        return (
          <div className="row g-2 field-array-row" key={item.id}>
            <div className="col-md-9 field-column">
              <div className="sub-field-array-style row g-2 mb-3" id={item.id}>
                <p className="subTitle">{`Archival record ` + itemNum}</p>
                <div className="col-6">
                  <input
                    id={`archival_records[${index}].archive`}
                    name={`archival_records[${index}].archive`}
                    {...register(`archival_records[${index}].archive`)}
                    placeholder="Archive name"
                    className="form-control"
                    readOnly={state.isReadOnly}
                  />
                </div>
                <div className="col-6" id={`archival_records[${index}].record-div`}>
                  <input
                    id={`archival_records[${index}].record`}
                    name={`archival_records[${index}].record`}
                    {...register(`archival_records[${index}].record`)}
                    placeholder="Record name*"
                    className="form-control"
                    readOnly={state.isReadOnly}
                  />
                  {errors?.archival_records?.[index]?.record && (
                    <span className="text-danger" data-testid="error-message">
                      {errors?.archival_records?.[index]?.record.message}
                    </span>
                  )}
                </div>
                <div className="col-6" id={`archival_records[${index}].item_no-div`}>
                  <input
                    id={`archival_records[${index}].item_no`}
                    name={`archival_records[${index}].item_no`}
                    {...register(`archival_records[${index}].item_no`)}
                    placeholder="Reference id"
                    className="form-control"
                    readOnly={state.isReadOnly}
                  />
                </div>
                <div className="col-6" id={`archival_records[${index}].url-div`}>
                  <input
                    id={`archival_records[${index}].url`}
                    name={`archival_records[${index}].url`}
                    {...register(`archival_records[${index}].url`)}
                    placeholder="Url to the record"
                    className="form-control"
                    readOnly={state.isReadOnly}
                  />
                </div>
              </div>
            </div>
            {!state?.isReadOnly && (
              <div className="col-md-3">
                <div className="row flex-row p-0">
                  <ArrayFieldDeleteButton
                    dataTestId={`archival-records-delete-button-${index}`}
                    onClick={() => {
                      updateFormData(getValues(), dispatch);
                      remove(index);
                    }}
                  />
                  <ArrayFieldMoveUpDownButtons
                    onDownButtonClick={() => swap(index, index + 1)}
                    onUpButtonClick={() => swap(index, index - 1)}
                    index={index}
                    fields={fields}
                    upButtonDataTestId={`archival-records-${index}-up`}
                    downButtonDataTestId={`archival-records-${index}-down`}
                  />
                </div>
              </div>
            )}
          </div>
        );
      })}
      {!state?.isReadOnly && (
        <ArrayFieldAddButton
          dataTestId={"archival-records-add-button"}
          onClick={() => {
            append({ archive: "", record: "", item_no: "", url: "" });
            updateFormData(getValues(), dispatch);
          }}
        />
      )}
      {displayUndoButton && (
        <ArrayFieldUndoButton
          dataTestId={"archival-records-undo-button"}
          getValues={getValues}
          append={append}
          reset={reset}
        />
      )}
    </div>
  );
}

export default ArchivalRecordsArray;
