import axios from "axios";
const baseURL = `/api/v1/glossaries`;

const headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

const getPage = async function (pageUrl) {
  try {
    let res = await axios.get(pageUrl, headers);
    return res.data;
  } catch (error) {
    console.log("Entering error handler");
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
      throw Error(
        `The server responded with an error code: ${error.response.status}`
      );
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an axios of XMLHttpRequest in the browser and an axios of
      // http.ClientRequest in node.js
      console.log(error.request);
      throw Error(`No responde from server.`);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log("Error", error.message);
      throw Error(`Error: ${error.message}.`);
    }
  }
};

const fetchAllPages = async function (resultList, startUrl) {
  const results = await getPage(startUrl);
  const newResultList = resultList.concat(results.results);
  if (results?.next !== null) {
    return newResultList.concat(await fetchAllPages(resultList, results.next));
  } else {
    return newResultList;
  }
};

const getGlossaryEntriesByGlossaryType = ({ glossaryType, name }) => {
  return axios.get(
    `${baseURL}/${glossaryType}/entries?name__icontains=${name}`
  );
};

const createGlossaryEntry = (glossaryType, data) => {
  return axios.post(`${baseURL}/${glossaryType}/entries`, data);
};

const updateGlossaryEntry = (id, glossaryType, params) => {
  return axios.put(`${baseURL}/${glossaryType}/entries/${id}`, params, headers);
};

// prefixed with underscored because delete is a reserved word in javascript
const _delete = (id, glossaryType) => {
  return axios.delete(`${baseURL}/${glossaryType}/entries/${id}`, headers);
};

const getGlossaryList = () => {
  return fetchAllPages([], baseURL);
};

const getGlossary = (slug) => {
  return axios.get(`${baseURL}/${slug}`);
};

const getGlossaryOptions = (slug) => {
  return axios.options(`${baseURL}/${slug}`);
};

export const glossaryAPI = {
  getGlossaryEntriesByGlossaryType,
  getGlossaryList,
  getGlossary,
  createGlossaryEntry,
  updateGlossaryEntry,
  getGlossaryOptions,
  delete: _delete,
};
