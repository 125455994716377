import React from "react";
import SearchPage from "../../pages/SearchPage";
import { biographyState } from "../BiographyEntryForm/utils/utils";

const AdminPublishedEntries = () => {
  return (
    <SearchPage
      displayAdminBreadcrumb={true}
      pageTitle={"Published Entries"}
      displayEntryForState={biographyState.PUBLISHED}
      hideActionColumn={false}
      hideStateColumn={true}
      alertMessage={"There are no Published Entries."}
    />
  );
};

export default AdminPublishedEntries;
