import { initialState } from "../stores/BiographyStore";

export const updateFormData = (state, formData) => {
  return { ...state, formData };
};

export const updateInitialFormData = (state, initialFormData) => {
  return { ...state, initialFormData };
};

export const setPermissions = (state, hasEditPermission, hasDeletePermission) => {
  return {
    ...state,
    hasEditPermission,
    hasDeletePermission,
  };
};

export const setPossibleTransitions = (state, possibleTransitions) => {
  return {
    ...state,
    possibleTransitions,
  };
};

export const setIsReadOnly = (state, isReadOnly) => {
  return {
    ...state,
    isReadOnly,
  };
};

export const resetState = () => {
  return initialState;
};

const resetBiographyData = (state, formData, isSaved) => {
  return {
    ...state,
    formData,
    isReadOnly: false,
    isSaved: isSaved,
    hasAnyAPIErrors: false,
    APIErrorMessages: null,
    APIErrorResponseStatus: null,
  };
};

const setIsLoadingBiographyData = (state, isLoading) => {
  return {
    ...state,
    isLoading: isLoading,
  };
};

const setHasAnyAPIErrorsBiographyData = (
  state,
  hasAnyAPIErrors,
  APIErrorMessages,
  APIErrorResponseStatus
) => {
  return {
    ...state,
    isLoading: false,
    isSaved: false,
    hasAnyAPIErrors: hasAnyAPIErrors,
    APIErrorMessages: APIErrorMessages,
    APIErrorResponseStatus: APIErrorResponseStatus,
  };
};

const setId = (state, id) => {
  return {
    ...state,
    id: id,
  };
};

export const BiographyReducer = (state, action) => {
  switch (action.type) {
    case "SET_ID":
      return setId(state, action.id);
    case "RESET_STATE":
      return resetState();
    case "UPDATE_INITIAL_FORM_DATA":
      return updateInitialFormData(state, action.initialFormData);
    case "UPDATE_FORM_DATA":
      return updateFormData(state, action.formData);
    case "LOADING_BIOGRAPHY_DATA":
      return setIsLoadingBiographyData(state, action.isLoading);
    case "SET_PERMISSIONS":
      return setPermissions(state, action.hasEditPermission, action.hasDeletePermission);
    case "SET_IS_READ_ONLY":
      return setIsReadOnly(state, action.isReadOnly);
    case "SET_POSSIBLE_TRANSITIONS":
      return setPossibleTransitions(state, action.possibleTransitions);
    case "RESET_BIOGRAPHY_DATA":
      return resetBiographyData(state, action.formData, action.isSaved);
    case "HAS_ANY_API_ERRORS_BIOGRAPHY_DATA":
      return setHasAnyAPIErrorsBiographyData(
        state,
        action.hasAnyAPIErrors,
        action.APIErrorMessages,
        action.APIErrorResponseStatus
      );

    default:
      return state;
  }
};
