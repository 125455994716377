import React, { useState } from 'react';
import axios from 'axios';

const GetPlaces = () => {
    const [data, setData] = useState(null)
    const fetchData = React.useCallback(() => {
        axios({
            "method": "GET",
            "url": `/api/v1/places`,
            "headers": { "Content-Type": "application/json" },
            "params": {}
        })
            .then((response) => {
                setData(response)
            })
            .catch((error) => {
                console.log("Error:");
                console.log(error);
            })
    }, [])

    React.useEffect(() => {
        fetchData()
        return () => {
            setData({});
          };
    }, [fetchData])

    return data
}

export default GetPlaces;