import { getIdFromUrl } from "./utils";

// from 2021-11-12T02:01:31.522000+10:00 to 12/11/2021
export const formatStrToDate = (strDate) => {
    return strDate?.split("T")[0].split("-").reverse().join("/");
};

const formatDate = (dateNumber) => {
    return dateNumber < 10 ? "0" : "";
};

// change null to '-' && add 0 to month&day less than 10 e.g. 8 to 08
export const numberIsNullOrLessThan10 = (dateNumber) => {
    dateNumber = dateNumber ? formatDate(dateNumber) + dateNumber : "-";

    return dateNumber;
};

// Returns short month name based on given month number
export const shortMonthName = (monthNumber) => {
    const month_names_short = ["", "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    if (month_names_short[monthNumber]) return month_names_short[monthNumber];
    else return "-";
};

// from { "year": 1892, "month": 5, "day": 15, "approximate": false, "uncertain": false } to 15 May 1892. approximate adds "ca. " in front of the date, uncertain adds " (uncertain)" afterwards
export const FormatToDate = (date_of_xx, unknown_response = " - ") => {
    let dateStr;

    if (date_of_xx) {
        if (
            date_of_xx.day == null &&
            date_of_xx.month == null &&
            date_of_xx.year == null
        ) {
            dateStr = unknown_response;
        } else if (date_of_xx.day == null && date_of_xx.month == null) {
            dateStr = `${date_of_xx.year}`;
        } else if (date_of_xx.day == null) {
            dateStr = `${shortMonthName(date_of_xx.month)} ${numberIsNullOrLessThan10(date_of_xx.year)}`;
        } else {
            dateStr = `${date_of_xx.day} ${shortMonthName(date_of_xx.month)} ${numberIsNullOrLessThan10(date_of_xx.year)}`;
        }

        if (date_of_xx.approximate) {
            dateStr = "ca. " + dateStr;
        }

        if (date_of_xx.uncertain) {
            dateStr = dateStr + " (uncertain)";
        }
    } else {
        dateStr = unknown_response;
    }

    return dateStr;
};

export const formatCreateUpdateBy = (createdupdated_by) => {
    return createdupdated_by?.first_name && createdupdated_by?.last_name
        ? `${createdupdated_by.first_name} ${createdupdated_by.last_name}`
        : " - ";
};

// Formats the input fields - number type (ie. year and number in published sources array) values. If kept empty, fields take empty string "" because native input will not return undefined or null.
// Hence, if kept empty, passing null to backend as it expects either integer or null.
export const formatPublishedSourcesData = (data) => {
    const formattedPublishedSources = [];
    data.forEach((publishedSource) => {
        const year = publishedSource.year === "" ? null : publishedSource.year;
        const number = publishedSource.number === "" ? null : publishedSource.number;
        const formattedPublishSource = {
            ...publishedSource,
            year: year,
            number: number,
        };
        formattedPublishedSources.push(formattedPublishSource);
    });
    return formattedPublishedSources;
};

//  Formats Relationship data for example form fields: person_parents and children before saving and editing.
//  BE expects string url as child and parent
export const formatRelationshipData = (parentValues) => {
    const formattedParentValues = [];
    parentValues?.forEach((parent) => {
        const newParent = {
            ...parent,
            child: parent.child?.url ? parent.child.url : parent.child,
            parent: parent.parent?.url ? parent.parent.url : parent.parent,
        };
        formattedParentValues.push(newParent);
    });

    return formattedParentValues;
};

export const formatMarriageData = (data) => {
    const formattedMarriageData = [];
    data?.forEach((marriage) => {
        const spouses = [];

        const place = marriage?.place_of_marriage?.url ? marriage.place_of_marriage.url : marriage?.place_of_marriage;

        marriage?.spouses?.forEach((spouse) => {
            if (spouse) {
                spouse = spouse?.url ? spouse?.url : spouse;
                spouses.push(spouse);
            }
        });

        const formattedMarriage = {
            ...marriage,
            place_of_marriage: place,
            spouses: spouses,
        };

        formattedMarriageData.push(formattedMarriage);
    });

    return formattedMarriageData;
};

// Formatting form fields values and updating the save data object before saving or editing.
export const formatData = (data) => {
    const formattedParentsData = formatRelationshipData(data["parents"]);
    const formattedChildrenData = formatRelationshipData(data["children"]);

    const formattedPublishedSourcesData = formatPublishedSourcesData(data["published_sources"]);
    const formattedMarriageData = formatMarriageData(data["marriages"]);

    return {
        ...data,
        parents: [...formattedParentsData],
        children: [...formattedChildrenData],
        published_sources: formattedPublishedSourcesData,
        marriages: formattedMarriageData,
    };
};

// Formatting marriages response data to alter spouses array. Removing the current person from the spouses array before pre-filling the form.
// For example, if you are viewing or editing person with id 1, then remove the person with id 1 from the marriages spouses array.
const formatMarriages = (data, personId) => {
    data?.forEach((marriage) => {
        const newSpouseList = [];
        marriage.spouses?.forEach((spouse) => {
            const spouseId = getIdFromUrl(spouse?.url);
            if (spouseId !== personId) {
                newSpouseList.push(spouse);
            }
            marriage.spouses = newSpouseList;
        });
    });
    return data;
};

// Formats biography response before pre-filling the form
export const formatDataToDisplayInForm = (responseData) => {
    const marriages = responseData["marriages"];
    const personId = getIdFromUrl(responseData["url"]);
    formatMarriages(marriages, personId);
    return {
        ...responseData,
    };
};
