import PageLayout from "../components/PageLayout";
import { Row } from "react-bootstrap";

const InternalServerError = () => {
  return (
    <PageLayout title={"Internal Server Error"} showHeadingAndBreadcrumb={true} parents="Home">
      <Row className="mx-1">Either server cannot be reached or encountered an error and could not complete your request.</Row>
    </PageLayout>
  );
};

export default InternalServerError;
