import React, { useContext, useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";

// Array field components
import OtherNamesArray from "../ArrayFieldComponents/OtherNamesArray";
import PropertyOwnedArray from "../ArrayFieldComponents/PropertyOwnedArray";
import RelatedOrganisationsArray from "../ArrayFieldComponents/RelatedOrganisationsArray";
import OccupationArray from "../ArrayFieldComponents/OccupationArray";
import MarriagesArray from "../ArrayFieldComponents/MarriagesArray";
import ArchivalRecordsArray from "../ArrayFieldComponents/ArchivalRecordsArray";
import OnlineResourcesArray from "../ArrayFieldComponents/OnlineResourcesArray";
import PublishedSourcesArray from "../ArrayFieldComponents/PublishedSourcesArray";

// React Select Components
import LabelAndSelect from "./LabelAndSelect";
import LabelAndDateInput from "./LabelAndDateInput";
import { ErrorAlertFor400 } from "../Errors/ErrorAlertFor400";
import { BiographyStore } from "../../../stateManager/stores/BiographyStore";
import { Accordion } from "react-bootstrap";
import RelationshipDropdownArray from "../ArrayFieldComponents/RelationshipDropdownArray";
import { BiographyFormAccordionItem } from "./BiographyFormAccordionItem";
import NewspapersArray from "../ArrayFieldComponents/NewspapersArray";
import { LabelAndInput } from "./LabelAndInput";
import { LabelAndMDEditor } from "./LabelAndMDEditor";
import { LabelAndGlossarySearch } from "./LabelAndGlossarySearch";
import { getValidDates } from "../utils/utils";
import ConfirmationModal from "../confirmationModal";
import { IndigenousStatus } from "./IndigenousStatus";
import LabelAndLocationSearch from "./LabelAndLocationSearch";

export function FormComponents() {
  const {
    setValue,
    getValues,
    control,
    clearErrors,
    formState: { errors },
    register,
  } = useFormContext(); // retrieve all hook methods
  const { state } = useContext(BiographyStore);
  const [showModal, setShowModal] = useState(false);
  const indigenousStatus = useWatch({
    control,
    name: "indigenous_status",
  });

  // Reset language_group and blanket_distribution_date values when indigenous_status value changes from yes to no
  useEffect(() => {
    if (indigenousStatus === false) {
      setValue("language_group", "");
      setValue("blanket_distribution_date", "");
      setValue("blanket_distribution_centre", null);
      clearErrors("language_group");
      clearErrors("blanket_distribution_date");
    }
  }, [indigenousStatus, setValue, clearErrors]);

  return (
    <>
      {state.hasAnyAPIErrors && state.APIErrorResponseStatus !== 500 && (
        <ErrorAlertFor400 APIErrorMessages={state.APIErrorMessages} />
      )}

      <ConfirmationModal
        show={showModal}
        scrollable={true}
        modalTitle={"Valid Date Formats"}
        cancelButtonText={"Close"}
        modalBody={getValidDates()}
        onModalClose={() => setShowModal(false)}
        onConfirmed={null}
        modalClassName={"modal-height"}
      />

      <Accordion defaultActiveKey={["1"]} alwaysOpen>
        <BiographyFormAccordionItem
          eventKey={1}
          header={"Personal Details"}
          error={errors?.first_name || errors?.middle_name || errors?.surname || errors?.gender}
        >
          <div className="row">
            <LabelAndInput
              labelText="Surname"
              error={errors?.surname?.message}
              classNames={"col-md-4"}
              labelClassName={"form-label required"}
              registerText="surname"
              register={register}
              isReadOnly={state?.isReadOnly}
            />

            <LabelAndInput
              labelText="First name"
              error={errors?.first_name?.message}
              classNames={"col-md-4"}
              registerText="first_name"
              register={register}
              isReadOnly={state?.isReadOnly}
            />

            <LabelAndInput
              labelText="Middle name"
              error={errors?.middle_name?.message}
              classNames={"col-md-4"}
              registerText="middle_name"
              register={register}
              isReadOnly={state?.isReadOnly}
            />
          </div>
          <OtherNamesArray classNames={"field-array-style mt-4"} />

          <div className="col-md-4 mt-3">
            <LabelAndSelect
              control={control}
              isReadOnly={state?.isReadOnly}
              labelText="Gender"
              error={errors?.gender?.message}
              placeholder="Select Gender"
              name="gender"
              classNames={"col-md-12"}
              labelClassName={"form-label required"}
              onSelectionChange={() => null}
              getValues={getValues}
            />
          </div>
        </BiographyFormAccordionItem>

        <BiographyFormAccordionItem
          eventKey={2}
          header={"Birth and Death Information"}
          error={errors?.date_of_birth || errors?.date_of_death}
        >
          <div className="row">
            <LabelAndLocationSearch
              labelText="Place of birth"
              name="place_of_birth"
              classNames={"col-lg-12 col-xl-6 mt-2"}
              placeholder={"Search and select or click + Place to add a new place"}
              displayAddButton={true}
              control={control}
              getValues={getValues}
              setValue={setValue}
              propertyOwnedLocation={false}
            />

            <LabelAndDateInput
              labelText="Date of birth"
              registerText="date_of_birth"
              classNames={"col-lg-12 col-xl-6 mt-2"}
              error={errors?.date_of_birth?.message}
              onShowDatesInfoIconClicked={() => setShowModal(true)}
            />

            <LabelAndLocationSearch
              labelText="Place of death"
              name="place_of_death"
              classNames={"col-lg-12 col-xl-6 mt-2"}
              displayAddButton={true}
              placeholder={"Search and select or click + Place to add a new place"}
              control={control}
              getValues={getValues}
              setValue={setValue}
              propertyOwnedLocation={false}
            />

            <LabelAndDateInput
              labelText="Date of death"
              registerText="date_of_death"
              classNames={"col-lg-12 col-xl-6 mt-2"}
              error={errors?.date_of_death?.message}
              onShowDatesInfoIconClicked={() => setShowModal(true)}
            />

            <LabelAndLocationSearch
              labelText="Place of burial"
              name="place_of_burial"
              classNames={"col-lg-12 col-xl-6 mt-2"}
              placeholder={"Search and select or click + Place to add a new place"}
              displayAddButton={true}
              control={control}
              getValues={getValues}
              setValue={setValue}
              propertyOwnedLocation={false}
            />
          </div>
        </BiographyFormAccordionItem>

        <BiographyFormAccordionItem
          eventKey={3}
          header={"Relationships"}
          error={errors?.parents || errors?.children || errors?.marriages}
        >
          {/* Parents relationship array field */}
          <RelationshipDropdownArray
            classNames={"field-array-style"}
            mainFieldTitle={"Parents"}
            registerText={"parents"}
            propertyName={"parent"}
            headingName={"Parent"}
            placeholder={"Click on search button to search and select parent"}
          />

          {/* Children relationship array field */}
          <RelationshipDropdownArray
            classNames={"field-array-style mt-4"}
            mainFieldTitle={"Children"}
            registerText={"children"}
            propertyName={"child"}
            headingName={"Child"}
            placeholder={"Click on search button to search and select child"}
          />

          <MarriagesArray classNames="field-array-style mt-4" onShowDatesFormatLinkClicked={() => setShowModal(true)} />
        </BiographyFormAccordionItem>

        <BiographyFormAccordionItem
          eventKey={4}
          header={"Indigenous status"}
          error={errors?.language_group || errors?.blanket_distribution_date}
        >
          <IndigenousStatus
            labelText={"Indigenous status"}
            registerText={"indigenous_status"}
            indigenousStatus={indigenousStatus}
          />
          {indigenousStatus === true && (
            <div className="row mt-3">
              <LabelAndInput
                labelText="Language group"
                error={errors?.language_group?.message}
                registerText="language_group"
                classNames="col-md-4"
                register={register}
                isReadOnly={state?.isReadOnly}
              />

              <LabelAndGlossarySearch
                displayGlossaryStatusMessage={true}
                onGlossarySelectionChange={() => null}
                placeholder={"Search Glossary or enter text"}
                labelText="Location reference"
                registerText="blanket_distribution_centre"
                classNames="col-md-4"
                glossaryType={"BlanketDistributionCentre"}
                control={control}
                getValues={getValues}
                setValue={setValue}
                isReadOnly={state?.isReadOnly}
              />

              <LabelAndDateInput
                labelText="Blanket distribution date"
                registerText="blanket_distribution_date"
                classNames={"col-md-4"}
                error={errors?.blanket_distribution_date?.message}
                onShowDatesInfoIconClicked={() => setShowModal(true)}
              />
            </div>
          )}
        </BiographyFormAccordionItem>
        <BiographyFormAccordionItem
          eventKey={5}
          header={"Cultural Heritage and Religion"}
          error={errors?.cultural_heritage || errors?.religion}
        >
          <div className="row">
            <LabelAndInput
              labelText="Cultural Heritage"
              error={errors?.cultural_heritage?.message}
              registerText="cultural_heritage"
              classNames="col-md-6"
              register={register}
              isReadOnly={state?.isReadOnly}
            />
            <LabelAndGlossarySearch
              displayGlossaryStatusMessage={true}
              placeholder={"Search Glossary or enter text"}
              onGlossarySelectionChange={() => null}
              labelText="Religion"
              error={errors?.religion?.message}
              registerText="religion"
              classNames="col-md-6"
              glossaryType={"Religion"}
              control={control}
              getValues={getValues}
              setValue={setValue}
              isReadOnly={state?.isReadOnly}
            />
          </div>
        </BiographyFormAccordionItem>

        <BiographyFormAccordionItem
          eventKey={6}
          header={"Arrival in Australia"}
          error={errors?.date_arrived_australia || errors?.date_arrived_qld}
        >
          <div className="row">
            <LabelAndDateInput
              labelText="Date arrived in Australia"
              registerText="date_arrived_australia"
              classNames={"col-md-6"}
              error={errors?.date_arrived_australia?.message}
              onShowDatesInfoIconClicked={() => setShowModal(true)}
            />

            <LabelAndGlossarySearch
              placeholder={"Search Glossary or enter text"}
              displayGlossaryStatusMessage={true}
              onGlossarySelectionChange={() => null}
              labelText="Ship name arrived in Australia"
              registerText="ship_arrived_in_australia"
              classNames="col-md-6"
              glossaryType={"ShipsArrivedInAustralia"}
              control={control}
              getValues={getValues}
              setValue={setValue}
              isReadOnly={state?.isReadOnly}
            />

            <LabelAndDateInput
              labelText="Date arrived in Queensland"
              registerText="date_arrived_qld"
              classNames={"col-md-6"}
              error={errors?.date_arrived_qld?.message}
              onShowDatesInfoIconClicked={() => setShowModal(true)}
            />

            <LabelAndGlossarySearch
              placeholder={"Search Glossary or enter text"}
              displayGlossaryStatusMessage={true}
              onGlossarySelectionChange={() => null}
              labelText="Ship name arrived in Queensland"
              registerText="ship_arrived_in_qld"
              classNames={"col-md-6"}
              glossaryType={"ShipsArrivedInQueensland"}
              control={control}
              getValues={getValues}
              setValue={setValue}
              isReadOnly={state?.isReadOnly}
            />
          </div>
        </BiographyFormAccordionItem>

        <BiographyFormAccordionItem eventKey={7} header={"Residence or Property Owned"} error={errors?.property_owned}>
          <PropertyOwnedArray classNames="field-array-style" onShowDatesFormatLinkClicked={() => setShowModal(true)} />
        </BiographyFormAccordionItem>

        <BiographyFormAccordionItem eventKey={8} header={"Related Organisations"} error={errors?.related_organisations}>
          <RelatedOrganisationsArray classNames="field-array-style" />
        </BiographyFormAccordionItem>

        <BiographyFormAccordionItem eventKey={9} header={"Occupation"} error={errors?.occupation}>
          <OccupationArray classNames="field-array-style" onShowDatesFormatLinkClicked={() => setShowModal(true)} />
        </BiographyFormAccordionItem>

        <BiographyFormAccordionItem eventKey={10} header={"Notes"}>
          <LabelAndMDEditor
            labelText="Internal Notes"
            registerText="notes"
            message="The notes field is for internal use only. The data entered in this field will not be visible to the public."
          />

          <LabelAndMDEditor labelText="Public Notes" registerText="familynotes" classNames={"mt-3"} />
        </BiographyFormAccordionItem>

        <BiographyFormAccordionItem eventKey={11} header={"Archival Records"} error={errors?.archival_records}>
          <ArchivalRecordsArray classNames="field-array-style" />
        </BiographyFormAccordionItem>

        <BiographyFormAccordionItem eventKey={12} header={"Online Resources"} error={errors?.online_resources}>
          <OnlineResourcesArray classNames="field-array-style" />
        </BiographyFormAccordionItem>

        <BiographyFormAccordionItem eventKey={13} header={"Newspapers"} error={errors?.newspapers}>
          <NewspapersArray classNames="field-array-style" onShowDatesFormatLinkClicked={() => setShowModal(true)} />
        </BiographyFormAccordionItem>

        <BiographyFormAccordionItem eventKey={14} header={"Published Sources"} error={errors?.published_sources}>
          <PublishedSourcesArray classNames="field-array-style" />
        </BiographyFormAccordionItem>
      </Accordion>
    </>
  );
}
