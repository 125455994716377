import { Col } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";

function AdminSummaryCard({ actionText, iconClassName, biographyEntriesCount, linkTo }) {
  const navigate = useNavigate();
  return (
    <div className="card action-card">
      <button type="button" className="btn summary-stats" onClick={() => navigate(linkTo)}>
        <div className="row">
          <Col xl={4} lg={4} md={6} sm={6} className="rounded-circle action-card-circle d-flex justify-content-center">
            {" "}
            <i className={iconClassName}></i>
          </Col>
          <Col xl={8} lg={8} md={6} sm={6} className="my-3 entry-summary-card">
            <b className="d-flex justify-content-left">{biographyEntriesCount}</b>
            <Link to={linkTo} className="card-title d-flex justify-content-left">
              {actionText}
            </Link>
          </Col>
        </div>
      </button>
    </div>
  );
}

export default AdminSummaryCard;
