import React, { useEffect, useRef, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import AdminSummaryCard from "./AdminSummaryCard";
import Chart from "chart.js/auto";
import { shortMonthName } from "../BiographyEntryForm/utils/Reformat";
import { personsAPI } from "../../api/personsAPI";
import { getLastYearMonths } from "../BiographyEntryForm/utils/utils";
import { Spinner } from "../Spinner";
import { Doughnut, Line } from "react-chartjs-2";

const AdminDashboard = ({ userName }) => {
  const [biographyEntriesCount, setBiographyEntriesCount] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState(null);

  // Read more about chart.js => https://www.chartjs.org/docs/latest/
  const getDoughnutChartData = (response) => {
    return {
      labels: ["Pending Reviews", "Published", "Draft", "Archived", "Out of Scope"],
      datasets: [
        {
          label: "My First Dataset",
          data: [
            response?.data?.pending_review_entries_count,
            response?.data?.published_entries_count,
            response?.data?.draft_entries_count,
            response?.data?.archived_entries_count,
            response?.data?.out_of_scope_entries_count,
          ],
          backgroundColor: [
            "rgb(255, 205, 86)",
            "rgb(75, 192, 192)",
            "rgb(54, 162, 235)",
            "rgb(255, 99, 132)",
            "rgb(153, 102, 255)",
          ],
          hoverOffset: 4,
        },
      ],
    };
  };

  // Read more about chart.js => https://www.chartjs.org/docs/latest/
  const getLineChartData = (response) => {
    let date = new Date();
    let lastYearMonths = getLastYearMonths(date);

    const getDataForEntries = (entriesCount) => {
      return lastYearMonths.map((month) => {
        const foundMonthData = entriesCount?.find((item) => shortMonthName(Number(item.month.split("-")[1])) === month);
        if (foundMonthData) return foundMonthData.sum_by_month;
        else return 0;
      });
    };
    const publishedEntriesData = getDataForEntries(response?.data?.last_year_published_entries_count);
    const draftEntriesData = getDataForEntries(response?.data?.last_year_draft_entries_count);
    const pendingReviewsEntriesData = getDataForEntries(response?.data?.last_year_pending_review_entries_count);
    const archiveEntriesData = getDataForEntries(response?.data?.last_year_archived_entries_count);
    const outOfScopeEntriesData = getDataForEntries(response?.data?.last_year_out_of_scope_entries_count);

    return {
      labels: lastYearMonths,
      datasets: [
        {
          type: "line",
          label: "Published",
          data: publishedEntriesData,
          fill: false,
          borderColor: "rgb(75, 192, 192)",
          tension: 0.1,
        },
        {
          type: "line",
          label: "Draft",
          data: draftEntriesData,
          fill: false,
          borderColor: "rgb(54, 162, 235)",
          tension: 0.1,
        },
        {
          type: "line",
          label: "Pending Reviews",
          data: pendingReviewsEntriesData,
          fill: false,
          borderColor: "rgb(255, 205, 86)",
          tension: 0.1,
        },
        {
          type: "line",
          label: "Archive",
          data: archiveEntriesData,
          fill: false,
          borderColor: "rgb(255, 99, 132)",
          tension: 0.1,
        },
        {
          type: "line",
          label: "Out of Scope",
          data: outOfScopeEntriesData,
          fill: false,
          borderColor: "rgb(153, 102, 255)",
          tension: 0.1,
        },
      ],
    };
  };

  useEffect(() => {
    setIsLoading(true);
    personsAPI
      .getPersonCount()
      .then((response) => {
        setIsLoading(false);
        setBiographyEntriesCount(response?.data);
        setResponse(response);
      })
      .catch((error) => {
        console.log("Error:");
        console.log(error);
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <section className="min-height-container">
      <Container className="mt-4 mt-lg-4">
        <div className="row">
          <h2 className="m-0">
            Welcome, <span className="welcome">{userName}!</span>
          </h2>
        </div>

        <div className="row m-4">
          <Col className="mx-1">
            <AdminSummaryCard
              actionText={"Pending"}
              biographyEntriesCount={biographyEntriesCount?.pending_review_entries_count}
              iconClassName={"bi bi-hourglass-split me-2 flex-shrink-0 overview-icons"}
              linkTo={"/admin-pending-reviews"}
            />
          </Col>
          <Col className="mx-1">
            <AdminSummaryCard
              actionText={"Published"}
              biographyEntriesCount={biographyEntriesCount?.published_entries_count}
              iconClassName={"bi bi-check-circle-fill flex-shrink-0 overview-icons"}
              linkTo={"/admin-published-entries"}
            />
          </Col>
          <Col className="mx-1">
            <AdminSummaryCard
              actionText={"Draft"}
              biographyEntriesCount={biographyEntriesCount?.draft_entries_count}
              iconClassName={"bi bi-file-text-fill flex-shrink-0 overview-icons"}
              linkTo={"/draft-entries"}
            />
          </Col>
          <Col className="mx-1">
            <AdminSummaryCard
              actionText={"Archived"}
              biographyEntriesCount={biographyEntriesCount?.archived_entries_count}
              iconClassName={"bi bi-archive-fill me-2 flex-shrink-0 overview-icons"}
              linkTo={"/archived-entries"}
            />
          </Col>
          <Col className="mx-1">
            <AdminSummaryCard
              actionText={"Out of Scope"}
              biographyEntriesCount={biographyEntriesCount?.out_of_scope_entries_count}
              iconClassName={"bi bi-file-earmark-minus-fill me-2 flex-shrink-0 overview-icons"}
              linkTo={"/out-of-scope-entries"}
            />
          </Col>
        </div>
        <Row className="row d-flex mx-3 mt-3 mt-lg-3">
          <Col xl={8} lg={8} md={12} sm={12} className="p-3">
            <Card className="shadow-sm bg-white rounded card-text">
              <Card.Body className="pt-0">
                <>
                  <Card.Title className="display-6 py-1">
                    <b>Last Year Entries</b>
                  </Card.Title>
                </>

                <Line width="40" height="19" data={getLineChartData(response)} />
              </Card.Body>
            </Card>
          </Col>
          <Col xl={4} lg={4} md={12} sm={12} className="pt-3">
            <Card className="shadow-sm bg-white rounded card-text">
              <Card.Body className="pt-0">
                <>
                  <Card.Title className="display-6 py-2">
                    <b>Total Entries</b>
                  </Card.Title>
                </>
                <Doughnut width="20" height="20" data={getDoughnutChartData(response)} />

              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default AdminDashboard;
