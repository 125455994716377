import axios from "axios";

const baseURL = `/api/v1/content`;

const headerConfig = () => {
  return {
    headers: {
      "Content-Type": "application/json",
    },
  };
};

const getContent = () => {
  const headers = headerConfig();

  return axios.get(baseURL, headers);
};

export const contentAPI = {
  getContent,
};
