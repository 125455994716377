import axios from "axios";
import React, { useContext } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { BiographyStore } from "../../../stateManager/stores/BiographyStore";
import { FormatToDate } from "../utils/Reformat";

function UncertainDateController({
  name,
  placeholder = "",
  error,
  displayDatesInfoLink,
  onShowDatesFormatLinkClicked,
  onFocus,
}) {
  const { control, setError, clearErrors } = useFormContext();
  const { state } = useContext(BiographyStore);

  const validate = (date) => {
    axios({
      method: "GET",
      url: `/api/v1/rpc/validate_uncertaindate?q=${date || ""}`,
      headers: { "Content-Type": "application/json" },
      params: {},
    })
      .then((response) => {
        !response.data.valid &&
          setError(name, { message: "Please enter a valid date!" });
        response.data.valid && clearErrors(name);
      })
      .catch((e) => {
        console.log("Error:");
        console.log(e);
      });
  };

  const displayFormattedValueIfAny = (value) => {
    return value === " - " ? "" : value;
  };

  const formatDateValueIfObjectType = (value) => {
    return typeof value === "string"
      ? value
      : displayFormattedValueIfAny(FormatToDate(value));
  };

  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field: { value, onChange } }) => (
          <input
            id={name}
            className="form-control"
            placeholder={placeholder}
            readOnly={state.isReadOnly}
            onFocus={onFocus}
            value={formatDateValueIfObjectType(value)}
            onChange={(e) => {
              onChange(e.target.value);
              validate(e.target.value);
            }}
          />
        )}
      />
      {error && (
        <span className="text-danger" data-testid="error-message" id={name+"-error-message"}>
          {error}
        </span>
      )}
      {displayDatesInfoLink && (
        <div className="mt-1">
          {" "}
          <button
            className="date-info-link p-0"
            id={name+"-info-link"}
            type="button"
            onClick={onShowDatesFormatLinkClicked}
          >
            <small> Refer valid date formats.</small>
          </button>
        </div>
      )}
    </>
  );
}

export default UncertainDateController;
