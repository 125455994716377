
export const defaultOverviewTitle = "The Harry Gentle Resource Centre Dictionary"

export const defaultHowToUseDictionaryTitle = "How to use the Dictionary"
 
export const defaultBecomeAContributorTitle = "Become a Contributor"

export const defaultHarryGentleResourceCentreTitle = "Harry Gentle Resource Centre"


export const defaultOverviewContent = `The Harry Gentle Resource Centre Dictionary is a biographical dictionary of the peoples who lived in colonial Queensland. The dictionary includes information about First Peoples of this region under conditions of colonisation, the convicts and soldiers who occupied Moreton Bay penal settlement, and settler immigrants as well as indentured labourers who followed them.

This dictionary is a living document, with information collated from a wide range of archival and printed sources, updated as new material comes to light. It draws on the contribution of researchers associated with the Centre, as well as the genealogical research of volunteers and collecting institutions over many years.

Information in this dictionary about First Peoples is drawn from sources most commonly associated with colonisation practices and government administration. For this reason most individuals of First People origin are known only through an anglicised name. Their inclusion in this dictionary is an important step in acknowledging their original possession of the country of this region, and the scale and significance of their ancient and ongoing occupation of these lands.`;

export const defaultHowToUseDictionaryContent = `A guide to users of the Dictionary is available at this link {to video} and answers to frequently answered questions is available here {FAQ}`;

export const defaultBecomeAContributorContent = `This Dictionary has been prepared by staff and scholars associated with the Harry Gentle Resource Centre. It draws on a wide range of sources, including those collated by genealogical societies and researchers, government agencies and archives, as well as individual family researchers. The Centre welcomes continuing input from researchers and community users. You may become a Contributor by emailing us at: harrygentle@griffith.edu.au`;

export const defaultHarryGentleResourceCentreContent = `The Harry Gentle Resource Centre (HGRC) is a digital initiative established in 2016 to honour the generous bequest of Mr Robert (Harry) Gentle, an alumnus of Griffith University. It promotes and supports the histories of the region that would become colonial Queensland by digitising and curating resources on people, institutions and landscapes.`;
