import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, Col, Row } from "react-bootstrap";

const ContactUs = ({ responseData }) => {
  return (
    <Card className="info-details">
      <Card.Body className="px-0">
        <Row>
          <Col lg={1} md={1} sm={1}>
            <h6>
              <FontAwesomeIcon icon={faCircleInfo} />
            </h6>
          </Col>
          <Col lg={10} md={10} sm={10} className="pr-0">
            <i>
              <h6>
                Have more information about{" "}
                {(responseData?.first_name ? responseData?.first_name + " " : "") + responseData.surname}?
              </h6>
            </i>
            <Card.Text className="bio-page-accordion">
              <small>
                We would love to hear from you. Please contact{" "}
                <b>
                  <a href="mailto:harrygentle@griffith.edu.au">harrygentle@griffith.edu.au</a>
                </b>
              </small>
            </Card.Text>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default ContactUs;
