// Basic Layout for a page with title and breadcrumps

import { Container, Breadcrumb } from "react-bootstrap";
import { Link } from "react-router-dom";
function PageLayout({ children, title, parents, showHeadingAndBreadcrumb, subtitle = "" }) {
    return (
        <>
            <section className="min-height-container">
            
                <Container className="my-md-4 my-3">
                    {showHeadingAndBreadcrumb && (
                        <div className="row mx-3 mt-2 mt-lg-2">
                            <Breadcrumb>
                                <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}>
                                    Home
                                </Breadcrumb.Item>
                                {Array.isArray(parents) &&
                                    parents.map((bc) => {
                                        return (
                                            <Breadcrumb.Item
                                                key={`bc-${bc.title}`}
                                                linkAs={Link}
                                                linkProps={{ to: bc.to }}
                                            >
                                                {bc.title}
                                            </Breadcrumb.Item>
                                        );
                                    })}
                                <Breadcrumb.Item active>{title}</Breadcrumb.Item>
                            </Breadcrumb>
                            <h4>{title}</h4>
                            {subtitle !== "" ? <h5>{subtitle}</h5> : null}
                        </div>
                    )}
                    <div className="row mx-3 mt-2 mt-lg-2">{children}</div>
                </Container>
            </section>
        </>
    );
}

export default PageLayout;
