import { Navigate } from "react-router-dom";
import { useAuth } from "react-oidc-context";
import { SignInContext } from "../../stateManager/stores/SignInContext";
import { useContext } from "react";

function PrivateRoute({ children, ...rest }) {
  let auth = useAuth();
  const { isApprovedUser } = useContext(SignInContext);

  if (isApprovedUser === null && auth.isAuthenticated) {
    return null;
  }

  if (auth.isAuthenticated && isApprovedUser) {
    return children;
  }

  if (auth.isAuthenticated && !isApprovedUser) {
    return <Navigate to={"/unauthorized"} replace />;
  }

  if (!auth.isAuthenticated) {
    return <Navigate to={"/"} replace />;
  }
}

export default PrivateRoute;
