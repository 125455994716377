import { Container, Form } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { personsAPI } from "../../../api/personsAPI";
import { toast } from "react-toastify";
import { initialFormData } from "../FormComponents/initialFormData";
import MDEditor from "@uiw/react-md-editor";
import rehypeSanitize from "rehype-sanitize";
import { LabelAndInput } from "../FormComponents/LabelAndInput";
import LabelAndSelect from "../FormComponents/LabelAndSelect";

const CreateBioEntryFormModal = ({ setResponse, onClose }) => {
  const defaultData = {
    ...initialFormData,
    gender: "U",
    place_of_birth: null,
    place_of_death: null,
    place_of_burial: null,
  };
  const { register, handleSubmit, control, getValues } = useForm({
    defaultValues: defaultData,
  });

  const onSubmit = (data) => {
    const toastId = toast.loading("Please wait...");

    // Updating the default draft state to out_of_scope if checkbox is checked
    if (data) {
      const isOutOfScope = data.is_out_of_scope;
      if (isOutOfScope) {
        data = { ...data, state: "out_of_scope" };
      }

      personsAPI
        .create(data)
        .then((res) => {
          setResponse(res?.data);
          toast.update(toastId, {
            render: "Record has been saved successfully.",
            type: "success",
            isLoading: false,
            autoClose: 1500,
            closeButton: true,
            hideProgressBar: false,
          });
          onClose();
        })
        .catch((e) => {
          window.scrollTo(0, 0);
          toast.update(toastId, {
            render: "Error! Something went wrong",
            type: "error",
            isLoading: false,
            autoClose: true,
            closeButton: true,
            hideProgressBar: false,
          });
          console.log(e.toString());
        });
    }
  };

  return (
    <Container>
      <div className="col-auto">
        <div className="row mb-3 mt-3">
          <div className="col-md-2">
            <h3>Add Entry</h3>
          </div>
          <div className="col-md-10 float-end text-end">
            <button
              type="button"
              onClick={onClose}
              className="btn btn-secondary mx-2"
            >
              Close
            </button>
            <button
              type="button"
              onClick={handleSubmit(onSubmit)}
              className="btn btn-primary mx-2"
            >
              Save
            </button>
          </div>
        </div>
        <hr />

        <div className="col-md-12 field-array-style mb-3 mt-3">
          <div className="row">
            <LabelAndInput
              labelText="Surname"
              classNames={"col-md-4"}
              labelClassName={"form-label required"}
              registerText="surname"
              required={true}
              register={register}
            />

            <LabelAndInput
              labelText="First name"
              classNames={"col-md-4"}
              registerText="first_name"
              required={false}
              register={register}
            />

            <LabelAndInput
              labelText="Middle name"
              classNames={"col-md-4"}
              registerText="middle_name"
              required={false}
              register={register}
            />
            <LabelAndSelect
              control={control}
              labelText="Gender"
              placeholder="Select Gender"
              name="gender"
              classNames={"col-md-12 mt-4"}
              labelClassName={"form-label required"}
              onSelectionChange={() => null}
              getValues={getValues}
            />
            <div className={"col-md-12 mt-4"}>
              {" "}
              <Form.Check
                type={"checkbox"}
                id={`default-check`}
                {...register("is_out_of_scope")}
                label={`Tick this checkbox if you are creating an out of scope entry`}
              />
              <i>
                <small>
                  Note: The out-of-scope entries will not be part of
                  the research. The main aim of creating such entries is solely
                  to create relationships in the Database. Based on your access,
                  you will be able to move out-of-scope entries to either Draft
                  or Publish states from the Dashboard menu.{" "}
                </small>
              </i>
            </div>
          </div>
          <div className="row mt-4">
            <>
              <label htmlFor={"notes"} className="form-label col-md-6">
                Notes:
              </label>
              {/* For now added hardcoded link  */}
              <small className="col-md-6 float-end text-end">
                {`To refer basic markdown syntax `}
                <a
                  href="https://www.markdownguide.org/basic-syntax/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <b>Click here</b>
                </a>
              </small>
            </>
          </div>
          <Controller
            control={control}
            name={"notes"}
            render={({ field: { onChange, value } }) => (
              <MDEditor
                value={value}
                onChange={(v) => onChange(v)}
                previewOptions={{
                  rehypePlugins: [[rehypeSanitize]],
                }}
              />
            )}
          />
        </div>
      </div>
    </Container>
  );
};

export default CreateBioEntryFormModal;
