import React from "react";
import SearchPage from "../../pages/SearchPage";
import { biographyState } from "../BiographyEntryForm/utils/utils";

const AdminArchiveEntries = () => {
  return (
    <SearchPage
      displayAdminBreadcrumb={true}
      pageTitle={"Archived Entries"}
      displayEntryForState={biographyState.ARCHIVED}
      hideActionColumn={false}
      hideStateColumn={true}
      alertMessage={"There are no Archived Entries."}
    />
  );
};

export default AdminArchiveEntries;
