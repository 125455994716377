import { Accordion } from "react-bootstrap";

export const BiographyFormAccordionItem = ({ eventKey, header, children, error, accordionArrowClassName }) => {
  return (
    <Accordion.Item eventKey={eventKey.toString()} id={`accordion-${eventKey}`} className={ error ? "accordion-item-error" : ""}>
      <Accordion.Header disabled className={ error ? "accordion-button-error" : accordionArrowClassName}>{header}</Accordion.Header>
      {children && <Accordion.Body>{children}</Accordion.Body>}
    </Accordion.Item>
  );
};
