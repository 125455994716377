import React from "react";
import griffithLogo from "../assets/GRIFF1_STD_RGB_reverse.png";
import { Container, Row, Col } from "react-bootstrap";


const Footer = () => (
    <footer id="Footer" className="text-light py-3 footer">
        <Container>
    <Row className="py-2 align-items-end">
        <Col xs={12} md={8} className="d-flex align-items-end">
        <div> 
            <a
                href="https://www.griffith.edu.au"
                target="_blank"
                rel="noopener noreferrer"
            >
                <img alt="Griffith Logo" src={griffithLogo} height={38} />
            </a>
            <p style={{ margin: "0px", paddingTop: "5px", fontWeight: "200" }}>
                An initiative of the Harry Gentle Resource Centre in collaboration with Griffith eResearch at Griffith University.
            </p>
            </div>
        </Col>

        <Col
            xs={12}
            md={4}
            className="d-flex justify-content-start justify-content-md-end align-items-end py-3 py-md-0"
            style={{ fontWeight: "300" }}
        >
            CRICOS Provider - 00233E | TEQSA - PRV12076
        </Col>
    </Row>
</Container>

    </footer>
);

export default Footer;
